import { Injectable } from "@angular/core";
import { paths } from "app/paths";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class ErrorService {
    constructor(private _router: Router) {}

    /**
     * Redirects the user to the error screen
     * @param resp
     */
    public catch(resp): void {
        const errorTitle = resp?.errorTitle ?? "Error";
        this._router.navigate([`/${paths.BASE}/${paths.ERROR}`], {
            state: {
                errorTitle,
                transactionResponse: resp
            }
        });
    }
}
