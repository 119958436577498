import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DepositLimitsService } from "app/services/deposit-limits/deposit-limits.service";
import { DepositLimit } from "app/interfaces/deposit-limits.interface";
import { PlayerService } from "app/services/player/player.service";
import { DepositLimitPeriod } from "app/enums/deposit-limit-period";
import { DepositLimitChangeAction } from "app/enums/deposit-limit-change-action.enum";

@Component({
    selector: "app-deposit-limits-overview-item",
    templateUrl: "./deposit-limits-overview-item.component.html",
    styleUrls: ["./deposit-limits-overview-item.component.scss"]
})
export class DepositLimitsOverviewItemComponent {
    public readonly playerTUIsAmber = this.playerService.isAmber;
    @Output() onAction = new EventEmitter();
    @Input() recentlyUpdated = false;

    private _type: DepositLimitPeriod;
    @Input()
    set type(value: DepositLimitPeriod) {
        this._type = value;
        this.limit = this._depositLimitsService.playerLimitsFormatted[value];
    }
    get type(): DepositLimitPeriod {
        return this._type;
    }

    public limit: DepositLimit;

    constructor(
        private _depositLimitsService: DepositLimitsService,
        private readonly playerService: PlayerService
    ) {}

    public cancelLimit(): void {
        this.onAction.emit(
            this._depositLimitsService.setDepositLimit(
                DepositLimitChangeAction.CANCEL_PENDING
            )
        );
    }

    public confirmLimit(): void {
        this.onAction.emit(
            this._depositLimitsService.setDepositLimit(
                DepositLimitChangeAction.CONFIRM_PENDING
            )
        );
    }

    /**
     * Determines the CTA Text to show
     */
    public getCTAText(): string {
        if (this.limit.value) {
            return "Edit";
        }

        if (
            this.playerTUIsAmber &&
            this.type === "perMonth" &&
            !!this.limit.lockedMaxValue &&
            !this.limit.value
        ) {
            return "Edit";
        }

        return "Set";
    }
}
