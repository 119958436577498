import { Injectable, NgZone } from "@angular/core";
import {
    CreatePaymentRequest,
    CreatePaymentResponse,
    SafeCharge,
    SafeChargeDepositTransactionSummary,
    SafeChargeField,
    SafeChargeFieldDefinition
} from "app/interfaces/safe-charge";
import { DepositResponse } from "app/interfaces/deposit.interface";
import { DepositService } from "app/services/deposit/deposit.service";
import { CoinStatus } from "app/enums/coin-status.enum";
import { LoggerService } from "app/services/logger/logger.service";
import { catchError, tap } from "rxjs/operators";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { throwError } from "rxjs";
import { CashierApiService } from "../cashier-api.service";

@Injectable({
    providedIn: "root"
})
export class SafeChargeService {
    public readonly recoverableDepositErrors: {
        affects: string;
        errorDescription: string;
    }[] = [
        {
            affects: "cvv",
            errorDescription: "incomplete_cvc"
        },
        {
            affects: "cvv",
            errorDescription: "cardData.CVV is invalid"
        },
        {
            affects: "cvv",
            errorDescription: "Negative CAM, dCVV, iCVV, or CVV results"
        },
        {
            affects: "cvv",
            errorDescription: "Invalid CVV2"
        }
    ];

    public readonly insufficientErrors = ["Insufficient funds"];

    public readonly expiredErrors = ["Expired card"];

    public readonly underReviewErrors = ["Suspected fraud"];

    public readonly recoverableTokenizationErrors = [
        {
            affects: "ccCvc",
            errorDescription: "cardData.CVV is invalid"
        },
        {
            affects: "ccCvc",
            errorDescription: "Invalid CVV2"
        }
    ];

    constructor(
        private _depositService: DepositService,
        private readonly loggerService: LoggerService,
        private readonly _playerSessionService: PlayerSessionService,
        private readonly cashierAPIService: CashierApiService,
        private _zone: NgZone
    ) {}

    /**
     * Initializes the WebSDK
     * @private
     */
    public get sdk(): SafeCharge {
        if (!window["SafeCharge"]) {
            this.handleSDKErrors("SafeCharge Web SDK not Available");
            return null;
        }

        return window["SafeCharge"]({
            /**
             * whether to display the cvvIcon within the cvv field
             * not aesthetically pleasing => @cvvIcon false
             */
            cvvIcon: false,
            env: this.cashierAPIService.playerData.gateway.nuvei.env,
            merchantId:
                this.cashierAPIService.playerData.gateway.nuvei.merchantId,
            merchantSiteId:
                this.cashierAPIService.playerData.gateway.nuvei.merchantSiteId
        });
    }

    public async setupFields(
        fields: SafeChargeFieldDefinition[],
        options: { cvvPlaceholder: string } = null
    ): Promise<void> {
        const isGrosvenor =
            this._playerSessionService.skinID == 17 ? true : false;

        const fieldBuilder = this.sdk.fields({
            i18n: {
                cvv: options?.cvvPlaceholder ?? "CVV" // Placeholder text for CVN field
            }
        });

        const sfcFieldOptions = {
            card_brand: "visa",
            style: {
                base: {
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: isGrosvenor ? "white" : null
                }
            }
        };

        Object.keys(fields).forEach((key) => {
            fields[key].field = fieldBuilder.create(
                fields[key].sfcID,
                sfcFieldOptions
            );
            fields[key].field.attach(`#${fields[key].htmlID}`);
        });
    }

    /**
     * Called after a successful request to DepositStart
     * @param depositStartResp
     * @param cvvField
     * @param transactionAmount
     *
     * @see https://docs.safecharge.com/documentation/guides/3d-secure-2/3ds-authentication-flows/#3ds-for-tokenized-cards
     */
    public async onDepositSessionCreated(
        depositStartResp: DepositResponse,
        cvvField: SafeChargeField,
        transactionAmount: number
    ): Promise<SafeChargeDepositTransactionSummary> {
        const createPaymentData: CreatePaymentRequest = {
            sessionToken: depositStartResp.nuveiDepositDetails.sessionToken,
            userTokenId: depositStartResp.nuveiDepositDetails.userTokenId,
            paymentOption: {
                card: {
                    CVV: cvvField
                },
                userPaymentOptionId:
                    depositStartResp.nuveiDepositDetails.userPaymentOptionId
            },
            billingAddress: {
                email: depositStartResp.nuveiDepositDetails.email,
                country: depositStartResp.nuveiDepositDetails.countryCode
            }
        };
        return new Promise((resolve) => {
            this.sdk.createPayment(createPaymentData, (resp) => {
                resolve(
                    this.finalizeDeposit(
                        resp,
                        depositStartResp,
                        transactionAmount
                    )
                );
            });
        });
    }

    public isRecoverableDepositError(
        errorDescription: string
    ): undefined | { affects: string; errorDescription: string } {
        return this.recoverableDepositErrors.find(
            (i) => i.errorDescription === errorDescription
        );
    }

    /**
     * Called when CreatePayment for Deposits has completed successfully
     * @param sfResp    SafeCharge Response
     * @param depositResponse  DepositResponse Response
     * @param transactionAmount
     * @private
     */
    private finalizeDeposit(
        sfResp: CreatePaymentResponse,
        depositResponse: DepositResponse,
        transactionAmount: number
    ): SafeChargeDepositTransactionSummary {
        return this._zone.run<SafeChargeDepositTransactionSummary>(() => {
            /**
             * If...
             * 1. the error received from SafeCharge is one of the recoverable errors AND
             * 2. the player is within their "retryable" range
             * THEN...
             * Don't redirect the player to the response page
             */
            const currentError = this.isRecoverableDepositError(
                sfResp?.errorDescription
            );
            const allowRetry =
                this._depositService.maxRetries >
                    this._depositService.retryCount &&
                currentError !== undefined;

            const depositSummary: SafeChargeDepositTransactionSummary = {
                allowRetry,
                validation: currentError,
                retryCount: this._depositService.retryCount
            };
            this._depositService.retryCount++;

            /**
             * Don't call deposit end on Nuvei Failures
             */
            if (sfResp.result === "APPROVED") {
                const requestData = {
                    gatewayEnc: depositResponse.nuveiDepositDetails.gatewayEnc,
                    txDepositId: depositResponse.txDepositId
                };

                const depositEnd$ =
                    this.cashierAPIService.finaliseDeposit(requestData);

                depositEnd$
                    .pipe(
                        catchError((error) => {
                            this._depositService.redirectToResponsePage(
                                error,
                                transactionAmount,
                                sfResp
                            );
                            return throwError(error);
                        }),
                        tap((resp) => {
                            this._depositService.redirectToResponsePage(
                                resp,
                                transactionAmount,
                                sfResp
                            );
                        })
                    )
                    .subscribe();
            } else {
                if (!allowRetry) {
                    this._depositService.redirectToResponsePage(
                        null,
                        transactionAmount,
                        sfResp
                    );
                }
            }

            return depositSummary;
        });
    }

    /**
     * Handle's SafeCharge Errors
     * @param errorMessage
     * @param data
     * @private
     */
    private handleSDKErrors(errorMessage, data = null): void {
        this.loggerService.error(errorMessage, data);
        const defaultData = {
            Status: CoinStatus.General_Error,
            StatusMsg: errorMessage,
            AccountID: null
        };

        this._depositService.redirectToResponsePage(defaultData);
    }
}
