import { Injectable } from "@angular/core";
import {
    CoinAccount,
    GetCustomerAccountsResponse
} from "app/interfaces/accounts.interface";
import { catchError, map, tap } from "rxjs/operators";
import { ExpireStatus } from "app/enums/expire-status.enum";
import { CashierService } from "app/services/cashier/cashier.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
    AccountType,
    GetAccountTypesResponse
} from "app/interfaces/account-types";
import { paths } from "app/paths";
import { Router } from "@angular/router";
import { ErrorService } from "app/services/error/error.service";
import { APP_COOKIE_KEYS } from "app/app.settings";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { AccountTypeCode } from "app/enums/account-type-code.enum";
import { CookieService } from "ngx-cookie-service";
import { CashierApiService } from "../cashier-api.service";
import { AddAccountResponse } from "app/interfaces/tokenize-card.interface";

interface FetchOptions {
    excludeExpired?: boolean;
    expiredAtBottom?: boolean;
    useCache?: boolean;
}

/**
 * Handles account specific queries
 * Contains some helper methods to v2 Cashier Service
 */
@Injectable({
    providedIn: "root"
})
export class PaymentMethodsService {
    private _accountsResponseCached: GetCustomerAccountsResponse;

    public smartTiles$ = new BehaviorSubject<number[]>([]);
    public accountTypes$ = new BehaviorSubject<AccountType[]>([]);
    public selected$ = new BehaviorSubject<CoinAccount | null>(null);
    public readonly maxTokenizationRetries =
        this._featureToggleService.getConfigValue(
            SupportedFeatures.TOKENIZATION_RETRIES,
            1
        );
    public readonly promptUsersToApplePay =
        this._featureToggleService.getConfigValue(
            SupportedFeatures.PROMPT_TO_USE_APPLE_PAY
        );
    public tokenizationRetryCount = 0;

    public get activeCount(): number {
        const value = this.cashierApiService.playerData.activeAccounts;
        return isNaN(value) ? 0 : value;
    }

    public get maxAllowed(): number {
        return this.cashierApiService.playerData.maxAccounts;
    }

    public get maxReached(): boolean {
        return this.activeCount >= this.maxAllowed;
    }

    /**
     * Checks if the currently selected account is a card
     */
    public get currentSelectedIsCard(): boolean {
        const cardTypeCodes = [
            AccountTypeCode.VisaDebit,
            AccountTypeCode.VisaCredit,
            AccountTypeCode.MasterCardDebit,
            AccountTypeCode.MasterCardCredit
        ];
        return (
            this.currentSelected &&
            cardTypeCodes.indexOf(this.currentSelected.accountTypeCode) !== -1
        );
    }

    constructor(
        private _errorService: ErrorService,
        private readonly _featureToggleService: FeatureToggleService,
        private _router: Router,
        private _cashierService: CashierService,
        public cashierApiService: CashierApiService,
        public cookieService: CookieService
    ) {}

    /**
     * Checks if there is data for this player's accounts
     * otherwise, make a call to the backend to retrieve this data
     * @public
     */
    public get hasActivePaymentMethod$(): Promise<boolean> {
        const numOfAccounts = this.cashierApiService.playerData?.activeAccounts;

        return new Promise((resolve) => {
            if (isNaN(numOfAccounts)) {
                this.activeCount$.then((result) => {
                    resolve(result !== 0 && !isNaN(result));
                    return;
                });
            } else {
                resolve(numOfAccounts !== 0);
            }
        });
    }

    private get activeCount$(): Promise<number> {
        return new Promise((resolve) =>
            this.cashierApiService.playerData$.subscribe((playerData) => {
                resolve(playerData.activeAccounts);
            })
        );
    }

    public setApplePayPrompt(): boolean {
        if (this.hasApplePay && this.promptUsersToApplePay) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Allows applepay on capable devices
     * renders Apple Pay Icon
     */
    public get hasApplePay(): boolean {
        /**
         * Unfortuntely, applepay session doesn't exist as part as the window object
         */
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        const myWindow = window as any;
        if (myWindow.ApplePaySession) return true;
        return false;
    }
    /**
     * Updates the ActiveAccounts value
     * Used when:
     * 1. Adding a Debit Card
     * 2. Deleting any Payment Method
     * @param valueToChangeBy
     */
    public updateActiveCount(valueToChangeBy: number): void {
        let newCount = this.activeCount + valueToChangeBy;
        newCount = Math.max(newCount, 0);
        this.cashierApiService.playerData.activeAccounts = newCount;
    }

    /**
     * Disables an account from a players profile
     * - the account will be disabled in the backend
     * - no longer appear in the `GetCustomerAccounts` call
     * @param account
     */
    public async disable(account: CoinAccount): Promise<boolean> {
        const coinAccountId = account?.accountId;
        return await this.cashierApiService
            .disableAccount(coinAccountId)
            .toPromise()
            .then(() => {
                /**
                 * If the currently selected payment method in Cashier Frontend
                 * is the one being disabled...
                 * unselect it, so that it cannot be used in further transactions
                 */
                if (this.currentSelected?.accountId === account.accountId) {
                    this.select(null);
                }
                this.updateActiveCount(-1);
                return true;
            })
            .catch((error) => {
                if (error?.status == 409) {
                    return false;
                } else {
                    /**
                     * If it's not an error related to disabling the account,
                     * We'll proceed to disable and unselect the account
                     */
                    if (this.currentSelected?.accountId === account.accountId) {
                        this.select(null);
                    }
                    this.updateActiveCount(-1);
                    return true;
                }
            });
    }

    private orderByLastSuccessfulDeposit(
        accountsResp: GetCustomerAccountsResponse
    ): GetCustomerAccountsResponse {
        if (accountsResp?.code) {
            return accountsResp;
        }

        accountsResp.accounts = accountsResp.accounts.sort((a, b) => {
            return new Date(a.lastSuccessfulDepositDate) >
                new Date(b.lastSuccessfulDepositDate)
                ? -1
                : 1;
        });
        return accountsResp;
    }

    /**
     * Reorders the Accounts list
     * by placing expired Accounts at the bottom of the array
     *
     * @param accountsResp
     * @private
     */
    private expiredAtBottom(
        accountsResp: GetCustomerAccountsResponse
    ): GetCustomerAccountsResponse {
        const activeAccounts = accountsResp.accounts.filter(
            (acc) => acc.expireStatus !== ExpireStatus.Expired
        );
        const expiredAccounts = accountsResp.accounts.filter(
            (acc) => acc.expireStatus === ExpireStatus.Expired
        );
        accountsResp.accounts = [...activeAccounts, ...expiredAccounts];
        return accountsResp;
    }

    private excludeExpired(
        accountsResp: GetCustomerAccountsResponse
    ): GetCustomerAccountsResponse {
        accountsResp.accounts = accountsResp.accounts.filter(
            (a) => a.expireStatus !== ExpireStatus.Expired
        );
        return accountsResp;
    }

    /**
     * Gets all the players current payment methods
     * Options allow you to filter through the fetch
     *
     * By default:
     * - orders accounts by last successful deposit date
     * - then places expired accounts at the bottom
     *
     * @see FetchOptions
     */
    public get(
        options: FetchOptions = {
            excludeExpired: false,
            expiredAtBottom: true,
            useCache: false
        }
    ): Observable<GetCustomerAccountsResponse> {
        let result;

        if (options?.useCache && this._accountsResponseCached) {
            result = of(this._accountsResponseCached);
        } else {
            result = this.cashierApiService.getCustomerAccounts();
        }

        /**
         * Guard against errors from B/E
         */
        result = result.pipe(
            map((resp: GetCustomerAccountsResponse) => {
                this._accountsResponseCached = resp;
                resp.accounts = resp?.accounts || [];
                return resp;
            })
        );

        if (options.excludeExpired) {
            result = result.pipe(map(this.excludeExpired));
        }

        result = result.pipe(map(this.orderByLastSuccessfulDeposit));

        /**
         * Order the accounts by Active -> Expired
         * ...Only if expired are not excluded
         */
        if (options.expiredAtBottom && !options.excludeExpired) {
            result = result.pipe(map(this.expiredAtBottom));
        }

        /**
         * After the Accounts are fetched...
         * do the following
         */
        result = result.pipe(
            tap((resp: GetCustomerAccountsResponse) => {
                this.customerAccountValidations(resp);
            })
        );

        return result;
    }

    /**
     * Validates accounts returned from the backend
     * if there are none, it will navigate to add account screen
     * else Preselects the last deposited payment method if none is already selected
     * or selects the first account available,
     * also makes sure if apple pay does not fulfil these conditions if not supported
     */
    private async customerAccountValidations(
        resp: GetCustomerAccountsResponse
    ): Promise<void> {
        /**
         * When there are no accounts in the response navigate directly to add account
         * Ignore unnecessary warning, source: trust me bro
         */
        const customerAccounts = await resp.accounts.filter((account) =>
            !this.hasApplePay
                ? account.accountTypeCode !== AccountTypeCode.ApplePay
                : true
        );
        if (customerAccounts.length < 1) {
            this._router.navigate([`/${paths.BASE}/${paths.ADD_ACCOUNT}/`]);
        }
        /**
         * Preselects the last deposited payment method if none is already selected
         */
        if (!this.currentSelected) {
            let accountToSelect = customerAccounts.find(
                (acc) => acc.defaultAccount == "1"
            );

            /**
             * Select a fallback account if no default account is found
             * The list is already ordered by last successful deposit
             * @see orderByLastSuccessfulDeposit
             */
            accountToSelect = accountToSelect ?? customerAccounts[0] ?? null;
            this.select(accountToSelect);
        }
    }

    /**
     * Selects the desired Payment Method
     * If no value is provided, it will select according to the following order
     * 1. a valid payment method with the most recent deposit date
     * 2. The first valid payment method
     *
     * a valid payment is one which has not yet expired
     */
    public select(account: CoinAccount): void {
        if (account) {
            this.cookieService.set(
                APP_COOKIE_KEYS.LAST_SELECTED_ACCOUNTTYPE_CODE,
                account.accountTypeCode,
                null,
                "/",
                null,
                true,
                "None"
            );
        } else {
            // if no account is passed, then delete the cookie as the account was disabled
            this.cookieService.delete(
                APP_COOKIE_KEYS.LAST_SELECTED_ACCOUNTTYPE_CODE
            );
        }
        this._cashierService.selectedAccount = account;
        this.smartTiles$.next(account.smartTiles);
        this.selected$.next(account);
    }

    /**
     * Gets the currently selected account
     * If not available at runtime, will return null
     */
    public get currentSelected(): CoinAccount {
        return this._cashierService.selectedAccount || null;
    }

    /**
     * Gets an alternate valid payment method
     * Filters apple pay account if not compatible as alternate valid
     * excluding the provided CoinAccount
     */
    public getAlternate(
        excludeAccount: CoinAccount = null,
        fetchOptions: FetchOptions
    ): Observable<CoinAccount> {
        return this.get({ ...fetchOptions, ...{ excludeExpired: true } }).pipe(
            map((resp) => {
                const checkedApplePayCompatibility = resp.accounts.filter(
                    (account) =>
                        !this.hasApplePay
                            ? account.accountTypeCode !==
                              AccountTypeCode.ApplePay
                            : true
                );
                return (
                    checkedApplePayCompatibility
                        .filter((acc) => {
                            if (!excludeAccount) {
                                return true;
                            }
                            return acc.accountId !== excludeAccount.accountId;
                        })
                        .shift() || null
                );
            })
        );
    }

    /**
     * Default Apple Pay Account setup, we'll make a request if the player doesn't have an active ApplePayAccount
     * If that fails, we'll create a default
     * @param CoinAccount
     */
    public async selectDefaultApplePay(): Promise<void> {
        const defaultApplePayAccount = await this.availableNewOptions()
            .toPromise()
            .then((paymentMethods) => {
                return paymentMethods.accountTypes.find(
                    (account) =>
                        account.accountTypeCode == AccountTypeCode.ApplePay
                );
            });
        if (defaultApplePayAccount)
            this.select(defaultApplePayAccount as CoinAccount);
        else
            this.select({
                accountId: null,
                accountTypeCode: AccountTypeCode.ApplePay,
                minDepositAmount: 5,
                promos: this.currentSelected.promos,
                smartTiles: this.currentSelected.smartTiles,
                description: "ApplePay",
                lastSuccessfulDepositDate: null
            });
    }

    /**
     * Gets the available options for
     * adding a new account
     */
    public availableNewOptions(): Observable<GetAccountTypesResponse> {
        return this.cashierApiService.getAccountTypes().pipe(
            map((accountTypesresponse) => {
                this.accountTypes$.next(accountTypesresponse.accountTypes);
                return accountTypesresponse;
            }),
            catchError((error, caught) => {
                const errorResponse = {
                    errorTitle: error?.code,
                    StatusMsg: error?.message
                };
                this._errorService.catch(errorResponse);
                return caught;
            })
        );
    }

    /**
     * Checks the GetAccountsResponse to see if a user has an active apple pay method
     * If not we will create a default
     */
    public navigateToApplePaySelected(): void {
        const applePayAccount = this._accountsResponseCached?.accounts.find(
            (account) => account.accountTypeCode == AccountTypeCode.ApplePay
        );

        if (applePayAccount) this.select(applePayAccount);
        else this.selectDefaultApplePay();
    }

    public redirectToResponsePage(
        transactionResponse: AddAccountResponse,
        safeChargeResponse = null
    ): void {
        this.tokenizationRetryCount = 0;

        /**
         * When a player has no active accounts,
         * Their ActiveAccounts value is 0
         * upon tokenizing, this value is never updated
         * So when they try to navigate to the deposit page
         * after a successful tokenization,
         * their ActiveAccounts value is still 0,
         * thus the HasActivePaymentMethodGuard kicks in
         * and they'll be redirected to the Add Account Page
         * @see HasActivePaymentMethodGuard.getActiveAccounts
         */
        if (transactionResponse && !transactionResponse?.code) {
            this.updateActiveCount(1);
        }

        this._router.navigate(
            [`/${paths.BASE}/${paths.ADD_ACCOUNT}/${paths.RESPONSE}`],
            {
                skipLocationChange: true,
                state: {
                    isCashierServiceTransaction: true,
                    cashierServiceResponse: transactionResponse,
                    safeChargeResponse: safeChargeResponse
                }
            }
        );
    }
}
