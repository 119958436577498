import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { TokenService } from "app/services/token/token.service";
import { CookieService } from "ngx-cookie-service";
import { InitData } from "app/interfaces/init-data.interface";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { APP_COOKIE_KEYS } from "app/app.settings";
import { DepositResponse } from "app/interfaces/deposit.interface";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private checkRedirectDepositFeedback: string;

    constructor(
        private tokenService: TokenService,
        private cookieService: CookieService,
        private readonly _playerSessionService: PlayerSessionService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    /**
     * Check params in url and in cookie
     */
    public isAuthenticated(): InitData {
        /**
         * If the response contains a redirect deposit feedback param (isPayProviderRedirect)
         * AND
         * the webview parameter is set to true
         * THEN
         * read the authToken and the skin ID from the cookies
         */
        let authToken;
        this.checkRedirectDepositFeedback = this.tokenService.getUrlParameter(
            "isPayProviderRedirect",
            this.document.location.href
        );
        this._playerSessionService.isWebView =
            this.tokenService.getUrlParameter(
                "webview",
                this.document.location.href
            ) === "true";
        if (
            this.checkRedirectDepositFeedback &&
            this._playerSessionService.isWebView
        ) {
            authToken = this._playerSessionService.token;
        } else {
            authToken = this.tokenService.getUrlParameter(
                APP_COOKIE_KEYS.AUTH_TOKEN,
                this.document.location.href
            );
        }
        if (!!authToken && !!this._playerSessionService.skinID) {
            return this.setUserAuth(true);
        } else {
            const tokenFromCookie = this._playerSessionService.token;
            const data: InitData = {
                isLoggedIn: false,
                isTokenInUrl: false,
                isWithdrawals: false,
                isDepositLimits: false,
                cashierSource: this.tokenService.getUrlParameter(
                    "source",
                    this.document.location.href
                )
            };
            if (
                !!tokenFromCookie &&
                !this._playerSessionService.isExpired &&
                !!this._playerSessionService.skinID
            ) {
                data["isLoggedIn"] = true;
            }
            return data;
        }
    }

    setUserAuth(isTokenInUrl = false): InitData {
        const depositLimitParam = this.tokenService.getUrlParameter(
            "depositLimits",
            this.document.location.href
        );
        const withdrawalsParam = this.tokenService.getUrlParameter(
            "withdrawals",
            this.document.location.href
        );

        this.checkForClaim();
        this.checkForRedirectDepositFeedback();

        const data: InitData = {
            isLoggedIn: true,
            isTokenInUrl,
            isWithdrawals: withdrawalsParam == "true",
            isDepositLimits: depositLimitParam == "true",
            cashierSource: this.tokenService.getUrlParameter(
                "source",
                this.document.location.href
            )
        };
        return data;
    }

    /**
     * Stores deposit status data on a cookie for redirect-deposit-feedback component.
     */
    checkForRedirectDepositFeedback() {
        if (
            !!this.checkRedirectDepositFeedback &&
            this.checkRedirectDepositFeedback === "true"
        ) {
            const url = this.document.location.href;
            const queryParams = url.slice(url.indexOf("?") + 1).split("&");
            const result = {};
            queryParams.forEach((param) => {
                const paramArray = param.split("=");
                const key = paramArray[0];
                const value = paramArray[1];
                result[key] = decodeURI(value);
            });

            this._playerSessionService.redirectDepositFeedback =
                result as DepositResponse;
        }
    }

    checkForClaim() {
        const claim = this.tokenService.getUrlParameter(
            "claim",
            this.document.location.href
        );
        let claimCode = "";
        if (
            !!claim &&
            claim.indexOf("undefined") !== 0 &&
            claim.trim() !== ""
        ) {
            claimCode = claim;
        }
        this._playerSessionService.claimCode = claimCode;
    }

    /**
     * Destroy any trace of user info
     * in Cookies and store
     */
    destroyAuth(): void {
        this.cookieService.deleteAll();
    }
}
