import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DepositResponse } from "app/interfaces/deposit.interface";
import { LoggerService } from "app/services/logger/logger.service";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { AccountTypeCode } from "app/enums/account-type-code.enum";

@Injectable({
    providedIn: "root"
})
export class TrackingEventService {
    constructor(
        private readonly loggerService: LoggerService,
        private router: Router,
        private readonly _playerSessionService: PlayerSessionService,
        private readonly paymentMethodsService: PaymentMethodsService
    ) {}

    private get paymentMethodReference():
        | "debit card"
        | "pp"
        | "ps"
        | "apple pay"
        | "unknown" {
        switch (this.paymentMethodsService.currentSelected?.accountTypeCode) {
            case AccountTypeCode.VisaDebit:
                return "debit card";
            case AccountTypeCode.PayPal:
                return "pp";
            case AccountTypeCode.PaySafe:
                return "ps";
            case AccountTypeCode.ApplePay:
                return "apple pay";
            default:
                return "unknown";
        }
    }

    public trackPageView(): void {
        const eventParameter = {
            page_name: this.router.url,
            page_type: ""
        };
        this.dispatchTrackingEvent("page_view", eventParameter);
    }

    public trackDepositSuccess(
        amount: number,
        depositResponse: DepositResponse = null
    ): void {
        /**
         * Use the DepositSuccessCount when available
         * otherwise fallback to the value in the cookies
         */
        let depositSuccessCount: number;
        if (depositResponse && depositResponse.depositCount) {
            depositSuccessCount = depositResponse.depositCount;
        } else {
            depositSuccessCount = this._playerSessionService.depositCount;
        }

        let purchase_id =
            this._playerSessionService.getTxDepositID(depositResponse);
        if (!purchase_id) {
            /**
             * Since there's no CoinRef,
             * Generate a reference using
             * 1. Player ID
             * 2. Customer ID
             * 3. TimeStamp
             */
            const date = new Date();
            const dateArray = [
                date.getFullYear(),
                date.getMonth() + 1, // Months are indexed based, god knows why
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
            ];
            purchase_id = `${this._playerSessionService.customerID}-${
                this._playerSessionService.playerID
            }-${dateArray
                .map((value) => (value < 10 ? `0${value}` : value))
                .join("")}`;
        }

        const eventParameter = {
            daub_id: this._playerSessionService.customerID,
            bede_id: this._playerSessionService.bedeID,
            deposit_count: depositSuccessCount,
            deposit_amount: amount,
            purchase_id: purchase_id,
            deposit_method: this.paymentMethodReference
        };
        this.dispatchTrackingEvent("deposit_success", eventParameter);
    }

    public trackDepositFailed(amount: number, reason: string): void {
        const eventParameter = {
            daub_id: this._playerSessionService.customerID,
            bede_id: this._playerSessionService.bedeID,
            deposit_failed_reason: reason || "Unknown Reason",
            deposit_amount: amount,
            deposit_method: this.paymentMethodReference
        };
        this.dispatchTrackingEvent("deposit_failed", eventParameter);
    }

    public trackAmountWithdrawn(amount: number): void {
        const eventParameter = {
            daub_id: this._playerSessionService.customerID,
            bede_id: this._playerSessionService.bedeID,
            amount_withdrawn: amount
        };
        this.dispatchTrackingEvent("amount_withdrawn", eventParameter);
    }

    public trackAmountWithdrawnFailure(amount: number, reason: string): void {
        const eventParameter = {
            daub_id: this._playerSessionService.customerID,
            bede_id: this._playerSessionService.bedeID,
            amount_withdrawn: amount,
            withdrawn_failure_message: reason
        };
        this.dispatchTrackingEvent("amount_withdrawn_failure", eventParameter);
    }

    private dispatchTrackingEvent(
        eventName: string,
        eventParams: object
    ): void {
        eventParams = eventParams || [];
        eventName = eventName || "";
        try {
            window.dispatchEvent(
                new CustomEvent(eventName, {
                    detail: eventParams
                })
            );
            this.loggerService.info("Tracking Event", {
                eventName: eventName,
                detail: eventParams
            });
        } catch (error) {
            this.loggerService.error("Tracking Dispatch Error:", error);
        }
    }
}
