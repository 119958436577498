<div class="list-item">
    <ng-container
        *ngIf="
            !hasError && (selectedAccount$ | async) as selected;
            else tplBusy
        "
    >
        <ng-container
            [ngTemplateOutlet]="tplItemInner"
            [ngTemplateOutletContext]="{ item: selected }"
        ></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="tplToggle"></ng-container>
</div>
<div id="dropdown" #dropdown hidden>
    <ng-container
        *ngIf="
            !hasError && accountsResponse$ | async as resp;
            else tplErrorMessage
        "
    >
        <ng-container *ngFor="let item of resp.accounts">
            <ng-container
                *ngIf="item.accountId !== selectedAccount?.accountId"
                [ngTemplateOutlet]="tplItem"
                [ngTemplateOutletContext]="{ item: item }"
            ></ng-container>
        </ng-container>
    </ng-container>
    <ng-template #tplErrorMessage>
        <p class="mb-0 border-top pt-3">{{ errorMessage }}</p>
    </ng-template>
    <a
        [routerLink]="[myAccountsURL]"
        [ngClass]="{ 'justify-content-center border-top': !hasError }"
        class="list-item"
        >Manage payment methods</a
    >
</div>

<ng-template #tplBusy>
    <ng-container *ngIf="hasError"
        ><p class="m-0">Payment method required</p></ng-container
    >
    <img
        *ngIf="!hasError"
        src="assets/images/spinner-busy.svg"
        class="animate spin"
    />
</ng-template>

<ng-template #tplItem let-item="item">
    <div
        class="border-top list-item {{ item.accountTypeCode.toLowerCase() }}"
        (click)="selectAccount(item); dropdown.hidden = true"
    >
        <ng-container
            [ngTemplateOutlet]="tplItemInner"
            [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
    </div>
</ng-template>

<ng-template #tplItemInner let-item="item">
    <app-account-type-logo
        [attr.code]="item?.accountTypeCode"
        [code]="item?.accountTypeCode"
        size="compact"
        class="mr-2"
    ></app-account-type-logo>
    <app-account-number
        [account]="item"
        [showExpiry]="false"
        [showApplePayAccountNumber]="false"
    ></app-account-number>
</ng-template>

<ng-template #tplToggle>
    <app-cashier-button
        theme="none"
        id="show-list-button"
        fontWeight="normal"
        class="ml-auto"
        (click)="dropdown.hidden = !dropdown.hidden"
    >
        <ng-container *ngIf="dropdown.hidden; else chevronUpIcon">
            <span class="small text-dark mr-2">Change</span>
            <span cashierIcon="chevron_down" color="#8F8F8F"></span>
        </ng-container>
        <ng-template #chevronUpIcon>
            <span cashierIcon="chevron_up" color="#8F8F8F"></span>
        </ng-template>
    </app-cashier-button>
</ng-template>
