import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import {
    DepositLimitChangeRequest,
    DepositLimitsResponse
} from "app/interfaces/deposit-limits.interface";
import { CashierService } from "app/services/cashier/cashier.service";
import { paths } from "app/paths";
import { map, tap } from "rxjs/operators";
import { DepositLimitStatus } from "app/enums/deposit-limit-status.enum";
import { DepositLimitFormatted } from "app/interfaces/deposit-limit-formatted";
import { TrackingService } from "app/services/tracking/tracking.service";
import { CashierApiService } from "../cashier-api.service";
import { DepositLimitChangeAction } from "app/enums/deposit-limit-change-action.enum";

@Injectable({
    providedIn: "root"
})
export class DepositLimitsService {
    /**
     * An easy to digest limits data object
     * specifically for the frontend
     */
    public playerLimitsFormatted: DepositLimitFormatted = {
        perDay: {
            type: "perDay",
            label: "24 hour net deposit",
            periodDescriptor: "24 hours",
            value: null,
            usage: null,
            DepositLimitGlobal: 0,
            DepositsGlobal: 0,
            available: null,
            lockedMaxValue: null,
            suffixKey: "Day",
            canEdit: true,
            isPending: false,
            requiresConfirm: false,
            pendingAmount: null,
            pendingTime: null
        },
        perWeek: {
            type: "perWeek",
            label: "7 day net deposit",
            periodDescriptor: "7 days",
            value: null,
            usage: null,
            DepositLimitGlobal: 0,
            DepositsGlobal: 0,
            available: null,
            lockedMaxValue: null,
            suffixKey: "Week",
            canEdit: true,
            isPending: false,
            requiresConfirm: false,
            pendingAmount: null,
            pendingTime: null
        },
        perMonth: {
            type: "perMonth",
            label: "30 day net deposit",
            periodDescriptor: "30 days",
            value: null,
            usage: null,
            DepositLimitGlobal: 0,
            DepositsGlobal: 0,
            available: null,
            lockedMaxValue: null,
            suffixKey: "Month",
            canEdit: true,
            isPending: false,
            requiresConfirm: false,
            pendingAmount: null,
            pendingTime: null
        }
    };

    constructor(
        private _cashierService: CashierService,
        private cashierAPIService: CashierApiService,
        private _trackingService: TrackingService,
        private _router: Router
    ) {}

    /**
     * Extracts data from the Player Limits Response
     * into an easily readable format for the Frontend
     *
     * @param resp  DepositLimitsResponse
     * @private
     */
    private formatPlayerLimitsResponse(
        resp: DepositLimitsResponse
    ): DepositLimitsResponse {
        /**
         * this logic needs to be repeated for each period:
         * * daily
         * * weekly
         * * monthly
         */

        // region Daily Limit Formatted
        this.playerLimitsFormatted.perDay.value = resp.depositLimitPerDay;
        this.playerLimitsFormatted.perDay.value = !this.playerLimitsFormatted
            .perDay.value
            ? null
            : this.playerLimitsFormatted.perDay.value;

        /**
         * Global Limit Data
         * When the current period limit data is not set
         * and the Global Limit Data is available
         * Then for this period, we will show the player a Global Limit Radial on the Edit Page
         */
        this.playerLimitsFormatted.perDay.DepositLimitGlobal =
            resp.depositLimitGlobal || null;
        this.playerLimitsFormatted.perDay.DepositsGlobal =
            resp.totalDepositsMonthGlobal || 0;

        this.playerLimitsFormatted.perDay.usage = resp.totalDepositsDay;
        this.playerLimitsFormatted.perDay.available =
            this.playerLimitsFormatted.perDay.value -
            this.playerLimitsFormatted.perDay.usage;

        this.playerLimitsFormatted.perDay.lockedMaxValue =
            resp.lockedDepositLimitPerDay;
        this.playerLimitsFormatted.perDay.pendingAmount =
            resp.pendingDepositLimitPerDay ?? null;
        this.playerLimitsFormatted.perDay.pendingTime =
            resp.dateToConfirmPendingChangeDay ?? null;

        if (
            this.playerLimitsFormatted.perDay.pendingAmount &&
            this.playerLimitsFormatted.perDay.pendingAmount !==
                this.playerLimitsFormatted.perDay.value
        ) {
            this.playerLimitsFormatted.perDay.requiresConfirm =
                resp.depositLimitStatus ===
                DepositLimitStatus.PENDING_CONFIRMATION_AFTER_24;
            this.playerLimitsFormatted.perDay.isPending =
                resp.depositLimitStatus ===
                DepositLimitStatus.PENDING_CONFIRMATION_WITHIN_24;
        } else {
            this.playerLimitsFormatted.perDay.isPending = false;
            this.playerLimitsFormatted.perDay.requiresConfirm = false;
        }

        /**
         * A player can edit their limit if either:
         * 1. There is nothing pending
         * 2. No limits are set
         */
        this.playerLimitsFormatted.perDay.canEdit =
            [
                DepositLimitStatus.NO_LIMITS_SET,
                DepositLimitStatus.NOTHING_PENDING
            ].indexOf(resp.depositLimitStatus) !== -1;
        // endregion

        // region Weekly Limit Formatted
        this.playerLimitsFormatted.perWeek.value = resp.depositLimitPerWeek;
        this.playerLimitsFormatted.perWeek.value = !this.playerLimitsFormatted
            .perWeek.value
            ? null
            : this.playerLimitsFormatted.perWeek.value;

        /**
         * Global Limit Data
         * When the current period limit data is not set
         * and the Global Limit Data is available
         * Then for this period, we will show the player a Global Limit Radial on the Edit Page
         */
        this.playerLimitsFormatted.perWeek.DepositLimitGlobal =
            resp.depositLimitGlobal || null;
        this.playerLimitsFormatted.perWeek.DepositsGlobal =
            resp.totalDepositsMonthGlobal || 0;

        this.playerLimitsFormatted.perWeek.usage = resp.totalDepositsWeek;
        this.playerLimitsFormatted.perWeek.available =
            this.playerLimitsFormatted.perWeek.value -
            this.playerLimitsFormatted.perWeek.usage;
        this.playerLimitsFormatted.perWeek.lockedMaxValue =
            resp.lockedDepositLimitPerWeek;
        this.playerLimitsFormatted.perWeek.pendingAmount =
            resp.pendingDepositLimitPerWeek ?? null;
        this.playerLimitsFormatted.perWeek.pendingTime =
            resp.dateToConfirmPendingChangeWeek ?? null;

        if (
            this.playerLimitsFormatted.perWeek.pendingAmount &&
            this.playerLimitsFormatted.perWeek.pendingAmount !==
                this.playerLimitsFormatted.perWeek.value
        ) {
            this.playerLimitsFormatted.perWeek.requiresConfirm =
                resp.depositLimitStatus ===
                DepositLimitStatus.PENDING_CONFIRMATION_AFTER_24;
            this.playerLimitsFormatted.perWeek.isPending =
                resp.depositLimitStatus ===
                DepositLimitStatus.PENDING_CONFIRMATION_WITHIN_24;
        } else {
            this.playerLimitsFormatted.perWeek.isPending = false;
            this.playerLimitsFormatted.perWeek.requiresConfirm = false;
        }

        /**
         * A player can edit their limit if either:
         * 1. There is nothing pending
         * 2. No limits are set
         */
        this.playerLimitsFormatted.perWeek.canEdit =
            [
                DepositLimitStatus.NO_LIMITS_SET,
                DepositLimitStatus.NOTHING_PENDING
            ].indexOf(resp.depositLimitStatus) !== -1;
        // endregion

        // region Monthly Limit Formatted
        this.playerLimitsFormatted.perMonth.value = resp.depositLimitPerMonth;
        this.playerLimitsFormatted.perMonth.value = !this.playerLimitsFormatted
            .perMonth.value
            ? null
            : this.playerLimitsFormatted.perMonth.value;

        /**
         * Global Limit Data
         * When the current period limit data is not set
         * and the Global Limit Data is available
         * Then for this period, we will show the player a Global Limit Radial on the Edit Page
         */
        this.playerLimitsFormatted.perMonth.DepositLimitGlobal =
            resp.depositLimitGlobal || null;
        this.playerLimitsFormatted.perMonth.DepositsGlobal =
            resp.totalDepositsMonthGlobal || 0;

        this.playerLimitsFormatted.perMonth.usage = resp.totalDepositsMonth;
        this.playerLimitsFormatted.perMonth.available =
            this.playerLimitsFormatted.perMonth.value -
            this.playerLimitsFormatted.perMonth.usage;
        this.playerLimitsFormatted.perMonth.lockedMaxValue =
            resp.lockedDepositLimitPerMonth;
        this.playerLimitsFormatted.perMonth.pendingAmount =
            resp.pendingDepositLimitPerMonth ?? null;
        this.playerLimitsFormatted.perMonth.pendingTime =
            resp.dateToConfirmPendingChangeMonth ?? null;

        if (
            this.playerLimitsFormatted.perMonth.pendingAmount &&
            this.playerLimitsFormatted.perMonth.pendingAmount !==
                this.playerLimitsFormatted.perMonth.value
        ) {
            this.playerLimitsFormatted.perMonth.requiresConfirm =
                resp.depositLimitStatus ===
                DepositLimitStatus.PENDING_CONFIRMATION_AFTER_24;
            this.playerLimitsFormatted.perMonth.isPending =
                resp.depositLimitStatus ===
                DepositLimitStatus.PENDING_CONFIRMATION_WITHIN_24;
        } else {
            this.playerLimitsFormatted.perMonth.isPending = false;
            this.playerLimitsFormatted.perMonth.requiresConfirm = false;
        }

        /**
         * A player can edit their limit if either:
         * 1. There is nothing pending
         * 2. No limits are set
         */
        this.playerLimitsFormatted.perMonth.canEdit =
            [
                DepositLimitStatus.NO_LIMITS_SET,
                DepositLimitStatus.NOTHING_PENDING
            ].indexOf(resp.depositLimitStatus) !== -1;
        // endregion

        return resp;
    }

    /**
     * Fetches the Player's Deposit Limits
     */
    public get(): Observable<DepositLimitsResponse> {
        return this.cashierAPIService
            .getDepositLimits()
            .pipe(map((resp) => this.formatPlayerLimitsResponse(resp)));
    }

    /**
     * Requests a change to Deposit Limits
     * @param newAmount
     */
    public change(newAmount: DepositLimitChangeRequest): Observable<void> {
        const mergedAmounts = {
            ...{ daily: 0, weekly: 0, monthly: 0 },
            ...newAmount
        };

        return this.cashierAPIService.setDepositLimits(newAmount).pipe(
            tap(async (resp) => {
                this._trackingService.trackDepositLimitsChange(
                    resp,
                    mergedAmounts
                );
                // Need to get the current limits
                this.cashierAPIService.getDepositLimits();
            })
        );
    }

    /**
     * Successful Responses redirect the player to the Deposit Limits Overview screen
     * Unsuccessful Responses go to the Error Screen
     * @param limitsEditResponse
     * @public
     */
    public redirectToResponsePage(limitsEditResponse): void {
        const skipLocationChange = false;
        let url = `/${paths.BASE}/${paths.DEPOSIT_LIMITS}`;

        if (limitsEditResponse?.code || limitsEditResponse?.error) {
            url += `/${paths.RESPONSE}`;
        }

        this._router.navigate([url], {
            skipLocationChange: skipLocationChange,
            state: {
                transactionResponse: limitsEditResponse
            }
        });
    }

    /**
     * Sets a players deposit limit
     * @param depositLimitChangeAction
     * Cancels a Player set pending deposit limit
     */
    public async setDepositLimit(
        depositLimitChangeAction: DepositLimitChangeAction
    ): Promise<void> {
        const depositLimitChangeRequest = {
            changeAction: depositLimitChangeAction
        };
        const depositLimitResponse = await this.cashierAPIService
            .setDepositLimits(depositLimitChangeRequest)
            .toPromise();
        this.redirectToResponsePage(depositLimitResponse);
    }
}
