<div class="container-fluid">
    <div class="row">
        <app-cashier-button
            class="col mb-2"
            *ngFor="let option of options$ | async | slice: 0:3"
            [theme]="value === option ? 'secondary' : 'secondary-outline'"
            size="large"
            [disabled]="breachesAffordabilityLimit(option)"
            (click)="select(option)"
        >
            {{ option | playerCurrency: false }}
        </app-cashier-button>
    </div>
</div>
