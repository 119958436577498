import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import {
    CoinAccount,
    GetCustomerAccountsResponse
} from "app/interfaces/accounts.interface";
import { paths } from "app/paths";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { CardComponent } from "app/components/card/card.component";
import { APP_ERROR_MESSAGES } from "app/app.settings";
import { tap } from "rxjs/operators";

@Component({
    selector: "app-my-accounts",
    templateUrl: "./my-accounts.component.html"
})
export class MyAccountsComponent implements OnInit {
    public customerAccounts: Observable<GetCustomerAccountsResponse>;
    public addAccountURL = `/${paths.BASE}/${paths.ADD_ACCOUNT}`;
    public errorMessage = APP_ERROR_MESSAGES.NO_PAYMENT_METHODS_AVAILABLE;
    public get maxNumberOfAccountsReached(): boolean {
        return this.paymentMethodsService.maxReached;
    }

    constructor(private paymentMethodsService: PaymentMethodsService) {}

    ngOnInit(): void {
        this.fetchAccounts();
    }

    public disablePlayersAccount(
        account: CoinAccount,
        cardComponent: CardComponent
    ): void {
        this.paymentMethodsService.disable(account).then((result) => {
            if (!result) {
                cardComponent.status = "error";
                return result;
            }
            cardComponent.fadeOut();
        });
    }

    /**
     * When fetching accounts for this component, we would like to make sure the hardcoded apple account does not show up in this list
     */
    private fetchAccounts() {
        this.customerAccounts = this.paymentMethodsService
            .get()
            .pipe(
                tap(
                    (response: GetCustomerAccountsResponse) =>
                        (response.accounts = response.accounts.filter(
                            (account) => account?.accountId
                        ))
                )
            );
    }
}
