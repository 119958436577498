import { Component } from "@angular/core";
import { paths } from "app/paths";

@Component({
    selector: "app-add-account-debit-card",
    templateUrl: "./add-account-debit-card.component.html"
})
export class AddAccountDebitCardComponent {
    public aboutStoringLink = `/${paths.BASE}/${paths.ADD_ACCOUNT}/${paths.ABOUT_STORING_CARDS}`;
}
