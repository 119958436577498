import { Component, HostBinding } from "@angular/core";

@Component({
    selector: "app-cashier-button-close",
    templateUrl: "./cashier-button-close.component.html",
    styleUrls: ["./cashier-button-close.component.scss"]
})
export class CashierButtonCloseComponent {
    @HostBinding("class") className = "position-absolute p-3";
}
