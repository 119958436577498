export const APP_SETTINGS = {
    deposit: {
        min: 5
    },
    PAYPAL: {
        FRAUDNET: {
            BEACON_JS_URL: "https://c.paypal.com/da/r/fb.js",
            FLOW_ID: "Rank_HANYHQ79KRKWJ_PYMNT"
        }
    }
};

export const APP_COOKIE_KEYS = {
    AUTH_TOKEN: "cashierToken",
    COIN_REF: "coinRef",
    FROM_PROMOTIONS: "fromPromotions",
    PAYPAL_FASTPAY_TOGGLE_LAST_STATE: "PAYPAL_FASTPAY_TOGGLE_LAST_STATE",
    LAST_SELECTED_ACCOUNTTYPE_CODE: "LAST_SELECTED_ACCOUNTTYPE_CODE"
};

export const APP_ERROR_MESSAGES = {
    GENERIC: "An unexpected error occurred",
    NO_PAYMENT_METHODS_AVAILABLE:
        "Unfortunately, there are no payment methods available"
};

export const CLOSE_CASHIER_EVENTS = {
    APP_CLOSE_CASHIER: "APP_CLOSE_CASHIER",
    APP_CLOSE_CASHIER_AND_OPEN_SUPPORT: "APP_CLOSE_CASHIER_AND_OPEN_SUPPORT",
    APP_CLOSE_CASHIER_AND_OPEN_SURVEY: "APP_CLOSE_CASHIER_AND_OPEN_SURVEY",
    APP_CLOSE_CASHIER_AND_OPEN_PRIVACY_POLICY:
        "APP_CLOSE_CASHIER_AND_OPEN_PRIVACY_POLICY",
    APP_CLOSE_CASHIER_AND_LOGOUT: "APP_CLOSE_CASHIER_AND_LOGOUT",
    /**
     * @deprecated
     */
    APP_CLOSE_CASHIER_AND_LOGOUT_OPEN_SUPPORT:
        "APP_CLOSE_CASHIER_AND_LOGOUT_OPEN_SUPPORT",
    /**
     * @deprecated
     */
    APP_CLOSE_CASHIER_AND_PRIZE_WHEEL: "APP_CLOSE_CASHIER_AND_PRIZE_WHEEL",
    /**
     * @deprecated
     */
    APP_CLOSE_CASHIER_AND_OPEN_BINGO: "APP_CLOSE_CASHIER_AND_OPEN_BINGO",
    /**
     * @deprecated
     */
    APP_CLOSE_CASHIER_AND_OPEN_MENU: "APP_CLOSE_CASHIER_AND_OPEN_MENU"
};

export const REDIRECT_PARENT_EVENTS = {
    APP_REDIRECT_PAYPAL: "APP_REDIRECT_PAYPAL",
    APP_REDIRECT_PAYSAFE: "APP_REDIRECT_PAYSAFE"
};
