import { Injectable } from "@angular/core";
import { SkinService } from "app/services/skin/skin.service";
import { EnvironmentService } from "app/services/environment/environment.service";
import * as importedConfig from "assets/config.json";

const enum envName {
    ALL = "all",
    DEVELOPMENT = "development",
    STAGING = "staging",
    PERFORMANCE = "perf",
    PRODUCTION = "production"
}

export const enum SupportedFeatures {
    BONUS_DROPDOWN_DEFAULT_STATE = "bonusDropDownDefaultState",
    CASHIER_HEADER = "cashierHeader",
    CASHIER_FOOTER = "cashierFooter",
    DEPOSIT_PROMOS = "displayPromoCodeInput",
    DEPOSIT_BONUSES = "displayBonusList",
    DEPOSIT_RETRIES = "depositRetries",
    PROMO_DEPOSIT = "promoDeposit",
    TOKENIZATION_RETRIES = "tokenizationRetries",
    WITHDRAW_ACCOUNT_SELECTION = "withdrawAccountSelectionAllowed",
    WITHDRAW_PRE_ALLOCATION = "showWithdrawPreAllocation",
    unSupportedFTDBonusPaymentMethods = "unSupportedFTDBonusPaymentMethods",
    PROMPT_TO_USE_APPLE_PAY = "promptToUseApplePay",
    ENABLE_TRUSTLY_PAYMENT_METHOD = "enableTrustlyPaymentMethod",
    WITHDRAW_TO_TRUSTLY="enableWithdrawToTrustly",
    WITHDRAW_TO_PREFERRED_ACCOUNT="enableWithdrawToPreferredAccount"
}
type FeatureToggleOptions = Record<SupportedFeatures, string | boolean>;

interface Config {
    global: FeatureToggleOptions;
    environment: Record<envName, FeatureToggleOptions>;
    skin: Record<number, Record<envName, FeatureToggleOptions>>;
}

@Injectable({
    providedIn: "root"
})
export class FeatureToggleService {
    // eslint-disable-next-line
    // @ts-ignore
    private readonly config: Config = importedConfig.default;

    constructor(
        private readonly environmentService: EnvironmentService,
        private _skinService: SkinService
    ) {}

    private get currentEnv(): string {
        return this.environmentService.BUILD;
    }

    /**
     * This will look through all the configs and fetch the config
     * Value hierarchy:
     * 1. Skin Environment value
     * 2. Skin All environment value
     * 3. Environment value
     * 4. CMS (epi vs rad2)
     * 5. Global value
     * @private
     */
    public getConfigValue<T>(feature: SupportedFeatures, defaultValue?: T): T {
        let value;
        const hierarchy = [
            `skin.${this._skinService.currentID}.${this.currentEnv}`,
            `skin.${this._skinService.currentID}.${envName.ALL}`,
            `environment.${this.currentEnv}`
        ];

        /**
         * If this is an EPI Skin,
         * Load the relevant configs from config.json
         */
        if (this._skinService?.current?.isEPI) {
            hierarchy.push("cms.epi");
        }

        hierarchy.push("global");

        for (let j = 0; j < hierarchy.length && value === undefined; j++) {
            const configPath = `${hierarchy[j]}.${feature}`;
            value = configPath.split(".").reduce((o, i) => {
                if (o === undefined) {
                    return undefined;
                }
                try {
                    return o[i];
                } catch (ex) {
                    return undefined;
                }
            }, this.config);
        }
        return value ?? defaultValue;
    }
}
