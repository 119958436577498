<sbmedia-response-type
    [size]="'medium'"
    [type]="'success'"
></sbmedia-response-type>
<div class="my-3 d-flex align-items-center flex-column">
    <div>
        <h6 class="text-success text-center">Payment Method Added</h6>
        <p>You successfully added the following payment method:</p>
    </div>
</div>

<app-card [account]="account" [showCTA]="false" class="mb-4"></app-card>
<app-cashier-button class="my-2 w-100" (click)="selectAccount(account)"
    >Deposit</app-cashier-button
>
