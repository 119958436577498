import { Component, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PlayerService } from "app/services/player/player.service";
import { CashierButtonComponent } from "app/components/cashier-button/cashier-button.component";
import { SafeChargeService } from "app/services/safe-charge/safe-charge.service";
import {
    ChangeEvent,
    CreatePaymentRequest,
    SafeChargeFieldDefinition
} from "app/interfaces/safe-charge";
import { MaskService } from "app/services/mask/mask.service";
import { LoggerService } from "app/services/logger/logger.service";
import { CardHolderValidator } from "app/validators/card-holder.validator";
import { CashierApiService } from "app/services/cashier-api.service";
import { TokenizedCardResponse } from "app/interfaces/tokenize-card.interface";

@Component({
    selector: "app-nuvei-cde-form",
    templateUrl: "./nuvei-cde-form.component.html",
    styleUrls: ["./nuvei-cde-form.component.scss"]
})
export class NuveiCDEFormComponent implements OnInit, OnDestroy {
    private tokenizationResponse: TokenizedCardResponse;
    private sfcFields: SafeChargeFieldDefinition[] = [
        {
            field: null,
            sfcID: "ccNumber",
            htmlID: "cardNumber"
        },
        {
            field: null,
            sfcID: "ccCvc",
            htmlID: "cardCVV"
        },
        {
            field: null,
            sfcID: "ccExpiration",
            htmlID: "cardExpiry"
        }
    ];

    public formGroup: FormGroup;

    public cvvField = this.sfcFields.find((i) => i.sfcID === "ccCvc");
    public expiryField = this.sfcFields.find((i) => i.sfcID === "ccExpiration");
    public cardNumberField = this.sfcFields.find((i) => i.sfcID === "ccNumber");

    @ViewChild("btnSubmit") btnSubmit: CashierButtonComponent;

    constructor(
        private _zone: NgZone,
        private paymentMethodsService: PaymentMethodsService,
        private _formBuilder: FormBuilder,
        private readonly loggerService: LoggerService,
        private readonly maskService: MaskService,
        private _safeChargeService: SafeChargeService,
        private cashierApiService: CashierApiService,
        private _playerService: PlayerService
    ) {
        this.maskService.show();
        this.addSafeChargeListener();
    }

    ngOnInit(): void {
        this.setupReactiveForm();
        this.initAddCard();
    }

    ngOnDestroy(): void {
        this.removeSafeChargeListener();
    }

    /**
     * Listen for message events from the SafeCharge iFrame
     * @private
     */
    private async addSafeChargeListener(): Promise<void> {
        window.addEventListener("message", this.onSafeChargeMessage);
    }

    private async removeSafeChargeListener() {
        window.removeEventListener("message", this.onSafeChargeMessage);
    }

    /**
     * Primary purpose is to check if SafeCharge is loaded or not
     * Will toggle the visibility of the form overlay
     * @param message
     */
    private onSafeChargeMessage = (message): void => {
        if (message.origin !== "https://cdn.safecharge.com") {
            return;
        }

        if (!message?.data && typeof message.data !== "string") {
            return;
        }

        let messageData;
        try {
            messageData = JSON.parse(message.data);

            if (messageData.event === "ready") {
                this.maskService.hide();
            } else if (messageData.event === "load") {
                this.maskService.show();
            }
        } catch (exception) {
            this.loggerService.warn(
                "onSafeChargeMessage: Error trying to parse event data",
                { ...message.data, ...{ journey: "tokenization" } }
            );
        }
    };

    /**
     * Sets up the Reactive Form Group
     * @private
     */
    private async setupReactiveForm(): Promise<void> {
        this.formGroup = this._formBuilder.group({
            cardHolder: [
                this._playerService.name,
                [
                    Validators.required,
                    CardHolderValidator.consecutiveNumberCheck
                ]
            ]
        });
    }

    private async setupSafeChargeFields(): Promise<void> {
        this._safeChargeService.setupFields(this.sfcFields).then(() => {
            this.sfcFields.forEach((item) => {
                item.field.on("change", (changeEvent: ChangeEvent) => {
                    this.onSafeChargeFieldChange(changeEvent);
                });
            });
            this.maskService.hide();
        });
    }

    private onSafeChargeFieldChange(changeEvent: ChangeEvent): void {
        const field = this.sfcFields.find((i) => i.sfcID === changeEvent.field);
        if (!field) {
            return;
        }

        field.validation = changeEvent;
        if (field.validation.error === undefined) {
            return;
        }

        /**
         * Custom default Error Messages
         */
        if (field.sfcID === "ccCvc") {
            field.validation.error.message = "Invalid CVV";
        } else if (field.sfcID === "ccNumber") {
            field.validation.error.message =
                "A valid debit card number is required";
        } else if (field.sfcID === "ccExpiration") {
            const validationMessageArray = field.validation.error.message
                .split("_")
                .join(" ");
            field.validation.error.message =
                validationMessageArray.charAt(0).toUpperCase() +
                validationMessageArray.slice(1);
        }
    }

    /**
     * Initialize the Add Card Journey
     * @private
     */
    private async initAddCard(isRetrying = false) {
        try {
            const tokenizationResponse = await this.cashierApiService
                .tokenizeCard()
                .toPromise();
            if (isRetrying) {
                this.maskService.hide();
            } else {
                this.setupSafeChargeFields();
            }
            this.tokenizationResponse = tokenizationResponse;
        } catch (error) {
            this.paymentMethodsService.redirectToResponsePage(await error);
        }
    }

    /**
     * Because we're integrating with an external SDK
     * We need to cater for the edge cases should they arise
     * @param errorMessage string
     * @param safeChargeResponse
     * @private
     */
    private handleError(errorMessage: string, safeChargeResponse = null): void {
        const defaultData = {
            code: "DoNotHonour",
            message: errorMessage
        };

        this.paymentMethodsService.redirectToResponsePage(
            defaultData,
            safeChargeResponse
        );
    }

    /**
     * callback for safeCharge.createPayment
     * @see submitForm
     * @param safeChargeResponse
     */
    private onCreatePayment = (safeChargeResponse): void => {
        this._zone.run(() => {
            const logData = safeChargeResponse;
            logData.journey = "tokenization";
            this.loggerService.info("SafeCharge Response", logData);
            if (safeChargeResponse.result !== "APPROVED") {
                /**
                 * Check whether the error from SafeCharge is recoverable
                 * And also check if the player is within their allowable retries
                 * If so...
                 * Show the Tokenization Form with an error and recall the AddCard Start method
                 */
                const currentError =
                    this._safeChargeService.recoverableTokenizationErrors.find(
                        (i) =>
                            i.errorDescription ===
                            safeChargeResponse?.errorDescription
                    );
                const allowRetry =
                    this.paymentMethodsService.maxTokenizationRetries >
                        this.paymentMethodsService.tokenizationRetryCount &&
                    currentError !== undefined;
                this.paymentMethodsService.tokenizationRetryCount++;
                if (allowRetry) {
                    this.maskService.show();
                    this.cvvField.validation.error = {
                        id: "retry_cvn",
                        message: "Retry CVV"
                    };
                    this.initAddCard(true);
                    this.btnSubmit.disabled = false;
                    this.btnSubmit.text = "Continue";
                    this.loggerService.info("Allowing Player to Retry CDE", {
                        allowRetry,
                        currentError,
                        retryCount:
                            this.paymentMethodsService.tokenizationRetryCount,
                        retriesAllowed:
                            this.paymentMethodsService.maxTokenizationRetries
                    });
                    return;
                }

                return this.handleError(
                    "Registration of the card was unsuccessful",
                    safeChargeResponse
                );
            }
            this.cashierApiService
                .AddAccount(this.tokenizationResponse.gatewayEnc)
                .toPromise()
                .then((result) => {
                    this.paymentMethodsService.redirectToResponsePage(
                        result,
                        safeChargeResponse
                    );
                })
                .catch((errors) => {
                    if (errors?.code) {
                        this.paymentMethodsService.redirectToResponsePage(
                            errors,
                            safeChargeResponse
                        );
                    } else {
                        this.handleError(
                            "Registration of the card was unsuccessful",
                            safeChargeResponse
                        );
                    }
                });
        });
    };

    /**
     * Check if both the form group and the safe charge fields are complete / valid
     */
    public get formIsComplete(): boolean {
        let result = this.formGroup.valid;

        /**
         * Check all safe charge validation messages
         */
        result = this.sfcFields.reduce((previous, current) => {
            return (
                previous &&
                current.validation !== undefined &&
                current.validation.error === undefined
            );
        }, result);

        return result;
    }

    public submitForm(): void {
        /**
         * If any of these are not available, we'll need to get the player outta here and report and error
         */
        if (!this.tokenizationResponse.gatewaySessionToken) {
            return this.handleError("Missing GatewaySessionToken");
        }

        /**
         * If the form is invalid
         * OR
         * SafeCharge has reported that all the fields are not complete...
         * ...prevent submission
         */
        if (!this.formIsComplete) {
            const animationClass = " animate shake";
            this.btnSubmit.cssClass += animationClass;
            setTimeout(() => {
                this.btnSubmit.cssClass = this.btnSubmit.cssClass.replace(
                    animationClass,
                    ""
                );
            }, 600);
            this.loggerService.info(
                "Player tried to submit an incomplete CDE Form",
                {
                    journey: "tokenization",
                    formGroupValidity: this.formGroup.valid,
                    safeChargeValidationMessages: {
                        cardNumberField: this.cardNumberField.validation,
                        cvvField: this.cvvField.validation,
                        expiryField: this.expiryField.validation
                    }
                }
            );
            return;
        }

        this.btnSubmit.disabled = true;
        this.btnSubmit.text = "Wait";
        this.maskService.show();
        const createPaymentData: CreatePaymentRequest = {
            sessionToken: this.tokenizationResponse.gatewaySessionToken,
            cardHolderName: this.formGroup.value.cardHolder,
            paymentOption: this.cardNumberField.field,
            billingAddress: {
                email: this.tokenizationResponse.email,
                country: this.tokenizationResponse.countryCode
            }
        };

        this._safeChargeService.sdk.createPayment(
            createPaymentData,
            this.onCreatePayment
        );
    }
}
