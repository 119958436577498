<app-cashier-layout headerYMargin="small" headerXMargin="small">
    <app-button-back slot="header"></app-button-back>
    <ng-container *ngIf="this.customerAccounts | async as result; else loader">
        <section id="card-list" class="px-1">
            <ng-container
                *ngIf="
                    result.accounts && result.accounts.length;
                    else errorNotice
                "
            >
                <app-card
                    *ngFor="let account of result.accounts; let first = first"
                    #cardItem
                    [account]="account"
                    [showDelete]="true"
                    (onCTAClick)="disablePlayersAccount(account, cardItem)"
                    [ngClass]="{ 'mt-2': !first }"
                >
                </app-card>
            </ng-container>
            <ng-template #errorNotice>
                <p class="mt-3">{{ errorMessage }}</p>
            </ng-template>
        </section>
        <app-info-panel *ngIf="maxNumberOfAccountsReached" class="d-flex my-3">
            <p>
                You have reached the maximum amount of payment methods. Please
                remove a method to add a new one.
            </p>
        </app-info-panel>
        <app-cashier-button
            class="mt-3"
            [routerLink]="[addAccountURL]"
            [disabled]="maxNumberOfAccountsReached"
            >Add new payment method</app-cashier-button
        >
    </ng-container>
</app-cashier-layout>

<ng-template #loader>
    <app-loader></app-loader>
</ng-template>
