import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
    PromoCheckRequest,
    PromoCheckResponseError,
    PromoCheckResponseSuccess
} from "app/interfaces/promo";
import { Promo } from "app/interfaces/accounts.interface";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { EnvironmentService } from "app/services/environment/environment.service";

@Injectable({
    providedIn: "root"
})
export class PromoService {
    private readonly noPromoPayload = {
        id: null,
        code: null,
        isOffer: false
    };
    public promoPayload$ = new BehaviorSubject(this.noPromoPayload);
    public selected: Promo;

    constructor(
        private readonly environmentService: EnvironmentService,
        private _playerSessionService: PlayerSessionService,
        private _http: HttpClient
    ) {}

    public validateCode(code: string): Observable<PromoCheckResponseSuccess> {
        const data: PromoCheckRequest = {
            playerId: this._playerSessionService.playerID.toString(),
            promoCode: code
        };

        return this._http
            .post<PromoCheckResponseSuccess>(
                this.environmentService.PLAYER_WALLET + "/promocode/check",
                data
            )
            .pipe(
                tap((resp) => {
                    this.selected = null;
                    this.updateSubject(code, resp.PromoId);
                }),
                catchError((err: PromoCheckResponseError) => {
                    this.selected = null;
                    this.updateSubject();
                    return throwError(err);
                })
            );
    }

    public selectBonus(bonus: Promo): void {
        if (bonus && bonus.promoId) {
            this.selected = bonus;
            this.updateSubject(null, +bonus.promoId, bonus.isOffer);
        } else {
            this.selected = null;
            this.updateSubject();
        }
    }

    /**
     * Clears any previous bonus or promo selection
     */
    public clear(): void {
        this.selected = null;
        this.updateSubject();
    }

    /**
     * Will update the promo payload subject,
     * when the validation check finishes
     * That way whatever listens to this subject,
     * will get new values
     *
     * Based on the input, different payloads are sent
     *
     * @see validateCode
     *
     * @param validPromoCode
     * @param validPromoID
     * @private
     */
    public updateSubject(
        validPromoCode: string = null,
        validPromoID: number = null,
        isOffer = false
    ): void {
        const payload = { ...this.noPromoPayload };

        /**
         * When talking to COIN...
         * If there is a valid promo code,
         * reset the promo ID to -1
         * to allow for the sProc logic to run
         * sProc: CoinSkinDeposit
         *
         * Coin needs either one, not both
         *
         * @link    https://bitbucket.org/spacebarmediadev/cashier-frontend/commits/afcf3fd430ccd96b131c78225da33f86d3721af8
         * @link    https://daubltd.atlassian.net/browse/CSI-187
         * @link    https://daubltd.atlassian.net/browse/BONUS-589
         */
        if (validPromoCode) {
            payload.id = null;
            payload.code = validPromoCode;
        } else if (validPromoCode === null && validPromoID !== null) {
            /**
             * This happens when a bonus is selected...
             * No Promo Code is available,
             * just the promo id
             */
            payload.id = validPromoID;
        }
        payload.isOffer = isOffer;
        this.promoPayload$.next(payload);
    }

    /**
     * The cashier can be launched with a `claim` parameter which contains a promo code
     * This code is saved to the cookies in
     * @see AuthService.checkForClaim
     * @public
     * @returns false   when there is no promo code in the cookies
     *          string  the promoCode that is stored in the cookies
     */
    public hasCodeInCookies(): false | string {
        const promoCodeInCookie = this._playerSessionService.claimCode;
        if (!promoCodeInCookie || promoCodeInCookie === "undefined") {
            return false;
        } else {
            return promoCodeInCookie;
        }
    }
}
