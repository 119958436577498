<sbmedia-response-type
    [size]="'medium'"
    [type]="'failure'"
></sbmedia-response-type>

<div class="mt-3 d-flex align-items-center flex-column">
    <div class="text-center">
        <h5 class="text-danger">Withdrawal Unsuccessful</h5>
    </div>

    <div class="my-3">
        <ng-container *ngIf="systemMessage; else defaultText">
            <p [innerHTML]="systemMessage"></p>
        </ng-container>
        <ng-template #defaultText>
            <p>
                Sorry, we weren’t able to process your withdrawal request as
                your real balance is zero.
            </p>
        </ng-template>
    </div>
</div>

<app-cashier-button appCloseCashier>Close</app-cashier-button>

<a class="mt-3 d-block text-center text-secondary" appChatWithUs
    >Chat with us</a
>
