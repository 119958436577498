import { AbstractControl, ValidationErrors } from "@angular/forms";

export function mustBeTruthy(
    control: AbstractControl
): ValidationErrors | null {
    const truthyValues = [true, "true", 1, "1"];
    if (truthyValues.indexOf(control.value) === -1) {
        return { mustBeTrue: true };
    }
    return null;
}
