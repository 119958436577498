import { Component } from "@angular/core";
import { paths } from "app/paths";

@Component({
    selector: "app-no-deposits-yet",
    templateUrl: "./no-deposits-yet.component.html"
})
export class NoDepositsYetComponent {
    public depositPageURL = `/${paths.BASE}/${paths.DEPOSIT}`;
}
