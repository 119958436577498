import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { paths } from "app/paths";
import { TrackingService } from "app/services/tracking/tracking.service";
import { PromoService } from "app/services/promo/promo.service";
import { PlayerService } from "app/services/player/player.service";
import { TUStatus } from "app/interfaces/start.interface";
import { APP_SETTINGS } from "app/app.settings";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { CashierApiService } from "../cashier-api.service";

@Injectable({
    providedIn: "root"
})
export class DepositService {
    public readonly maxRetries = this._featureToggleService.getConfigValue(
        SupportedFeatures.DEPOSIT_RETRIES,
        1
    );
    public retryCount = 0;

    constructor(
        private cashierAPIService: CashierApiService,
        private readonly _featureToggleService: FeatureToggleService,
        private _trackingService: TrackingService,
        private _promoService: PromoService,
        private _playerService: PlayerService,
        private _router: Router
    ) {}

    /**
     * TODO: this value should really come from the StartResponse
     */
    public get minAllowed(): number {
        return APP_SETTINGS.deposit.min;
    }

    /**
     * Get the maximum amount the player is allowed to deposit at this time
     * @returns number
     */
    public get maxAllowed(): number {
        /**
         * Pivot Functionality
         * Use the AvailableToDeposit field as the source of truth
         */
        const availableToDeposit =
            this.cashierAPIService.playerData.deposit?.max;

        if (this.hasAvailableToDeposit) {
            return availableToDeposit < this.minAllowed
                ? 0
                : availableToDeposit;
        }

        const valuesToCompare = [
            this.cashierAPIService.availableToDepositDay,
            this.cashierAPIService.availableToDepositWeek,
            this.cashierAPIService.availableToDepositMonth
        ];
        if (this.cashierAPIService.playerData.deposit.max) {
            valuesToCompare.push(this.cashierAPIService.playerData.deposit.max);
        }

        return Math.min(...valuesToCompare.filter((i) => !isNaN(i)));
    }

    public get hasAvailableToDeposit(): boolean {
        const availableToDeposit =
            this.cashierAPIService.playerData.deposit.max;
        return (
            [undefined, null].indexOf(availableToDeposit) === -1 &&
            !isNaN(availableToDeposit)
        );
    }

    /**
     * Redirects the user to the Deposit Response Screen
     * This screen will determine whether it is successful or not
     * with the deposit response as route data
     * @param depositResponse
     * @param depositAmount
     * @param safeChargeResponse
     * @public
     */
    public redirectToResponsePage(
        depositResponse: any,
        depositAmount = 0,
        safeChargeResponse = null
    ): void {
        this.retryCount = 0;
        this._trackingService.trackDeposits(
            depositResponse,
            depositAmount,
            safeChargeResponse
        );

        this._promoService.clear();
        const isError = CashierApiService.isErrorResponse(
            depositResponse,
            safeChargeResponse
        );
        this._router.navigate(
            [
                `/${paths.BASE}/${paths.DEPOSIT}/${paths.RESPONSE}/${
                    isError ? "error" : "success"
                }`
            ],
            {
                state: {
                    isCashierServiceTransaction: true,
                    cashierServiceResponse: depositResponse,
                    transactionAmount: depositAmount,
                    safeChargeResponse: safeChargeResponse
                }
            }
        );
    }

    /**
     * Checks if the deposit amount, along with the current loss limit so far
     * breaches the loss limit for the month
     * ..according to the Affordability Loss Limits
     * @param depositAmount
     */
    public breachesAffordabilityLimit(depositAmount: number): boolean {
        /**
         * If there is no Affordability Data... bail
         */
        if (!this.cashierAPIService.hasTUStatus) {
            return false;
        }

        /**
         * Don't run these checks for GREEN Players
         */
        if (this._playerService.TUStatus === TUStatus.GREEN) {
            return false;
        }

        return depositAmount > this.cashierAPIService.playerData.deposit.max;
    }
}
