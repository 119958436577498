import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { TokenService } from "app/services/token/token.service";

@Injectable({
    providedIn: "root"
})
export class GtmService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private tokenService: TokenService
    ) {}

    scriptLoaded = false;
    public loadScripts(): void {
        if (!this.scriptLoaded) {
            const gtmId = this.tokenService.getUrlParameter(
                "gtmId",
                this.document.location.href
            );

            if (gtmId === null || gtmId === "") {
                return;
            }

            // TODO: CSI-363: load this via the script loader service
            // TODO: make it async
            const dataLayerScript = document.createElement("script");
            dataLayerScript.type = "text/javascript";
            dataLayerScript.innerHTML = `let dataLayer = [];`;

            const gtmScript = document.createElement("script");
            gtmScript.async = true;
            gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`;

            const body = document.getElementsByTagName("body")[0];
            const gtmUI = document.createElement("noscript");
            const gtmIframe = document.createElement("iframe");
            gtmIframe.setAttribute(
                "src",
                `https://www.googletagmanager.com/ns.html?id=${gtmId}`
            );
            gtmIframe.setAttribute("height", "0");
            gtmIframe.setAttribute("width", "0");
            gtmIframe.setAttribute(
                "style",
                "display:none !important; visibility:hidden;"
            );
            gtmUI.appendChild(gtmIframe);
            body.insertBefore(
                gtmUI,
                document.getElementsByTagName("app-root")[0]
            );

            this.scriptLoaded = true;

            document
                .getElementsByTagName("head")[0]
                .appendChild(dataLayerScript);
            document.getElementsByTagName("head")[0].appendChild(gtmScript);
        }
    }
}
