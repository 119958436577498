import { Injectable } from "@angular/core";
import { CashierService } from "app/services/cashier/cashier.service";
import { Observable } from "rxjs";
import {
    GetWithdrawDetailResponse,
    WithdrawResponse
} from "app/interfaces/withdraw.interface";
import { tap } from "rxjs/operators";
import { CoinStatus } from "app/enums/coin-status.enum";
import { paths } from "app/paths";
import { Router } from "@angular/router";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { TrackingService } from "app/services/tracking/tracking.service";
import { SkinService } from "app/services/skin/skin.service";
import { Skins } from "app/enums/skin-id.enum";
import { CashierApiService, CashierServiceError } from "../cashier-api.service";
import { AccountTypeCode } from "app/enums/account-type-code.enum";

@Injectable({
    providedIn: "root"
})
export class WithdrawService {
    public static bonusValue: number;

    constructor(
        private _cashierService: CashierService,
        private cashierAPIService: CashierApiService,
        private readonly skinService: SkinService,
        private _trackingService: TrackingService,
        private _router: Router
    ) {}

    /**
     * Will use this to determine if a bonus will be forfeited
     * static method so we don't have added dependancy on this service
     * @returns boolean
     */
    public static hasForfeitedBonus(): boolean {
        if (this.bonusValue) return true;
        return false;
    }

    public hasAllocations(withdrawResponse: WithdrawResponse): boolean {
        return (
            withdrawResponse.allocations &&
            withdrawResponse.allocations.length !== 0
        );
    }

    /**
     * Uses the Withdrawal Descriptor specified on the Skin Config
     * UNLESS...
     * The Allocation contains an APM allocation...
     * Then it will use "Rank Group"
     *
     * @param withdrawSuccessResponse
     */
    public getDescriptor(withdrawSuccessResponse: WithdrawResponse): string {
        withdrawSuccessResponse.allocations = withdrawSuccessResponse
            .allocations.length
            ? withdrawSuccessResponse.allocations
            : [];

        const accountTypesAllocated = withdrawSuccessResponse.allocations.map(
            (account) => {
                return account.accountTypeCode;
            }
        );

        const filtered = new Set(accountTypesAllocated);
        if (!filtered.has(AccountTypeCode.PayPal)) {
            return (
                Skins.find((s) => s.id === this.skinService.currentID)
                    .withdrawalDescriptor ?? "DAUB"
            );
        }

        /**
         * Has PayPal but others as well
         */
        if (filtered.size !== 1) {
            return null;
        }

        return "Rank Interactive";
    }

    /**
     * Gets withdraw related info for the player, such as:
     * 1. Amount available to withdraw
     * 2. Minimum amount to withdraw
     * 3. Maximum amount to withdraw
     *
     * If the request has a status other than Success,
     * redirect to the response page
     */
    public get initData(): Observable<GetWithdrawDetailResponse> {
        return this._cashierService.getWithdrawDetail().pipe(
            tap((resp) => {
                if (resp.Status === CoinStatus.Success) {
                    WithdrawService.bonusValue = +resp?.BonusToBeForfeited;
                    return;
                }
                this._router.navigate(
                    [
                        `/${paths.BASE}/${paths.WITHDRAW}/${paths.RESPONSE}/error`
                    ],
                    {
                        state: {
                            transactionResponse: resp,
                            transactionAmount: null,
                            accountUsed: null
                        }
                    }
                );
            })
        );
    }

    /**
     * Performs a Withdraw Request
     * @param amount
     * @param accountUsed
     */
    public async withdraw(
        amount: number,
        accountUsed: CoinAccount
    ): Promise<void> {
        const withdrawalResponse = this.cashierAPIService
            .withdraw(amount)
            .toPromise();

        withdrawalResponse
            .then((result) => {
                this.redirectToResponsePage(result, amount, accountUsed);
                this._trackingService.trackWithdraw(result, amount);
            })
            .catch((err) => {
                this.redirectToResponsePage(err, amount, accountUsed);
                this._trackingService.trackWithdraw(err, amount);
            });
    }
    /**
     * Based on the Withdraw Response, redirect to the relevant success or error screen
     * @param response
     * @param transactionAmount
     * @param accountUsed
     */
    public redirectToResponsePage(
        response: WithdrawResponse | CashierServiceError,
        transactionAmount: number = null,
        accountUsed: CoinAccount = null
    ): void {
        const isError = CashierApiService.isErrorResponse(response);
        this._router.navigate(
            [
                `/${paths.BASE}/${paths.WITHDRAW}/${paths.RESPONSE}/${
                    isError ? "error" : "success"
                }`
            ],
            {
                skipLocationChange: true,
                state: {
                    isCashierServiceTransaction: true,
                    cashierServiceResponse: response,
                    transactionAmount: transactionAmount,
                    accountUsed: accountUsed
                }
            }
        );
    }
}
