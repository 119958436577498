<sbmedia-response-type size="medium" type="success"></sbmedia-response-type>
<div class="mt-3 d-flex align-items-center flex-column">
    <div class="text-center">
        <h6 class="text-success">
            {{ transactionAmount | playerCurrency }} Deposited Successfully
        </h6>
    </div>
    <div id="deposit-summary" class="w-100 px-2 text-primary">
        <div class="container my-3">
            <ng-container *ngFor="let item of successData">
                <div class="row border-top" *ngIf="item.value !== null">
                    <div style="width: auto !important;" class="label flex-grow-1">{{ item.text }}</div>
                    <div style="width: auto !important;" class="value">{{ item.value }}</div>
                </div>
            </ng-container>
        </div>
    </div>

    <app-cashier-button class="w-100" appCloseCashier
        >Play Now</app-cashier-button
    >
</div>
