<div class="mt-4">
    <h6 class="font-family-bold">Calculating Net Deposit Limit</h6>
    <hr />

    <p class="font-family-bold subheading">How do we calculate your limit?</p>
    <p>
        We calculate your net deposit limit as the difference between your
        deposits and withdrawals.
    </p>

    <p class="font-family-bold subheading">How does it work?</p>

    <p>
        For example, if you deposit £20 and then later withdraw £15, your Net
        Deposit Amount would be £5.
    </p>

    <p class="font-family-bold subheading">When do limits reset?</p>

    <p>
        Daily limit applies to 24-hour period, weekly is based on a rolling 7
        days and monthly, by a rolling 30 day period rather than a calendar
        month.
    </p>

    <p>
        During these rolling time periods, if you reach your limit you’ll either
        need to withdraw some funds or wait for your limits to reset in order to
        deposit again.
    </p>

    <p>
        In some instances where we deem it necessary to limit your spending as
        part of our Responsible Gaming protocols, we will apply a Global Limit
        to your account.
    </p>

    <p>
        This Global Limit will be applied across any linked accounts you might
        hold with our partner sites:<br />
        {{ domainNames.join(", ") }}
    </p>

    <p>
        This means if you reach your limit on one site or as an accumulation
        across multiple sites, you will not be able to deposit and play on any
        other sites until your monthly rolling limit resets.
    </p>
</div>
