<div
    [ngClass]="{
        'has-icon': iconContainer.children.length !== 0,
        'is-invalid': invalid
    }"
    class="position-relative d-flex"
>
    <span
        class="icon font-family-bold position-absolute"
        #iconContainer
        [hidden]="iconContainer.children.length === 0"
    >
        <ng-content select="[slot=icon]"></ng-content>
    </span>
    <input
        #input
        class="w-100 py-2 pr-3"
        [ngClass]="{
            'pl-3': iconContainer.children.length === 0,
            'border-danger': invalid
        }"
        type="{{ type }}"
        [placeholder]="!!placeholder ? placeholder : ''"
        [disabled]="disabled"
        (keyup)="writeValue(input.value)"
        [value]="value"
    />
</div>
<div class="invalid-feedback">
    <div class="d-flex align-items-start">
        <span
            class="icon error"
            cashierIcon="warning"
            [hidden]="customErrorSlot.children.length === 0"
        ></span>
        <span #customErrorSlot>
            <ng-content select="[slot=validation-error-message]"></ng-content>
        </span>
    </div>
</div>
