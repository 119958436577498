<app-cashier-layout headerYMargin="small">
    <app-info [title]="title" [description]="description">
        <div slot="recoveryOptions">
            <app-cashier-button class="my-3" appChatWithUs
                >Chat with us</app-cashier-button
            >
            <app-cashier-button
                class="my-3"
                theme="primary-outline"
                [routerLink]="[withdrawURL]"
                >Withdraw</app-cashier-button
            >
        </div>
    </app-info>
</app-cashier-layout>
