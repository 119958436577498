import { Component } from "@angular/core";
import { paths } from "app/paths";

@Component({
    selector: "app-deposit-blocked",
    templateUrl: "./deposit-blocked.component.html"
})
export class DepositBlockedComponent {
    public title = "Deposits Disabled";
    public description = "We have disabled deposits for your account";
    public withdrawURL = `/${paths.BASE}/${paths.WITHDRAW}`;
}
