<app-cashier-layout headerYMargin="small">
    <div *ngIf="!displayGlobalLimitScreen">
        <app-payment-method-dropdown
            slot="header"
        ></app-payment-method-dropdown>

        <app-paypal-toggle-fastpay></app-paypal-toggle-fastpay>

        <app-promo-form
            *ngIf="promosEnabled || bonusesEnabled"
        ></app-promo-form>

        <app-deposit-form
            #form
            [enableSubmitButton]="!currentSelectedIsCard || !form.valid"
        ></app-deposit-form>

        <section class="my-3">
            <app-cvv-form
                [depositAmount]="form.amount"
                [visible]="currentSelectedIsCard && form.valid"
            >
            </app-cvv-form>
        </section>
    </div>

    <div *ngIf="displayGlobalLimitScreen">
        <deposit-global-limit-reached
            [limitsResponse]="depositLimitsResponse"
        ></deposit-global-limit-reached>
    </div>
</app-cashier-layout>
