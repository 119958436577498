import { NgModule } from "@angular/core";
import { DepositComponent } from "./deposit.component";
import { SharedModule } from "app/modules/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { DepositGlobalLimitReachedComponent } from "../deposit-global-limit-reached/deposit-global-limit-reached.component";

@NgModule({
    declarations: [DepositComponent, DepositGlobalLimitReachedComponent],
    imports: [BrowserModule, ReactiveFormsModule, SharedModule]
})
export class DepositModule {}
