<sbmedia-response-type
    [size]="'medium'"
    [type]="'information'"
></sbmedia-response-type>
<div class="mt-3 d-flex align-items-center flex-column px-2">
    <div class="text-center">
        <h5 class="text-info title">{{ title }}</h5>
    </div>
</div>
<p class="description" [innerHTML]="description"></p>

<section>
    <p *ngIf="optionsContent.children.length !== 0 && optionsTitleEnabled">
        Here's what you can still do:
    </p>
    <div #optionsContent>
        <ng-content select="[slot=recoveryOptions]"></ng-content>
    </div>
</section>
