import { Component, Input, OnInit } from "@angular/core";
import { WithdrawResponse } from "app/interfaces/withdraw.interface";
import { WithdrawService } from "app/services/withdraw/withdraw.service";

@Component({
    selector: "app-withdrawal-allocation",
    templateUrl: "./withdrawal-allocation.component.html",
    styleUrls: ["./withdrawal-allocation.component.scss"]
})
export class WithdrawalAllocationComponent implements OnInit {
    @Input() response: WithdrawResponse;
    public showTransactionReference = false;
    public transactionReference: string;
    public showAlternateReference = false;

    constructor(private readonly withdrawService: WithdrawService) {}

    ngOnInit(): void {
        this.transactionReference = this.withdrawService.getDescriptor(
            this.response
        );
        this.showTransactionReference = this.transactionReference !== null;
        if (
            this.showTransactionReference &&
            this.transactionReference !== "Rank Interactive"
        ) {
            this.showAlternateReference = true;
        }
    }
}
