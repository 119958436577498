import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CardHolderValidator {
    public static consecutiveNumberCheck(
        control: AbstractControl
    ): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        /**
         * Remove all spaces from the string and then test
         */
        const spaceLessValue = control.value.replace(/\s/gm, "");
        if (!spaceLessValue.length) {
            return null;
        }

        const hasConsecutiveNumbers = /\d{3,}/.test(spaceLessValue);

        if (!hasConsecutiveNumbers) {
            return null;
        }

        return {
            consecutiveNumbers: {
                message: "Cannot have more than 2 consecutive numbers"
            }
        };
    }
}
