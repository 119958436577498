import { AbstractControl, ValidationErrors } from "@angular/forms";

/**
 * Custom Validator to ensure a number matches the specified format
 * 1. Cannot start with a 0
 * 2. If it has a decimal point, it needs to be proceeded by 2 digits only
 * @param control
 */
export function promoCodeValidator(
    control: AbstractControl
): ValidationErrors | null {
    if (!control || !control.value || !control.value.toString().trim()) {
        return null;
    }

    /**
     * Code must:
     * 1. contain only alphanumeric characters
     * 2. contain no other characters
     * 3. have a minimum length of 3 characters
     */
    const isValid = control.value.toString().match(/^\w{3,}$/) !== null;

    return isValid
        ? null
        : {
              pattern: true
          };
}
