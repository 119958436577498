import { Injectable } from "@angular/core";
import { DecodedJWT } from "app/interfaces/jwt";
import { CookieService } from "ngx-cookie-service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ErrorService } from "app/services/error/error.service";
import { CurrencyISOCode } from "app/enums/currency-iso-code";
import { DeviceDetectorService } from "ngx-device-detector";
import { InitData } from "app/interfaces/init-data.interface";
import { APP_COOKIE_KEYS } from "app/app.settings";
import { DepositResponse } from "app/interfaces/deposit.interface";

/**
 * JWT helper
 * @type {JwtHelperService}
 */
const jwtService = new JwtHelperService();

@Injectable({
    providedIn: "root"
})
export class PlayerSessionService {
    private _token: string;
    private decodedToken: DecodedJWT;

    public bedeID: string;
    public claimCode: string;
    public currencyCode: CurrencyISOCode;
    public currencySign: "€" | "£";
    public customerID;
    public depositCount: number;
    public isWebView: boolean;
    public initStatus: InitData;
    public redirectDepositFeedback: any;
    public sessionID: string;
    public skinID: number;
    public playerID: number;
    public ukgcAck = false;
    public username: string;

    /**
     * Detects the device used and returns the appropriate value acceptable by COIN API
     * @link https://daubltd.atlassian.net/wiki/spaces/MAN/pages/1099202561/Coin+-+API
     *
     * Defaults to 'Web'
     * @private
     */
    private _device;
    public get device(): "Web" | "Tablet" | "Phone" {
        if (this._device) {
            return this._device;
        }
        const deviceInfo = this._deviceService.getDeviceInfo();
        let result;
        switch (deviceInfo.deviceType) {
            case "mobile":
                result = "Phone";
                break;
            case "tablet":
                result = "Tablet";
                break;
            default:
                result = "Web";
        }

        return (this._device = result);
    }

    constructor(
        private readonly _cookieService: CookieService,
        private readonly _deviceService: DeviceDetectorService,
        private readonly errorService: ErrorService
    ) {}

    /**
     * fetches the token from either launch URL or cookies
     * @private
     */
    public get token(): string {
        if (this._token) {
            return this._token;
        }

        const currentURL = new URL(document.location.href);
        const tokenInURL = currentURL.searchParams.get("cashierToken");
        if (tokenInURL) {
            return (this._token = tokenInURL);
        }

        return (this._token = this._cookieService.get(
            APP_COOKIE_KEYS.AUTH_TOKEN
        ));
    }

    public initialize(): void {
        let token;
        try {
            token = this.token;
            this._cookieService.set(
                APP_COOKIE_KEYS.AUTH_TOKEN,
                token,
                null,
                "/",
                null,
                true,
                "None"
            );
            this.decodedToken = jwtService.decodeToken(token);
            this.bedeID = this.decodedToken.BEDEPlayerId;
            this.currencyCode = this.decodedToken.CurrencyCode;
            this.playerID = this.decodedToken.PlayerID;
            this.sessionID = this.decodedToken.SessionID;
            this.skinID = this.decodedToken.SkinID;
            // not sure which is the right one?!
            this.username =
                this.decodedToken.user_name || this.decodedToken.Username;
        } catch {
            this.errorService.catch({
                errorTitle: "Failed to Initialize Player Session",
                token: token
            });
        }
    }

    public get isExpired(): boolean {
        return jwtService.isTokenExpired(this.token);
    }

    /**
     * Try to get the TxDepositId from the Response, (this will later be sent as COINREF).
     * Otherwise use the value stored in the cookies
     * Clear out the COIN Ref cookie value once fetched since we don't care and will not use it in future
     * @param depositResponse
     * @private
     */
    public getTxDepositID(depositResponse: DepositResponse): string {
        const value =
            depositResponse?.txDepositId?.toString() ||
            this._cookieService.get(APP_COOKIE_KEYS.COIN_REF);
        return value;
    }
}
