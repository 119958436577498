import { Component } from "@angular/core";
import { ResponseHandlerComponent } from "app/components/response-handler/response-handler.component";
import { paths } from "app/paths";

@Component({
    selector: "app-deposit-limits-response",
    templateUrl: "./deposit-limits-response.component.html"
})
export class DepositLimitsResponseComponent extends ResponseHandlerComponent {
    public returnURL = `/${paths.BASE}/${paths.DEPOSIT_LIMITS}`;
}
