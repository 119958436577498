<div class="px-2">
    <div class="section-title font-family-bold mb-2">
        You are about to withdraw {{ transactionAmount | playerCurrency }}
    </div>

    <ng-container *ngIf="showWithdrawPreAllocation">
        <p>which will be paid into:</p>

        <p>
            Mastercard Debit (x9955) - £1,000.00
            <br />
            <i class="text-danger"
                >This is hardcoded... awaiting Backend work</i
            >
        </p>
    </ng-container>

    <div class="container-fluid">
        <div class="row no-gutters" *ngIf="hasBonus">
            <div class="col-auto mr-2">
                <input
                    type="checkbox"
                    id="confirmation"
                    #cbCheckbox
                    (change)="disableCashierButton = !cbCheckbox.checked"
                />
            </div>
            <div class="col" *ngIf="isEPISkin; else rad2Text">
                <label for="confirmation" class="text-dark cta">
                    <small>
                        I understand that if I continue to withdraw I will lose
                        any bonus I have active in my account.
                    </small>
                </label>
            </div>
            <ng-template #rad2Text>
                <div class="col">
                    <label for="confirmation" class="text-dark cta">
                        <small>
                            I understand that if I continue to withdraw I will
                            lose any active bonuses or prize wheels I have in my
                            account.
                        </small>
                    </label>
                </div>
            </ng-template>
        </div>
    </div>
    <app-info-panel>
        <p>
            I understand that depending on my payment method, my withdrawal can
            take up to 3 working days to arrive and that it cannot be cancelled
        </p>
    </app-info-panel>

    <app-cashier-button
        class="w-100 mt-3 mb-4"
        #btnSubmit
        [disabled]="disableCashierButton"
        (click)="confirmWithdraw()"
    >
        Withdraw
    </app-cashier-button>

    <a
        [routerLink]="withdrawURL"
        class="mb-2 d-block text-center text-muted font-family-bold"
        >Cancel</a
    >
</div>
