import { Component, Injector, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { TrackingService } from "app/services/tracking/tracking.service";
import { MaskService } from "app/services/mask/mask.service";
import { LoggerService } from "app/services/logger/logger.service";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { CashierService } from "app/services/cashier/cashier.service";
import { CashierApiService } from "app/services/cashier-api.service";

@Component({
    selector: "app-response-handler",
    template: "app-response-handler works"
})
export class ResponseHandlerComponent implements OnInit {
    public returnURL: string;
    public coinResponse: any;
    public cashierServiceResponse: any;
    public safeChargeResponse: any;
    public accountUsed: CoinAccount;
    public transactionAmount: number;
    public isError: boolean;
    public isCashierServiceRequest: boolean;

    private _hasResponseData: boolean;

    constructor(
        protected _injector: Injector,
        public readonly loggerService: LoggerService,
        private readonly maskService: MaskService,
        private readonly _playerSessionService: PlayerSessionService,
        protected _trackingService: TrackingService,
        protected _router: Router
    ) {
        this.maskService.hide();
        if (this.hasResponseData()) {
            if (this.isCashierServiceRequest) {
                this.errorCheckCashierService();
            } else {
                this.errorCheckCoinResponse();
            }
        }
    }

    private errorCheckCashierService(): void {
        this.isError = CashierApiService.isErrorResponse(
            this.cashierServiceResponse,
            this.safeChargeResponse
        );
        this.loggerService.info("Response Handler Component", {
            isError: this.isError,
            cashierServiceResponse: this.cashierServiceResponse,
            safeChargeResponse: this.safeChargeResponse
        });
    }

    private errorCheckCoinResponse(): void {
        this.isError = CashierService.isErrorResponse(
            this.coinResponse,
            this.safeChargeResponse
        );
        this.loggerService.info("Response Handler Component", {
            isError: this.isError,
            coinResponse: this.coinResponse,
            safeChargeResponse: this.safeChargeResponse
        });
    }

    public ngOnInit(): void {
        if (!this.hasResponseData()) {
            this.backToTransactionOrigin();
        }
    }

    /**
     * Called if no response is received via the router
     * @see hasResponseData
     * @private
     */
    private backToTransactionOrigin(): void {
        if (!this.returnURL) {
            return;
        }
        this._router.navigate([this.returnURL]);
    }

    /**
     * Checks if the response is present in the router data
     * although other data is used, the primary data needed is the coin\transaction response
     * @private
     */
    private hasResponseData(): boolean {
        if (this._hasResponseData) {
            return this._hasResponseData;
        }
        const currentNavigation = this._router.getCurrentNavigation();
        this.coinResponse =
            currentNavigation?.extras?.state?.transactionResponse;

        /**
         * I'd prefer to wait for the rest of the coin to be deprecated before removing these params as they will still be used.
         * For now I'll add this flag
         */
        this.isCashierServiceRequest =
            currentNavigation?.extras?.state?.isCashierServiceTransaction;
        this.cashierServiceResponse =
            currentNavigation?.extras?.state?.cashierServiceResponse;

        this.safeChargeResponse =
            currentNavigation?.extras?.state?.safeChargeResponse;
        this.accountUsed = currentNavigation?.extras?.state?.accountUsed;
        this.transactionAmount =
            currentNavigation?.extras?.state?.transactionAmount;

        /**
         * This value is set when an APM redirects to the skin
         * and the skin opens up the cashier with the redirect deposit feedback response
         */
        const redirectDepositFeedBack =
            this._playerSessionService.redirectDepositFeedback;
        if (redirectDepositFeedBack) {
            this.loggerService.info("Redirect Deposit Feedback", {
                ...redirectDepositFeedBack,
                ...{ cashierToken: "redacted" }
            });
            this.cashierServiceResponse = redirectDepositFeedBack;
            this.isCashierServiceRequest = true;
            this.transactionAmount = redirectDepositFeedBack?.Amount;
            this._trackingService.trackDeposits(
                this.cashierServiceResponse,
                this.transactionAmount
            );
            this._playerSessionService.redirectDepositFeedback = undefined;
        }

        return (this._hasResponseData =
            this.coinResponse ||
            this.safeChargeResponse ||
            this.cashierServiceResponse);
    }
}
