import { Component, ViewChild } from "@angular/core";
import { DepositFormComponent } from "app/components/deposit-form/deposit-form.component";
import { DepositLimitsResponse } from "app/interfaces/deposit-limits.interface";
import { CashierApiService } from "app/services/cashier-api.service";
import { CashierService } from "app/services/cashier/cashier.service";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";

@Component({
    selector: "app-deposit",
    templateUrl: "./deposit.component.html"
})
export class DepositComponent {
    @ViewChild("form") form: DepositFormComponent;

    public displayGlobalLimitScreen: boolean;
    private depositLimitsResponse: DepositLimitsResponse;

    public readonly promosEnabled: boolean =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.DEPOSIT_PROMOS
        );
    public readonly bonusesEnabled: boolean =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.DEPOSIT_BONUSES
        );

    get currentSelectedIsCard(): boolean {
        return this.paymentMethodsService.currentSelectedIsCard;
    }

    constructor(
        private featureToggleService: FeatureToggleService,
        private paymentMethodsService: PaymentMethodsService,
        private cashierAPIService: CashierApiService,
        private _cashierService: CashierService
    ) {}

    public get hasAvalableBonuses(): boolean {
        return this._cashierService ||
            this.paymentMethodsService.currentSelected?.promos > []
            ? true
            : false;
    }

    ngOnInit(): void {
        this.getDepositLimitGlobalData();
    }

    private getDepositLimitGlobalData(): void {
        this.cashierAPIService
            .getDepositLimits()
            .toPromise()
            .then((getDepositLimitResponse: DepositLimitsResponse) => {
                this.depositLimitsResponse = getDepositLimitResponse;
                if (
                    this.depositLimitsResponse.totalDepositsMonthGlobal >=
                    this.depositLimitsResponse.depositLimitGlobal
                ) {
                    this.displayGlobalLimitScreen = true;
                }
            });
    }
}
