import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgIfContext } from "@angular/common";

@Component({
    selector: "app-form-dropdown",
    templateUrl: "./form-dropdown.component.html",
    styleUrls: ["./form-dropdown.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormDropdownComponent),
            multi: true
        }
    ]
})
export class FormDropdownComponent<T> implements ControlValueAccessor {
    @Input() value: T;
    @Input() options: T[];
    @Input() disabled = false;
    @Input() optionTemplate: TemplateRef<NgIfContext>;
    @Input() footerTemplate: TemplateRef<NgIfContext>;
    @Output() change = new EventEmitter<T>();
    public selectedOption: T;
    public collapsed = true;

    public get isOpened(): boolean {
        return !this.collapsed;
    }

    @ViewChild("dropdown") dropdown: ElementRef<HTMLDivElement>;

    private collapseCssClass = "collapse";
    private onTouched: any = () => {};
    private onChange: any = () => {};

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(incomingValue: T): void {
        if (incomingValue === null) {
            return;
        }
        this.value = incomingValue;
        this.onChange(this.value);
        this.change.emit(this.value);
    }

    public toggle(): void {
        this.collapsed = this.dropdown.nativeElement.classList.toggle(
            this.collapseCssClass
        );
    }

    /**
     * Selects an option by index
     * @param index
     * @private
     */
    public select(index = 0): T {
        const selectedOption = this.options[index];
        this.writeValue(selectedOption);
        return (this.selectedOption = selectedOption);
    }
}
