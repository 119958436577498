import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { APP_ERROR_MESSAGES } from "app/app.settings";

@Component({
    selector: "app-error",
    templateUrl: "./error.component.html"
})
export class ErrorComponent {
    public title = "Error";
    public description = APP_ERROR_MESSAGES.GENERIC;
    private transactionResponse;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router
    ) {
        this.getRouteData();
        this._activatedRoute.url.subscribe(() => {
            this.title =
                this._activatedRoute.snapshot.data?.errorTitle || this.title;
            this.description =
                this._activatedRoute.snapshot.data?.description ??
                this.description;
        });
    }

    private getRouteData(): void {
        const currentNavigation = this._router.getCurrentNavigation();
        this.transactionResponse =
            currentNavigation?.extras?.state?.transactionResponse;
        this.description =
            this.transactionResponse?.message ??
            this.transactionResponse?.StatusMsg ??
            this.description;
        this.title = this.transactionResponse?.errorTitle
            ? this.transactionResponse?.errorTitle
            : this.title;
    }
}
