import { NgModule } from "@angular/core";
import { SharedModule } from "app/modules/shared.module";
import { BrowserModule } from "@angular/platform-browser";
import { MyAccountsComponent } from "./my-accounts.component";

@NgModule({
    declarations: [MyAccountsComponent],
    imports: [BrowserModule, SharedModule]
})
export class MyAccountsModule {}
