import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-withdraw-response-error",
    templateUrl: "./withdraw-response-error.component.html",
    styleUrls: ["./withdraw-response-error.component.scss"]
})
export class WithdrawResponseErrorComponent implements OnInit {
    @Input() transactionResponse: any;
    @Input() transactionAmount: number;
    public systemMessage: string;

    ngOnInit(): void {
        this.systemMessage =
            this.transactionResponse?.message ??
            this.transactionResponse?.StatusMsg;
    }
}
