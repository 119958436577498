import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output
} from "@angular/core";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { ExpireStatus } from "app/enums/expire-status.enum";
import { AccountTypeCode } from "app/enums/account-type-code.enum";

@Component({
    selector: "app-card",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnInit {
    @HostBinding("class") class =
        "card p-3 position-relative d-block rounded-sm";
    @Input() account: CoinAccount;
    @Input() showDelete = false;
    @Input() showCTA = true;
    @Output() onCTAClick = new EventEmitter<void>();

    public isExpired: boolean;
    public status: "busy" | "error" = null;

    constructor(public elementRef: ElementRef) {}

    ngOnInit(): void {
        this.isExpired =
            this.account.expireStatus == ExpireStatus.Expired &&
            this.account.accountTypeCode != AccountTypeCode.ApplePay;
        this.class += this.isExpired ? " expired border-danger" : " bg-white";
    }

    public triggerCTA(): void {
        this.status = "busy";
        this.onCTAClick.emit();
    }

    public fadeOut(): void {
        this.elementRef.nativeElement.addEventListener("animationend", () => {
            this.elementRef.nativeElement.parentNode.removeChild(
                this.elementRef.nativeElement
            );
        });
        this.elementRef.nativeElement.classList.add("fadeOut");
    }
}
