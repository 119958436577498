import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class MaskService {
    public visibility$ = new BehaviorSubject<boolean>(false);

    public async show(): Promise<void> {
        this.visibility$.next(true);
    }

    public async hide(): Promise<void> {
        this.visibility$.next(false);
    }
}
