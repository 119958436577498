import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { CoinStatus } from "app/enums/coin-status.enum";
import { CoinResponse } from "app/interfaces/coin-response";
import { ErrorService } from "app/services/error/error.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (!(event instanceof HttpResponse)) {
                    return event;
                }

                if (event.status !== 200 || event.body === null) {
                    return event;
                }

                /**
                 * This is unnecessary for now
                 * should use this to reliably check on the user session
                 */
                if (event.body?.code === CoinStatus.SessionExpired) {
                    this.goToErrorScreen(event.body);
                    return;
                }

                /**
                 * If the get player function errors
                 * take the user to the error screen
                 */
                if (event.body?.code) {
                    this.goToErrorScreen(event.body);
                    return;
                }
                return event;
            })
        );
    }

    private goToErrorScreen(resp: CoinResponse) {
        const errorService = this.injector.get(ErrorService);
        errorService.catch(resp);
    }
}
