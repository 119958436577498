import { Component } from "@angular/core";
import { AccountTypeCode } from "app/enums/account-type-code.enum";
import { paths } from "app/paths";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import {
    AccountType,
    GetAccountTypesResponse
} from "app/interfaces/account-types";
import { tap } from "rxjs/operators";
import { PlayerSessionService } from "app/services/player-session/player-session.service";

interface AddAccountOption {
    name: string;
    accountTypeCode: AccountTypeCode;
    logos: AccountTypeCode[];
    route: string;
    enabled: boolean;
    accountData: AccountType;
}

@Component({
    selector: "app-add-account-options",
    templateUrl: "./add-account-options.component.html"
})
export class AddAccountOptionsComponent {
    public readonly accountTypes$: Observable<GetAccountTypesResponse>;
    public options: AddAccountOption[] = [
        {
            name: "applepay",
            accountTypeCode: AccountTypeCode.ApplePay,
            logos: [AccountTypeCode.ApplePay],
            route: `/${paths.BASE}/${paths.ADD_ACCOUNT}/applepay`,
            accountData: null,
            enabled: false
        },
        {
            name: "debit",
            accountTypeCode: AccountTypeCode.VisaDebit,
            logos: [AccountTypeCode.VisaDebit, AccountTypeCode.MasterCardDebit],
            route: `/${paths.BASE}/${paths.ADD_ACCOUNT}/card`,
            accountData: null,
            enabled: false
        },
        {
            name: "paypal",
            accountTypeCode: AccountTypeCode.PayPal,
            logos: [AccountTypeCode.PayPal],
            route: `/${paths.BASE}/${paths.ADD_ACCOUNT}/paypal`,
            accountData: null,
            enabled: false
        },
        {
            name: "paysafe",
            accountTypeCode: AccountTypeCode.PaySafe,
            logos: [AccountTypeCode.PaySafe],
            route: `/${paths.BASE}/${paths.ADD_ACCOUNT}/paysafe`,
            accountData: null,
            enabled: false
        }
    ];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private paymentMethodsService: PaymentMethodsService,
        private playerSessionService: PlayerSessionService
    ) {
        this.accountTypes$ = this.paymentMethodsService
            .availableNewOptions()
            .pipe(
                tap((resp) => {
                    this.verifyEnabled(resp);
                    this.checkIntent(resp);
                })
            );
    }

    /**
     * By default, all options are disabled
     * If an option appears in the GetAccountTypes response
     * ...enable it
     * @param resp
     * @private
     */
    private verifyEnabled(resp: GetAccountTypesResponse): void {
        resp.accountTypes.forEach((respItem) => {
            const optionFound = this.getOptionByAccCode(
                respItem.accountTypeCode
            );
            if (!optionFound) {
                return;
            }
            if (
                optionFound.accountTypeCode == AccountTypeCode.ApplePay &&
                !this.paymentMethodsService.hasApplePay
            ) {
                return;
            }
            if (
                optionFound.accountTypeCode == AccountTypeCode.PaySafe &&
                this.playerSessionService.skinID == 16 &&
                this.playerSessionService.depositCount < 1
            ) {
                return;
            }
            optionFound.accountData = respItem;
            optionFound.enabled = true;
        });
    }

    /**
     * Checks the "journey" so to speak
     * Intent is used to determine why is the player
     * trying to add a payment method
     *
     * If the intent is to withdraw,
     * then we do no show non-refundable methods
     * @param resp
     * @private
     */
    private checkIntent(resp: GetAccountTypesResponse): void {
        const intent =
            this._activatedRoute.snapshot.queryParamMap.get("intent");
        if (intent !== "withdraw") {
            return;
        }

        resp.accountTypes.forEach((i) => {
            if (i.refundable) {
                return;
            }

            const optionFound = this.getOptionByAccCode(i.accountTypeCode);
            if (!optionFound) {
                return;
            }

            optionFound.enabled = false;
        });
    }

    private getOptionByAccCode(code: AccountTypeCode): AddAccountOption {
        return this.options.find(
            (feOption) => feOption.accountTypeCode === code
        );
    }
}
