import { Injectable } from "@angular/core";
import { SkinService } from "app/services/skin/skin.service";
import {
    CoreUiTrackingService,
    RequestTrackingInterface
} from "@sbmedia/tracking";
import { TrackingEventService } from "app/services/tracking-event/tracking-event.service";
import { WithdrawResponse } from "app/interfaces/withdraw.interface";
import { VfBingoService } from "app/services/vf-bingo/vf-bingo.service";
import { DepositLimitChangeRequest } from "app/interfaces/deposit-limits.interface";
import { DepositResponse } from "app/interfaces/deposit.interface";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { CashierApiService, CashierServiceError } from "../cashier-api.service";
import { DepositResponseStatus } from "app/enums/DepositResponseStatus.enum";

@Injectable({
    providedIn: "root"
})
export class TrackingService {
    private readonly affiliatesId: string | undefined;
    private readonly netrefererId: string | undefined;
    private readonly gclid: string | undefined;

    constructor(
        private _coreUiTrackingService: CoreUiTrackingService,
        private _playerSessionService: PlayerSessionService,
        private _skinService: SkinService,
        private _trackingEventService: TrackingEventService,
        private cashierAPIService: CashierApiService,
        private _vfBingoService: VfBingoService
    ) {
        this.affiliatesId =
            this.cashierAPIService.playerData?.affiliateId?.toString();
        this.netrefererId =
            this.cashierAPIService.playerData?.netRefererAffiliateId?.toString();
        this.gclid = this.cashierAPIService.playerData?.gclId;
    }
    /**
     * Determine if the transaction is a successful one or not based on the API response
     * Prefer the Nuvei Response over the API Response
     *
     * @param depositResponseStatus
     * @param nuveiResponse
     * @private
     */
    private isSuccess(
        depositResponseStatus: DepositResponseStatus,
        nuveiResponse = null
    ): boolean {
        if (nuveiResponse && nuveiResponse.result) {
            return nuveiResponse.result === "APPROVED";
        }
        return depositResponseStatus == DepositResponseStatus.S_SUCCESS_SETTLED;
    }

    /**
     * Preps the tracking data for Tealium and Adobe Launch
     * @param depositResponse
     * @param transactionAmount
     * @param nuveiResponse
     * @private
     */
    private getTrackingEventData(
        depositResponse: any,
        transactionAmount: number = null,
        nuveiResponse: any = null
    ): RequestTrackingInterface {
        const amount = transactionAmount ?? depositResponse?.amount;
        const data = {
            amount: amount,
            status: this.isSuccess(depositResponse?.status, nuveiResponse)
                ? "success"
                : "failure",
            playerID: this._playerSessionService.playerID.toString(),
            gclid: this.gclid,
            affiliatesId: this.affiliatesId,
            netrefererId: this.netrefererId
        };

        // Clean out any undefined data
        Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
                delete data[key];
            }
        });

        return data;
    }

    private formatTrackingData(
        response: WithdrawResponse,
        transactionAmount: number = null,
        nuveiResponse: any = null
    ): RequestTrackingInterface {
        const amount = transactionAmount ?? response?.amount;
        const data = {
            amount: amount.toString(),
            status: CashierApiService.isErrorResponse(response, nuveiResponse)
                ? "success"
                : "failure",
            playerID: this._playerSessionService.playerID.toString(),
            gclid: this.gclid,
            affiliatesId: this.affiliatesId,
            netrefererId: this.netrefererId
        };

        // Clean out any undefined data
        Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
                delete data[key];
            }
        });

        return data;
    }

    /**
     * Tracks both successful and failed Withdraw Requests
     * @param response
     * @param transactionAmount this is needed since the Error Response does not contain the amount
     */
    public trackWithdraw(
        response: WithdrawResponse | CashierServiceError,
        transactionAmount: number
    ): void {
        const isErrorResponse = CashierApiService.isErrorResponse(response);

        if (!isErrorResponse && this._skinService.supportsVF) {
            this._vfBingoService.notifyWithdraw(
                (response as WithdrawResponse).txWithdrawId
            );
        }

        if (this._skinService.isStride) {
            this._coreUiTrackingService.trackCashierWithdrawal(
                this.getTrackingEventData(response)
            );
        } else {
            this.trackEPIWithdrawals(
                isErrorResponse,
                response,
                transactionAmount
            );
        }
    }

    /**
     * Tracks successful and failed Withdraw Requests from EPI brands
     * @param response
     * @param transactionAmount this is needed since the Error Response does not contain the amount
     */
    public trackEPIWithdrawals(
        isWithdrawalSuccess: boolean,
        response: WithdrawResponse | CashierServiceError,
        transactionAmount: number
    ): void {
        if (isWithdrawalSuccess) {
            this._trackingEventService.trackAmountWithdrawn(transactionAmount);
            return;
        }
        const errorMessage =
            "message" in response ? response.message : "Unknown API error";
        this._trackingEventService.trackAmountWithdrawnFailure(
            transactionAmount,
            errorMessage
        );
    }

    /**
     * Track both successful and failed deposit attempts
     * Called under the following scenarios:
     * 1. Nuvei Deposit Response has failed
     * 2. after DepositEnd
     * 3. after APM Deposit Responses
     *
     * There are instances where some of these parameters are not available
     * e.g.
     * 1. When the Nuvei Deposit Response has failed, there will not be a COIN Response
     *
     * @param coinResponse
     * @param transactionAmount
     * @param nuveiResponse
     */
    public trackDeposits(
        depositResponse: any = null,
        transactionAmount: number,
        nuveiResponse: any = null
    ): void {
        const standardDepositResponse =
            CashierApiService.standardiseDepositResponse(depositResponse);
        const isSuccess = this.isSuccess(
            standardDepositResponse?.status,
            nuveiResponse
        );

        if (isSuccess && this._skinService.supportsVF) {
            this._vfBingoService.vfDeposit(
                standardDepositResponse,
                transactionAmount
            );
        }

        if (this._skinService.isStride) {
            const trackingEventData = this.getTrackingEventData(
                standardDepositResponse,
                transactionAmount,
                nuveiResponse
            );
            if (
                (isSuccess &&
                    +(standardDepositResponse as DepositResponse)
                        ?.depositCount === 1) ||
                this._playerSessionService.depositCount === 0
            ) {
                this._coreUiTrackingService.trackCashierFirstDeposit(
                    trackingEventData
                );
            } else {
                this._coreUiTrackingService.trackCashierDeposit(
                    trackingEventData
                );
            }
        } else {
            if (isSuccess) {
                this._trackingEventService.trackDepositSuccess(
                    transactionAmount,
                    standardDepositResponse as DepositResponse
                );
            } else {
                const reason =
                    standardDepositResponse?.message ||
                    nuveiResponse?.errorDescription ||
                    standardDepositResponse?.StatusMsg;
                this._trackingEventService.trackDepositFailed(
                    transactionAmount,
                    reason
                );
            }
        }
    }

    /**
     * Track Change Requests to Deposit Limits
     * The following is NOT Tracked:
     * 1. RIDE Brands
     * 2. Confirming a pending limit change
     * 3. Cancelling a pending limit change
     * @param response
     * @param request
     */
    public trackDepositLimitsChange(
        response,
        request: DepositLimitChangeRequest
    ): void {
        if (this._skinService.isStride) {
            this._coreUiTrackingService.trackCashierSelectDepositLimit(
                request,
                this.getTrackingEventData(response)
            );
        }
    }
}
