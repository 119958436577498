import { Component, HostBinding } from "@angular/core";
import { MaskService } from "app/services/mask/mask.service";

@Component({
    selector: "app-mask",
    template: "",
    styleUrls: ["./mask.component.scss"]
})
export class MaskComponent {
    @HostBinding("hidden") isHidden: boolean;
    constructor(private readonly maskService: MaskService) {
        this.maskService.visibility$.subscribe((visibility) => {
            this.isHidden = !visibility;
        });
    }
}
