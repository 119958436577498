import { Component, Input } from "@angular/core";
import { PlayerService } from "app/services/player/player.service";
import { DepositLimit } from "app/interfaces/deposit-limits.interface";

@Component({
    selector: "app-amber-affordability-limit-validation",
    styleUrls: ["./amber-affordability-limit-validation.component.scss"],
    templateUrl: "./amber-affordability-limit-validation.component.html"
})
export class AmberAffordabilityLimitValidationComponent {
    @Input() limitPeriodData: DepositLimit;
    public readonly playerTUIsAmber = this.playerService.isAmber;
    constructor(private readonly playerService: PlayerService) {}
}
