import { NgModule } from "@angular/core";
import { AddAccountComponent } from "./add-account.component";
import { SharedModule } from "app/modules/shared.module";
import { AboutStoringCardDetailsComponent } from "./about-storing-card-details/about-storing-card-details.component";
import { AddAccountOptionsComponent } from "./add-account-options/add-account-options.component";
import { AddAccountDebitCardComponent } from "./add-account-debit-card/add-account-debit-card.component";
import { AddAccountEWalletComponent } from "./add-account-ewallet/add-account-ewallet.component";
import { ConfirmNonRefundableComponent } from "./confirm-non-refundable/confirm-non-refundable.component";

@NgModule({
    declarations: [
        ConfirmNonRefundableComponent,
        AddAccountComponent,
        AddAccountEWalletComponent,
        AboutStoringCardDetailsComponent,
        AddAccountOptionsComponent,
        AddAccountDebitCardComponent
    ],
    imports: [SharedModule]
})
export class AddAccountModule {}
