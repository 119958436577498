import { Injectable } from "@angular/core";
import { Skin, Skins } from "app/enums/skin-id.enum";
import { PlayerSessionService } from "app/services/player-session/player-session.service";

@Injectable({
    providedIn: "root"
})
export class SkinService {
    public static readonly domainNames: string[] = Skins.filter(
        (s) => s.enabled !== false
    ).map((s) => s.domainName);
    private static epiSkins: number[] = Skins.filter(
        (s) => s.isEPI === true
    ).map((s) => s.id);
    public readonly currentID: number = this._playerSessionService.skinID;
    public readonly current: Skin = Skins.find(
        (s) => s.id === this._playerSessionService.skinID
    );
    private vfSupportedSkins: number[] = Skins.filter(
        (s) => s.notifiesVF === true
    ).map((s) => s.id);

    private _isEPI: boolean;
    get isEPI(): boolean {
        /**
         * If it's already been set, use that value
         */
        if (this._isEPI !== undefined) {
            return this._isEPI;
        }
        return SkinService.epiSkins.includes(this.currentID);
    }

    get isStride(): boolean {
        return !this.isEPI;
    }

    get supportsVF(): boolean {
        return this.vfSupportedSkins.indexOf(this.currentID) !== -1;
    }

    constructor(private _playerSessionService: PlayerSessionService) {}
}
