<sbmedia-response-type
    [size]="'medium'"
    [type]="'success'"
></sbmedia-response-type>
<div class="mt-3 d-flex align-items-center flex-column">
    <div class="text-center">
        <ng-container *ngIf="hasAllocations !== undefined">
            <h6 class="text-success" [ngSwitch]="hasAllocations">
                <ng-container *ngSwitchCase="false"
                    >Request Received</ng-container
                >
                <ng-container *ngSwitchDefault
                    >Withdrawal Successful</ng-container
                >
            </h6>
        </ng-container>
        <p>
            Your withdrawal request for
            {{ transactionResponse.amount | playerCurrency }} was successful.
        </p>
    </div>
    <div class="container-fluid border rounded info-container mt-3">
        <div class="row no-gutters pb-1">
            <div class="col-auto p-2 mr-2">
                <span cashierIcon="transaction_ref"></span>
            </div>
            <div class="col-auto p-2 mr-2">
                <div class="row mt-2">
                    <p>
                        Withdrawal reference
                        {{ transactionResponse.txWithdrawId }}.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <app-withdrawal-allocation
        class="my-3"
        [response]="transactionResponse"
    ></app-withdrawal-allocation>
    <app-info-panel class="mb-3">
        <p>
            I understand that depending on my payment method, my withdrawal can
            take up to 3 working days to arrive and that it cannot be cancelled
        </p>
    </app-info-panel>
    <app-info-panel class="mb-3" *ngIf="hasBonuses">
        <p>All active bonus on your account may have been removed.</p>
    </app-info-panel>

    <app-cashier-button
        class="w-100"
        *ngIf="hasAllocations; else addPaymentMethodButton"
        appCloseCashier
        >Close</app-cashier-button
    >
</div>
<ng-template #addPaymentMethodButton>
    <app-cashier-button class="w-100" [routerLink]="[addAccountURL]"
        >Add new payment method</app-cashier-button
    >
</ng-template>
