import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-deposit-limits-response-error",
    templateUrl: "./deposit-limits-response-error.component.html"
})
export class DepositLimitsResponseErrorComponent implements OnInit {
    @Input() transactionResponse;
    public systemMessage: string;

    ngOnInit(): void {
        this.systemMessage = this.transactionResponse?.message;
    }
}
