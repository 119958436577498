import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { PlayerService } from "app/services/player/player.service";

@Component({
    selector: "app-insolvency-acknowledgement",
    templateUrl: "./insolvency-acknowledgement.component.html",
    styleUrls: ["./insolvency-acknowledgement.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InsolvencyAcknowledgementComponent),
            multi: true
        }
    ]
})
export class InsolvencyAcknowledgementComponent
    implements ControlValueAccessor
{
    @Input() value: number;
    public readonly requestUKGCAck = this.playerService.requestUKGC;
    public onChange: any = () => {};
    public onTouch: any = () => {};

    constructor(private playerService: PlayerService) {}

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    public writeValue(accepted: boolean): void {
        this.value = accepted ? 1 : 0;
        this.onChange(this.value);
        this.onTouch(this.value);
    }
}
