import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BootstrapComponent } from "./bootstrap.component";

@NgModule({
    declarations: [BootstrapComponent],
    imports: [AppRoutingModule],
    bootstrap: [BootstrapComponent]
})
export class AppModule {}
