import { Component, OnInit } from "@angular/core";
import { paths } from "app/paths";
import { ResponseHandlerComponent } from "app/components/response-handler/response-handler.component";
import { DepositResponseStatus } from "app/enums/DepositResponseStatus.enum";

@Component({
    selector: "app-deposit-response",
    templateUrl: "./deposit-response.component.html"
})
export class DepositResponseComponent
    extends ResponseHandlerComponent
    implements OnInit
{
    public returnURL = `/${paths.BASE}/${paths.DEPOSIT}`;
    public showPendingMessage = false;

    public ngOnInit(): void {
        this.showPendingMessage = this._showPendingMessage;
    }

    /**
     * This is an edge case where
     * 1. Nuvei has processed the deposit successfully
     * 2. Call to Deposit Finalise unexpectedly fails due to a DB error
     */
    private get _showPendingMessage(): boolean {
        /**
         * Nuvei has not processed the deposit successfully
         */
        if (
            this.safeChargeResponse &&
            this.safeChargeResponse.result !== "APPROVED"
        ) {
            return false;
        }

        /**
         * API has returned a successful response
         * ...bail
         */
        if (
            this.cashierServiceResponse &&
            this.cashierServiceResponse.status ==
                DepositResponseStatus.S_SUCCESS_SETTLED
        ) {
            return false;
        }

        let result = false;

        /**
         * API response has an empty body
         */
        if (
            this.cashierServiceResponse == null ||
            Object.keys(this.cashierServiceResponse).length === 0
        ) {
            result = true;
        }

        if (result === false) {
            return result;
        }

        this.loggerService.warn("Pending Deposit Screen Shown", {
            safeCharge: this.safeChargeResponse,
            cashierService: this.cashierServiceResponse
        });

        return true;
    }
}
