import { Directive, HostListener } from "@angular/core";
import { Location } from "@angular/common";

@Directive({
    selector: "[appButtonBack]"
})
export class GoBackDirective {
    constructor(private _location: Location) {}

    @HostListener("click") onClick(): void {
        this._location.back();
    }
}
