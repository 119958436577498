<div class="row">
    <div class="col">
        <app-info-panel icon="warning" class="d-flex my-2">
            <small
                >You may only withdraw after you have made a successful
                deposit.</small
            >
        </app-info-panel>
    </div>
</div>
<div class="row mt-2">
    <div class="col">
        <app-cashier-button [routerLink]="[depositPageURL]"
            >Deposit</app-cashier-button
        >
    </div>
</div>
