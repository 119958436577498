import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { paths } from "app/paths";
import { NotificationService } from "app/services/notification/notification.service";
import { WithdrawService } from "app/services/withdraw/withdraw.service";

@Component({
    selector: "app-withdraw-response-success",
    templateUrl: "./withdraw-response-success.component.html",
    styleUrls: ["./withdraw-response-success.component.scss"]
})
export class WithdrawResponseSuccessComponent implements OnInit, OnChanges {
    @Input() transactionResponse: any;
    public addAccountURL = `/${paths.BASE}/${paths.ADD_ACCOUNT}`;
    public hasAllocations;
    public hasBonuses: boolean;
    constructor(
        private readonly _withdrawService: WithdrawService,
        private _notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this._notificationService.sendWithdrawalNotification();
        this.hasBonuses = WithdrawService.hasForfeitedBonus();
    }

    ngOnChanges(): void {
        this.hasAllocations = this._withdrawService.hasAllocations(
            this.transactionResponse
        );
    }
}
