import { Injectable } from "@angular/core";
import { TUStatus } from "app/interfaces/start.interface";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { CashierApiService } from "../cashier-api.service";

@Injectable({
    providedIn: "root"
})
/**
 * Bridge between player specific data and cookies
 */
export class PlayerService {
    public readonly TUStatus: TUStatus =
        this.cashierAPIService.playerData?.transunionStatus ?? null;
    public readonly isAmber = this.TUStatus === TUStatus.AMBER;

    constructor(
        private cashierAPIService: CashierApiService,
        private _playerSessionService: PlayerSessionService
    ) {}

    get name(): string {
        return `${this.cashierAPIService.playerData.firstName} ${this.cashierAPIService.playerData.lastName}`;
    }

    get requestUKGC(): boolean {
        return this._playerSessionService.ukgcAck;
    }
}
