<ng-container *ngIf="!isHidden">
    <div id="svgContainer">
        <svg xmlns="http://www.w3.org/2000/svg" width="176" height="176">
            <circle
                id="progressCircleBackground"
                fill="transparent"
                stroke-width="20"
                cx="88"
                cy="88"
                r="78"
                stroke-dasharray="490.0884539600077"
                stroke-dashoffset="490.0884539600077"
            ></circle>
            <circle
                id="progressCircle"
                fill="transparent"
                stroke-width="20"
                cx="88"
                cy="88"
                r="78"
                stroke-dasharray="490.0884539600077"
                [attr.stroke-dashoffset]="strokeDashoffset"
            ></circle>
            <defs>
                <linearGradient id="greenRange" x1="0" x2="1" y1="0" y2="0">
                    <stop offset="0%" stop-color="#3dd228"></stop>
                    <stop offset="100%" stop-color="#49be54"></stop>
                </linearGradient>
                <linearGradient id="amberRange" x1="0" x2="1" y1="0" y2="0">
                    <stop offset="0%" stop-color="#ff9153"></stop>
                    <stop offset="100%" stop-color="#ffd143"></stop>
                </linearGradient>
                <linearGradient id="redRange" x1="0" x2="1" y1="0" y2="0">
                    <stop offset="0%" stop-color="#d64c7f"></stop>
                    <stop offset="100%" stop-color="#ee4758"></stop>
                </linearGradient>
            </defs>
        </svg>
        <div id="progressText">
            <div class="amountUsed">{{ usage$ | async | playerCurrency }}</div>
            <div class="usageDescriptor">
                used {{ usageDescriptor$ | async }}
            </div>
            <hr />
            <div class="amountValue">
                {{
                    (isGlobal
                        ? limitPeriodData.DepositLimitGlobal
                        : limitPeriodData.value
                    ) | playerCurrency
                }}
            </div>
            <div class="periodDescriptor">{{ periodDescriptor$ | async }}</div>
        </div>
        <span
            id="shieldIcon"
            cashierIcon="shield_pound_large"
            *ngIf="isGlobal"
        ></span>
    </div>
</ng-container>
