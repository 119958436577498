import { Component, Input } from "@angular/core";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { CoinAccount } from "app/interfaces/accounts.interface";

@Component({
    selector: "app-cvv-form",
    templateUrl: "./cvv-form.component.html"
})
export class CVVFormComponent {
    @Input() visible = false;
    @Input() depositAmount: number;

    constructor(private paymentMethodsService: PaymentMethodsService) {}

    public get selectedAccount(): CoinAccount {
        return this.paymentMethodsService.currentSelected;
    }
}
