import { Component, Input, NgZone, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CashierService } from "app/services/cashier/cashier.service";
import { REDIRECT_PARENT_EVENTS } from "app/app.settings";
import { mustBeTruthy } from "app/validators/must-be-truthy.validator";
import { DepositService } from "app/services/deposit/deposit.service";
import { Observable, Subscription, throwError } from "rxjs";
import { CashierButtonComponent } from "app/components/cashier-button/cashier-button.component";
import { AccountTypeCode } from "app/enums/account-type-code.enum";
import { SkinService } from "app/services/skin/skin.service";
import { matchMoneyPattern } from "app/validators/money-pattern.validator";
import { PlayerService } from "app/services/player/player.service";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { PromoService } from "app/services/promo/promo.service";
import { DepositWithBonusValidatorService } from "app/validators/deposit-with-bonus.validator";
import { DepositValidatorService } from "app/validators/deposit.validator";
import { PaypalService } from "app/services/paypal/paypal.service";
import { MaskService } from "app/services/mask/mask.service";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import {
    DepositResponse,
    DepositStartRequest
} from "app/interfaces/deposit.interface";
import { catchError, debounceTime, tap } from "rxjs/operators";
import { FormFieldComponent } from "../form-field/form-field.component";
import { LoggerService } from "app/services/logger/logger.service";
import { Skins } from "app/enums/skin-id.enum";
import { CashierApiService } from "app/services/cashier-api.service";
import { DepositResponseStatus } from "app/enums/DepositResponseStatus.enum";

@Component({
    selector: "app-deposit-form",
    templateUrl: "./deposit-form.component.html",
    styleUrls: ["./deposit-form.component.scss"]
})
export class DepositFormComponent implements OnDestroy {
    @ViewChild("submitButton") submitButton: CashierButtonComponent;
    @ViewChild("formfieldComponent") formfieldComponent: FormFieldComponent;
    @Input() enableSubmitButton = true;

    private depositResponse: DepositResponse;
    private depositStartData: DepositStartRequest;
    private initiatingSDK = false;
    private subscriptions = new Subscription();
    protected sfc;
    public showApplePayPrompt = false;

    /**
     * Checks if this component is valid or not
     */
    get valid(): boolean {
        return this.formGroup && this.formGroup.valid;
    }

    get amount(): number {
        return this.formGroup ? this.formGroup.controls.amt.value : 0;
    }

    get isAPMDeposit(): boolean {
        return [AccountTypeCode.PayPal, AccountTypeCode.PaySafe].includes(
            this.formGroup.value.acctType
        );
    }

    get currentSellectedApplePay(): boolean {
        if (!this.paymentMethodsService.currentSelected) {
            return false;
        }
        if (
            this.paymentMethodsService.currentSelected.accountTypeCode ==
            AccountTypeCode.ApplePay
        ) {
            return true;
        }
        return false;
    }

    public minAllowed: number;
    public maxAllowed: number = this._depositService.maxAllowed;
    public currency = this._playerSessionService.currencySign;

    /**
     * @see setupFormGroup
     */
    public formGroup: FormGroup;
    public statusChanges: Observable<"VALID" | "INVALID">;

    public initSubscription: Subscription;

    private readonly requestUKGCAck = this._playerService.requestUKGC;

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private _skinService: SkinService,
        private _formBuilder: FormBuilder,
        private _cashierService: CashierService,
        private readonly paypalService: PaypalService,
        private _playerService: PlayerService,
        private _promoService: PromoService,
        private _depositWithBonusValidator: DepositWithBonusValidatorService,
        private _depositValidator: DepositValidatorService,
        private readonly maskService: MaskService,
        private readonly _playerSessionService: PlayerSessionService,
        private readonly _depositService: DepositService,
        private loggerService: LoggerService,
        private cashierAPIService: CashierApiService,
        private ngzone: NgZone
    ) {
        this.setupFormGroup();
        this.listenForPromos();
        this.listenForSelectedAccount();
        this.listenForFastPayToggle();
    }

    /**
     * When this component is closed or
     * when a player is redirected away,
     * we want to clear the values of this form so that they're not persistent
     * when the player comes back to the deposit form
     */
    ngOnDestroy(): void {
        this.formGroup.reset();
        this.subscriptions.unsubscribe();
        if (this.initSubscription) {
            this.initSubscription.unsubscribe();
        }
    }

    /**
     * Initiates a Deposit Session with COIN
     * @emits Deposit init response from COIN
     */
    public initiateDeposit(): void {
        if (this.initSubscription) {
            this.initSubscription.unsubscribe();
        }

        if (this.formGroup.invalid) {
            return;
        }

        /**
         * When the selected account is a card
         * Skip the Initiate Deposit call
         * Nuvei Handles this
         */
        if (this.paymentMethodsService.currentSelectedIsCard) {
            return;
        }

        /**
         * Apple Pay payment initiation
         */
        if (
            this.paymentMethodsService.hasApplePay &&
            this.paymentMethodsService.currentSelected.accountTypeCode ==
                AccountTypeCode.ApplePay
        ) {
            this.initiateApplePayment();
            return;
        }

        this.maskService.show();
        this.initSubscription = this.cashierAPIService
            .initiateAPMDeposit(this.formGroup.value)
            .subscribe(
                (depositResponse) => {
                    this.handleAPMResponse(
                        depositResponse,
                        this.formGroup.value.acctType
                    );
                },
                (error) => {
                    this._depositService.redirectToResponsePage(
                        error,
                        this.formGroup.value.amt,
                        this.formGroup.value.acctID
                    );
                }
            );
    }

    public initiateApplePayment(): void {
        this.initiatingSDK = true;
        const skinName = Skins.find(
            (s) => s.id === this._playerSessionService.skinID
        ).withdrawalDescriptor;
        const depositAmount = this.formGroup.value.amt;
        const paymentDetails = {
            sessionToken: this.depositResponse.nuveiDepositDetails.sessionToken,
            merchantId:
                this.cashierAPIService.playerData.gateway.nuvei.merchantId,
            merchantSiteId:
                this.cashierAPIService.playerData.gateway.nuvei.merchantSiteId,
            env: this.cashierAPIService.playerData.gateway.nuvei.env,
            countryCode: "GB",
            currencyCode: this.cashierAPIService.playerData.currencyCode,
            total: {
                label: skinName,
                amount: depositAmount
            }
        };
        try {
            this.sfc.createApplePayPayment(paymentDetails, (sdkResponse) => {
                this.ngzone.run(() => {
                    if (sdkResponse.status == "canceled") {
                        this.initiatingSDK = false;
                        return;
                    }
                    if (sdkResponse.result !== "APPROVED") {
                        this._depositService.redirectToResponsePage(
                            null,
                            depositAmount,
                            sdkResponse
                        );
                    } else {
                        const depositEndRequest = {
                            gatewayEnc:
                                this.depositResponse.nuveiDepositDetails
                                    .gatewayEnc,
                            txDepositId: this.depositResponse.txDepositId,
                            last4Digits: sdkResponse.last4Digits,
                            userPaymentOptionId: sdkResponse.userPaymentOptionId
                        };
                        const depositEnd$ =
                            this.cashierAPIService.finaliseDeposit(
                                depositEndRequest
                            );
                        depositEnd$
                            .pipe(
                                catchError((error) => {
                                    this._depositService.redirectToResponsePage(
                                        error,
                                        depositAmount,
                                        sdkResponse
                                    );
                                    return throwError(error);
                                }),
                                tap((resp) => {
                                    this._depositService.redirectToResponsePage(
                                        resp,
                                        depositAmount,
                                        sdkResponse
                                    );
                                })
                            )
                            .subscribe();
                    }
                });
            });
        } catch (error) {
            this.loggerService.error("SafeCharge Error Response", error);
        }
    }

    private setupFormGroup(): void {
        this.formGroup = this._formBuilder.group({
            acctID: [null],
            acctType: [null, Validators.required],
            amt: [
                null,
                [
                    Validators.required,
                    this._depositValidator.validate(),
                    this._depositWithBonusValidator.validate(),
                    matchMoneyPattern
                ]
            ],
            promoID: [null],
            promoCode: [null],
            isOffer: [null],
            fastpay: [null],
            RISKCORRELATIONID: [null],
            ukgcAck: [
                this.requestUKGCAck ? 0 : 1,
                [Validators.required, mustBeTruthy]
            ]
        });

        this.statusChanges = this.formGroup.statusChanges;
    }

    /**
     * When the deposit response is for APM (paypal/paysafe),
     * redirect the client to the relevant url
     * @param response
     * @private
     * @return  true when the deposit request is for an APM
     *          false when the deposit request is not for an APM
     */
    private handleAPMResponse(
        response: DepositResponse,
        accountTypeCode: AccountTypeCode
    ): boolean {
        if (![DepositResponseStatus.S_INIT].includes(response.status)) {
            /**
             * In the event an APM Deposit already has a Deposit Response
             */
            this._depositService.redirectToResponsePage(
                response,
                this.formGroup.value.amt
            );
            return false;
        }
        this.submitButton.text = "Redirecting...";

        if (this._playerSessionService.isWebView) {
            window.location.href = response.redirectUrl;
        } else if (this._skinService.isEPI) {
            let redirectURL;
            let redirectEvent;

            if (accountTypeCode == AccountTypeCode.PaySafe) {
                redirectURL = response.redirectUrl;
                redirectEvent = REDIRECT_PARENT_EVENTS.APP_REDIRECT_PAYSAFE;
            } else {
                redirectURL = response.redirectUrl;
                redirectEvent = REDIRECT_PARENT_EVENTS.APP_REDIRECT_PAYPAL;
            }
            window.parent.postMessage(
                {
                    cashierEvent: redirectEvent,
                    url: redirectURL
                },
                "*"
            );
        } else {
            window.parent.location.href = response.redirectUrl;
        }

        return true;
    }

    /**
     * Since the promos value can change outside this form,
     * we've gotta listen to this event,
     * and update the form accordingly
     */
    private listenForPromos(): void {
        this.subscriptions.add(
            this._promoService.promoPayload$.subscribe((resp) => {
                this.formGroup.patchValue({
                    promoID: resp.id,
                    promoCode: resp.code,
                    isOffer: resp.isOffer
                });
                this.formGroup.controls.amt.updateValueAndValidity();
            })
        );
    }

    /**
     * This value is changed in the PayPal FastPay Component
     * @see PaypalToggleFastpayComponent
     * @private
     */
    private async listenForFastPayToggle(): Promise<void> {
        this.subscriptions.add(
            this.paypalService.fastPayEnabled$.subscribe((isEnabled) => {
                if (isEnabled) {
                    this.paypalService.loadFraudNet();
                }

                this.formGroup.patchValue({
                    fastpay: 1,
                    RISKCORRELATIONID: isEnabled
                        ? this.paypalService.fraudNetGUID
                        : null
                });
            })
        );
    }

    /**
     * The account can be changed from outside this component
     * So listen to these change events and act upon it
     * @private
     */
    private listenForSelectedAccount(): void {
        this.subscriptions.add(
            this.paymentMethodsService.selected$.subscribe((account) => {
                if (!account || !this.formGroup) {
                    return;
                }
                this.minAllowed = account.minDepositAmount;
                this.formGroup.patchValue({
                    acctID: account.accountId,
                    acctType: account.accountTypeCode
                });

                if (account.accountTypeCode === AccountTypeCode.ApplePay) {
                    this.initiateSDK();
                } else {
                    this.showApplePayPrompt =
                        this.paymentMethodsService.setApplePayPrompt();
                }
                this.formGroup.controls.amt.updateValueAndValidity();
            })
        );
    }

    /**
     * This listens to the keypress event of the input field
     * If the selected account is apple pay, we could want to call deposit start before
     * we confirm to deposit, we do this to get session token for web SDK
     */
    private initiateSDK(): void {
        if (!this.paymentMethodsService.hasApplePay) {
            return;
        }

        const debouncedInput = this.formGroup.valueChanges.pipe(
            tap(() => {
                this.initiatingSDK = true;
            }),
            debounceTime(500)
        );

        this.subscriptions.add(
            debouncedInput.subscribe(async (value) => {
                const inputValue = isNaN(Number(value?.amt))
                    ? null
                    : Number(value?.amt);
                if (this.validateBeforeDeposit(inputValue)) {
                    try {
                        this.initiatingSDK = true;
                        const result = await this.onDepositCreatePayment(
                            inputValue
                        );

                        this.initiatingSDK = false;
                        this.depositResponse = result;

                        this.sfc = window["SafeCharge"]({
                            sessionToken:
                                result.nuveiDepositDetails.sessionToken,
                            merchantId:
                                this.cashierAPIService.playerData.gateway.nuvei
                                    .merchantId,
                            merchantSiteId:
                                this.cashierAPIService.playerData.gateway.nuvei
                                    .merchantSiteId,
                            env: this.cashierAPIService.playerData.gateway.nuvei
                                .env
                        });
                    } catch (error) {
                        // Handle error appropriately (e.g., log, show error message)
                        console.error("Error:", error);
                        this.initiatingSDK = false;
                    }
                } else {
                    this.initiatingSDK = false;
                }
            })
        );
    }

    private validateBeforeDeposit(amount: number): boolean {
        if (!amount) {
            return false;
        }
        if (amount > this.maxAllowed) {
            return false;
        }
        if (amount < this.minAllowed) {
            return false;
        }
        return !(
            this.paymentMethodsService.currentSelected.accountTypeCode !==
                AccountTypeCode.ApplePay || this.formGroup.invalid
        );
    }

    /**
     * Contains the createApplePayPayment method
     * which needs a session Token returned from DepositStart
     * https://docs.nuvei.com/documentation/global-guides/apple-pay-guides/implementation/static-apple-pay-button-using-web-sdk/
     * @param depositAmount
     */
    private async onDepositCreatePayment(
        depositAmount: number
    ): Promise<DepositResponse> {
        return await this.cashierAPIService
            .initiateDeposit(
                this.requestData(
                    {
                        promoId: this.formGroup.value.promoID,
                        promoCode: this.formGroup.value.promoCode,
                        isOffer: this.formGroup.value.isOffer || false
                    },
                    depositAmount
                )
            )
            .toPromise();
    }

    /**
     * Click handler to create or select an Apple Pay CoinAccount
     */
    public navigateToApplePaySelectedHandler(): void {
        this.paymentMethodsService.navigateToApplePaySelected();
    }

    /**
     * Preps the data that is sent to the DepositStart endpoint
     * @param promoData
     * @param depositAmount
     * @private
     */
    private requestData(
        promoData = {},
        depositAmount: number
    ): DepositStartRequest {
        return (this.depositStartData = {
            ...this.depositStartData,
            ...{
                amount: depositAmount,
                accountId: null,
                accountTypeCode: AccountTypeCode.ApplePay,
                ukgcAck: true
            },
            ...promoData
        });
    }
}

