import { NgModule } from "@angular/core";
import { DepositLimitsResponseComponent } from "./deposit-limits-response.component";
import { DepositLimitsResponseErrorComponent } from "./deposit-limits-response-error/deposit-limits-response-error.component";
import { SharedModule } from "app/modules/shared.module";

@NgModule({
    declarations: [
        DepositLimitsResponseComponent,
        DepositLimitsResponseErrorComponent
    ],
    imports: [SharedModule]
})
export class DepositLimitsResponseModule {}
