import { Component, Input } from "@angular/core";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { AccountTypeCode } from "app/enums/account-type-code.enum";

@Component({
    selector: "app-account-type-name",
    templateUrl: "./account-type-name.component.html"
})
export class AccountTypeNameComponent {
    @Input() account: CoinAccount;
    public AccountTypeCodeEnum = AccountTypeCode;
}
