export interface Skin {
    id: number;
    domainName: string;
    name: string;
    enabled?: boolean;
    notifiesVF?: boolean;
    isEPI?: boolean;
    withdrawalDescriptor?: string;
}

export const Skins: Skin[] = [
    {
        id: 1,
        domainName: "SpinAndWin.com",
        name: "Spin and Win",
        isEPI: true,
        withdrawalDescriptor: "SPINANDWIN"
    },
    {
        id: 2,
        domainName: "KittyBingo.com",
        name: "Kitty Bingo",
        notifiesVF: true,
        isEPI: true,
        withdrawalDescriptor: "KITTYBINGO"
    },
    {
        id: 3,
        domainName: "LuckyPantsBingo.com",
        name: "Lucky Pants",
        notifiesVF: true,
        isEPI: true,
        withdrawalDescriptor: "LUCKYPANTS"
    },
    {
        id: 4,
        domainName: "DirectSlot",
        name: "Direct Slot",
        enabled: false
    },
    {
        id: 5,
        domainName: "MagicalVegas.com",
        name: "Magical Vegas",
        isEPI: true,
        withdrawalDescriptor: "MAGICALVEGAS"
    },
    {
        id: 6,
        domainName: "BingoExtra",
        name: "Bingo Extra",
        enabled: false
    },
    {
        id: 7,
        domainName: "BubblyBingo",
        name: "Bubbly Bingo",
        enabled: false
    },
    {
        id: 8,
        domainName: "LuckyVIP.com",
        name: "Lucky VIP",
        isEPI: true,
        withdrawalDescriptor: "LUCKYVIP"
    },
    {
        id: 9,
        domainName: "GiveBackBingo",
        name: "Give Back Bingo",
        enabled: false
    },
    {
        id: 10,
        domainName: "RegalWins.com",
        name: "Regal Wins",
        isEPI: true,
        withdrawalDescriptor: "REGALWINS"
    },
    {
        id: 11,
        domainName: "KingJack",
        name: "King Jack",
        enabled: false
    },
    {
        id: 12,
        domainName: "RialtoCasino.com",
        name: "Rialto Casino",
        isEPI: true,
        withdrawalDescriptor: "RIALTO"
    },
    {
        id: 13,
        domainName: "BellaCasino.com",
        name: "Bella Casino",
        withdrawalDescriptor: "BELLA",
        isEPI: true
    },
    {
        id: 14,
        domainName: "MeccaGames.com",
        name: "Mecca Games",
        isEPI: true,
        withdrawalDescriptor: "MECCAGAMES"
    },
    {
        id: 15,
        domainName: "TheVic.com",
        name: "The Vic",
        isEPI: true,
        withdrawalDescriptor: "THEVIC"
    },
    {
        id: 16,
        domainName: "MeccaBingo.com",
        name: "Mecca Bingo",
        notifiesVF: true,
        isEPI: true,
        withdrawalDescriptor: "MECCABINGO"
    },
    {
        id: 17,
        domainName: "GrosvenorCasinos.com",
        name: "Grosvenor Casino",
        isEPI: true,
        notifiesVF: true,
        withdrawalDescriptor: "GROSVENOR"
    }
];
