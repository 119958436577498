import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EnvironmentService } from "./services/environment/environment.service";
import { AppLoadService } from "./services/app-load/app-load.service";
import { FooterLinksComponent } from "./components/footer-links/footer-links.component";
import { MaskComponent } from "./components/mask/mask.component";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    HTTP_INTERCEPTORS,
    HttpClientJsonpModule,
    HttpClientModule
} from "@angular/common/http";
import { SharedModule } from "./modules/shared.module";
import { DepositModule } from "./modules/deposit/deposit.module";
import { DepositLimitsModule } from "./modules/deposit-limits/deposit-limits.module";
import { DepositLimitsResponseModule } from "./modules/deposit-limits-response/deposit-limits-response.module";
import { MyAccountsModule } from "./modules/my-accounts/my-accounts.module";
import { AddAccountModule } from "./modules/add-account/add-account.module";
import { AddAccountResponseModule } from "./modules/add-account-response/add-account-response.module";
import { DepositResponseModule } from "./modules/deposit-response/deposit-response.module";
import { WithdrawResponseModule } from "./modules/withdraw-response/withdraw-response.module";
import { WithdrawModule } from "./modules/withdraw/withdraw.module";
import { CookieService } from "ngx-cookie-service";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { LoggerInterceptor } from "./interceptors/logger.interceptor";
import { PlayerCurrencyPipe } from "./pipes/player-currency/player-currency.pipe";
import { AuthenticatedGuard } from "./guards/authenticated/authenticated.guard";
import { paths } from "./paths";
import { ErrorComponent } from "./components/error/error.component";
import { DepositBlockedComponent } from "./components/deposit-blocked/deposit-blocked.component";
import { DepositComponent } from "./modules/deposit/deposit.component";
import { AllowedToDepositGuard } from "./guards/allowed-to-deposit/allowed-to-deposit.guard";
import { HasActivePaymentMethodGuard } from "./guards/has-active-payment-method/has-active-payment-method.guard";
import { APNResponseCheckerGuard } from "./guards/apn-response-checker/apn-response-checker.guard";
import { DepositResponseComponent } from "./modules/deposit-response/deposit-response.component";
import { AddAccountResponseComponent } from "./modules/add-account-response/add-account-response.component";
import { AddAccountComponent } from "./modules/add-account/add-account.component";
import { AddAccountOptionsComponent } from "./modules/add-account/add-account-options/add-account-options.component";
import { AddAccountDebitCardComponent } from "./modules/add-account/add-account-debit-card/add-account-debit-card.component";
import { AboutStoringCardDetailsComponent } from "./modules/add-account/about-storing-card-details/about-storing-card-details.component";
import { AddAccountEWalletComponent } from "./modules/add-account/add-account-ewallet/add-account-ewallet.component";
import { AccountTypeCode } from "./enums/account-type-code.enum";
import { ConfirmNonRefundableComponent } from "./modules/add-account/confirm-non-refundable/confirm-non-refundable.component";
import { MyAccountsComponent } from "./modules/my-accounts/my-accounts.component";
import { WithdrawResponseComponent } from "./modules/withdraw-response/withdraw-response.component";
import { WithdrawComponent } from "./modules/withdraw/withdraw.component";
import { WithdrawHomeComponent } from "./modules/withdraw/withdraw-home/withdraw-home.component";
import { WithdrawConfirmComponent } from "./modules/withdraw/withdraw-confirm/withdraw-confirm.component";
import { NoDepositsYetComponent } from "./modules/withdraw/no-deposits-yet/no-deposits-yet.component";
import { DepositLimitsResponseComponent } from "./modules/deposit-limits-response/deposit-limits-response.component";
import { DepositLimitsComponent } from "./modules/deposit-limits/deposit-limits.component";
import { DepositLimitsResolver } from "./resolvers/deposit-limits/deposit-limits.resolver";
import { DepositLimitsOverviewComponent } from "./modules/deposit-limits/deposit-limits-overview/deposit-limits-overview.component";
import { DepositLimitsEditComponent } from "./modules/deposit-limits/deposit-limits-edit/deposit-limits-edit.component";
import { GlobalErrorHandler } from "./errors/global-error-handler";

/**
 * Fetch Environment values, then only initialize the app
 * @param environmentService
 * @param appLoadService
 */
function initializeAppFactory(
    environmentService: EnvironmentService,
    appLoadService: AppLoadService
): () => Promise<void> {
    return async () => {
        await environmentService.loadEnvConfig();
        appLoadService.initializeApp();
        return Promise.resolve();
    };
}

export const ChildRoutes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: paths.DEPOSIT
    },
    {
        path: paths.ERROR,
        component: ErrorComponent,
        data: {
            title: "Error"
        }
    },
    {
        path: paths.DEPOSIT_BLOCKED,
        pathMatch: "full",
        component: DepositBlockedComponent,
        data: {
            allowFooterLinks: false
        }
    },
    {
        path: paths.DEPOSIT,
        component: DepositComponent,
        canActivate: [AllowedToDepositGuard],
        children: [
            {
                path: "",
                component: DepositComponent,
                canActivate: [
                    HasActivePaymentMethodGuard,
                    APNResponseCheckerGuard
                ],
                data: {
                    title: "Deposit"
                }
            }
        ]
    },
    {
        path: `${paths.DEPOSIT}/${paths.RESPONSE}/:status`,
        component: DepositResponseComponent,
        data: {
            title: "Deposit"
        }
    },
    {
        path: `${paths.ADD_ACCOUNT}/${paths.RESPONSE}`,
        component: AddAccountResponseComponent
    },
    {
        path: paths.ADD_ACCOUNT,
        component: AddAccountComponent,
        data: {
            title: "New Payment Method"
        },
        children: [
            {
                path: "",
                component: AddAccountOptionsComponent
            },
            {
                path: paths.ADD_ACCOUNT_DEBIT_CARD,
                component: AddAccountDebitCardComponent
            },
            {
                path: paths.ABOUT_STORING_CARDS,
                component: AboutStoringCardDetailsComponent
            },
            {
                path: "paypal",
                component: AddAccountEWalletComponent,
                data: {
                    type: AccountTypeCode.PayPal
                }
            },
            {
                path: "paysafe",
                children: [
                    {
                        path: "",
                        component: ConfirmNonRefundableComponent
                    },
                    {
                        path: "confirmed",
                        component: AddAccountEWalletComponent,
                        data: {
                            type: AccountTypeCode.PaySafe
                        }
                    }
                ]
            },
            {
                path: "applepay",
                component: AddAccountEWalletComponent,
                data: {
                    type: AccountTypeCode.ApplePay
                }
            }
        ]
    },
    {
        path: paths.MY_ACCOUNTS,
        component: MyAccountsComponent,
        canActivate: [HasActivePaymentMethodGuard],
        data: {
            title: "Manage Payment Methods"
        }
    },
    {
        path: `${paths.WITHDRAW}/${paths.RESPONSE}/:status`,
        component: WithdrawResponseComponent,
        data: {
            title: "Withdraw"
        }
    },
    {
        path: paths.WITHDRAW,
        component: WithdrawComponent,
        data: {
            title: "Withdraw"
        },
        children: [
            {
                path: "",
                component: WithdrawHomeComponent
            },
            {
                path: "confirm",
                component: WithdrawConfirmComponent
            },
            {
                path: paths.WITHDRAW_NO_DEPOSITS,
                component: NoDepositsYetComponent,
                data: {
                    layoutHeader: false
                }
            }
        ]
    },
    {
        path: `${paths.DEPOSIT_LIMITS}/${paths.RESPONSE}`,
        component: DepositLimitsResponseComponent,
        data: {
            title: "My Limits"
        }
    },
    {
        path: paths.DEPOSIT_LIMITS,
        data: {
            title: "My Limits"
        },
        component: DepositLimitsComponent,
        resolve: {
            depositLimits: DepositLimitsResolver
        },
        runGuardsAndResolvers: "always",
        children: [
            {
                path: "",
                component: DepositLimitsOverviewComponent
            },
            {
                path: ":limitPeriod",
                component: DepositLimitsEditComponent
            }
        ]
    },
    {
        path: "**",
        redirectTo: paths.DEPOSIT
    }
];

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        component: AppComponent,
        canActivate: [AuthenticatedGuard]
    },
    {
        path: paths.BASE,
        component: AppComponent,
        runGuardsAndResolvers: "always",
        children: ChildRoutes
    }
];

@NgModule({
    declarations: [FooterLinksComponent, MaskComponent, AppComponent],
    imports: [
        // angular
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: "reload"
        }),
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ReactiveFormsModule,
        RouterModule,

        // custom
        SharedModule,
        DepositModule,
        DepositLimitsModule,
        DepositLimitsResponseModule,
        MyAccountsModule,
        AddAccountModule,
        AddAccountResponseModule,
        DepositResponseModule,
        WithdrawResponseModule,
        WithdrawModule
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        CookieService,
        // APP initializer
        EnvironmentService,
        AppLoadService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [EnvironmentService, AppLoadService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoggerInterceptor,
            multi: true
        },
        PlayerCurrencyPipe
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})
export class AppRoutingModule {}
