import { Directive, ElementRef, Input } from "@angular/core";
import { iconRegistry } from "./icons";

@Directive({
    selector: "[cashierIcon]"
})
export class CashierIconDirective {
    @Input()
    set cashierIcon(iconName: string) {
        this.setSVG(this._element, iconName);
    }

    @Input()
    set color(color: string) {
        this.setColor(this._element, color);
    }

    @Input()
    set height(height: string) {
        this.setHeight(this._element, height);
    }

    @Input()
    set width(width: string) {
        this.setWidth(this._element, width);
    }

    constructor(private _element: ElementRef) {}

    setSVG(element: ElementRef, iconName: string): void {
        element.nativeElement.innerHTML = iconRegistry[iconName] || null;
    }

    setColor(element: ElementRef, color: string): void {
        element.nativeElement.innerHTML =
            element.nativeElement.innerHTML.replace(
                /"#[0-9a-zA-Z]{3,6}"/gm,
                `"${color}"`
            );
    }

    setHeight(element: ElementRef, height: string): void {
        element.nativeElement.innerHTML =
            element.nativeElement.innerHTML.replace(
                /height="[0-9]{2}"/gm,
                `height="${height}"`
            );
    }

    setWidth(element: ElementRef, width: string): void {
        element.nativeElement.innerHTML =
            element.nativeElement.innerHTML.replace(
                /width="[0-9]{2}"/gm,
                `width="${width}"`
            );
    }
}
