import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DepositLimitsService } from "app/services/deposit-limits/deposit-limits.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DepositLimitPeriod } from "app/enums/deposit-limit-period";
import {
    DepositLimit,
    DepositLimitsResponse
} from "app/interfaces/deposit-limits.interface";
import { PlayerService } from "app/services/player/player.service";
import { matchMoneyPattern } from "app/validators/money-pattern.validator";
import { DepositLimitValidator } from "app/validators/deposit-limit.validator";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { DepositLimitChangeAction } from "app/enums/deposit-limit-change-action.enum";

@Component({
    selector: "app-deposit-limits-edit",
    templateUrl: "./deposit-limits-edit.component.html"
})
export class DepositLimitsEditComponent {
    private readonly limitPeriod: DepositLimitPeriod;

    public readonly playerTUIsAmber = this._playerService.isAmber;
    public limitPeriodData: DepositLimit;
    public limitsResponse: DepositLimitsResponse;
    public formGroup: FormGroup;
    public currency = this._playerSessionService.currencySign;
    private changedLimits = {
        affectedPeriod: null
    };

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _depositLimitsService: DepositLimitsService,
        private depositLimitValidator: DepositLimitValidator,
        private _playerService: PlayerService,
        private readonly _playerSessionService: PlayerSessionService,
        private _formBuilder: FormBuilder
    ) {
        this.limitPeriod = this._activatedRoute.snapshot.paramMap.get(
            "limitPeriod"
        ) as DepositLimitPeriod;
        this.limitPeriodData =
            this._depositLimitsService.playerLimitsFormatted[this.limitPeriod];

        this._activatedRoute.parent.data.subscribe((data) => {
            this.limitsResponse = data.depositLimits;
        });
        this.setupForm();
    }

    private async setupForm(): Promise<void> {
        this.formGroup = this._formBuilder.group({
            amount: [
                null,
                [
                    Validators.pattern(/^\d+$/),
                    Validators.required,
                    this.depositLimitValidator.validate(
                        this.limitPeriod,
                        this._depositLimitsService.playerLimitsFormatted
                    ),
                    matchMoneyPattern
                ]
            ]
        });
    }

    public submit(): void {
        if (!this.formGroup.valid) {
            return;
        }

        const depositLimitFormValue =
            parseInt(this.formGroup.value.amount) ?? null;

        const requestData = {
            [this.limitPeriod]: depositLimitFormValue
        };

        this._depositLimitsService
            .change({
                changeAction: DepositLimitChangeAction.SUBMIT_CHANGE,
                skinNetDepositLimits: {
                    ...requestData
                }
            })
            .subscribe(() => {
                /**
                 * When the limit decreases
                 * OR
                 * if there was no previous value, i.e. null
                 *
                 * Add some extra data to the response
                 * so that the success page knows what limit has changed
                 */
                if (
                    this.limitPeriodData.value > depositLimitFormValue ||
                    this.limitPeriodData.value === null
                ) {
                    this.changedLimits.affectedPeriod = this.limitPeriod;
                }
                this._depositLimitsService.redirectToResponsePage(
                    this.changedLimits
                );
            });
    }
}
