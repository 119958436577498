import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { SkinService } from "app/services/skin/skin.service";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { PromoService } from "app/services/promo/promo.service";
import { APP_COOKIE_KEYS } from "app/app.settings";
import { EnvironmentService } from "app/services/environment/environment.service";
import { DepositResponse } from "app/interfaces/deposit.interface";

@Injectable({
    providedIn: "root"
})
export class VfBingoService {
    constructor(
        public http: HttpClient,
        private cookieService: CookieService,
        private readonly environmentService: EnvironmentService,
        private featureToggleService: FeatureToggleService,
        private readonly _playerSessionService: PlayerSessionService,
        private readonly _promoService: PromoService,
        private skinService: SkinService
    ) {}

    public vfDeposit(
        depositResponse: DepositResponse,
        transactionAmount: number
    ): void {
        const promoDepositFlag = this.featureToggleService.getConfigValue(
            SupportedFeatures.PROMO_DEPOSIT
        );
        if (promoDepositFlag) {
            this.promoDeposit(depositResponse, transactionAmount);
        } else {
            this.notifyDeposit(depositResponse, transactionAmount);
        }
    }

    /**
     * Calls GI after a successful deposit if:
     * 1. Player is on one of the supported skins
     * 2. Player has chosen a VF Bonus Promo
     * @see   SelectAmountToDepositComponent.onDepositSuccess
     * @param depositResponse
     */
    public promoDeposit(
        depositResponse: DepositResponse,
        transactionAmount: number
    ): void {
        if (!this.skinService.supportsVF) {
            return;
        }

        const promoId = this.getPromoId();

        const data = {
            selectedPromoId: null,
            notifyDeposit: {
                username: this._playerSessionService.username,
                amount: transactionAmount,
                depositNumber: this._playerSessionService.depositCount,
                currency: this._playerSessionService.currencyCode,
                transactionId:
                    this._playerSessionService.getTxDepositID(depositResponse),
                skinId: this._playerSessionService.skinID
            }
        };

        /**
         * When there is no attached VF Bonus
         * remove the selectedPromoID from the payload
         * sent to VF Bingo
         */
        if (!this.hasVFBonus(depositResponse)) {
            delete data.selectedPromoId;
        } else {
            data.selectedPromoId = promoId;
        }

        const body = JSON.stringify(data);
        const url = this.environmentService.VF_BINGO + "/promoDepositPost";

        this.http
            .post(url, body, {
                observe: "body",
                headers: this.getHeaders(),
                responseType: "text"
            })
            .subscribe();
    }

    /**
     * Notify on success deposit only if there is a VF Bonus
     * @param depositResponse
     */
    private async notifyDeposit(
        depositResponse: DepositResponse,
        transactionAmount: number
    ): Promise<void> {
        const res = this.checkPromo();
        if (res) {
            // First register promo if it exists
            await this.registerPromo();
            this.sendNotifyDeposit(
                this._playerSessionService.getTxDepositID(depositResponse),
                transactionAmount
            );
        } else {
            this.sendNotifyDeposit(
                this._playerSessionService.getTxDepositID(depositResponse),
                transactionAmount
            );
        }
    }

    /**
     * Get the selected promoId from the cache
     * @returns {number}    0 when the promoId is null, otherwise the promoID
     */
    private getPromoId(): number {
        return this._promoService.promoPayload$.value.id === null
            ? 0
            : this._promoService.promoPayload$.value.id;
    }

    /**
     * Checks the Deposit Response for
     * a VF Bonus
     * @param depositResponse
     */
    private hasVFBonus(depositResponse): boolean {
        if (
            +depositResponse?.VFBingoBonusPrize1Amount === 0 &&
            +depositResponse?.VFMiniGameBonusPrize1Amount === 0
        ) {
            return false;
        }
        return true;
    }

    public async notifyWithdraw(transactionId: number): Promise<void> {
        const data = {
            transactionId,
            skinId: this._playerSessionService.skinID,
            username: this._playerSessionService.username
        };
        const body = JSON.stringify(data);
        const url = this.environmentService.VF_BINGO + "/notifyWithdrawal";

        await this.http
            .post(url, body, {
                observe: "body",
                headers: this.getHeaders(),
                responseType: "text"
            })
            .toPromise();
    }

    private checkPromo(): boolean {
        if (this.cookieService.check(APP_COOKIE_KEYS.FROM_PROMOTIONS)) {
            const cookie = this.cookieService.get(
                APP_COOKIE_KEYS.FROM_PROMOTIONS
            );
            const origin = JSON.parse(cookie);
            let selectedBonus = false;
            if (origin[APP_COOKIE_KEYS.FROM_PROMOTIONS]) {
                selectedBonus = origin["type"] === "bonus";
            }
            return selectedBonus;
        } else {
            return false;
        }
    }

    private async registerPromo(): Promise<void> {
        const data = {
            playerId: this._playerSessionService.playerID.toString()
        };
        const url =
            this.environmentService.PLAYER_WALLET +
            "/prize/getAvailablePrizesVF";

        await this.http
            .get(url, {
                observe: "body",
                headers: this.getHeadersWallet(),
                params: data,
                responseType: "text"
            })
            .toPromise();
    }

    private async sendNotifyDeposit(transactionId, amount): Promise<void> {
        const data = {
            amount,
            transactionId,
            skinId: this._playerSessionService.skinID,
            username: this._playerSessionService.username,
            currency: this._playerSessionService.currencyCode,
            depositNumber: this._playerSessionService.depositCount
        };

        const body = JSON.stringify(data);
        const url = this.environmentService.VF_BINGO + "/notifyDeposit";

        await this.http
            .post(url, body, {
                observe: "body",
                headers: this.getHeaders(),
                responseType: "text"
            })
            .toPromise();
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Authorization", this._playerSessionService.token)
            .set("SessionId", this._playerSessionService.sessionID)
            .set("PlayerId", this._playerSessionService.playerID.toString());
        return headers;
    }

    private getHeadersWallet(): HttpHeaders {
        const headers = new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Authorization", this._playerSessionService.token);
        return headers;
    }
}
