import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { EnvironmentService } from "app/services/environment/environment.service";
import { catchError, tap } from "rxjs/operators";
import { LoggerService } from "app/services/logger/logger.service";

/**
 * Logs all HTTP Requests and Responses for:
 * 1. COIN
 * 2. VF Bingo Service
 * 3. Bonus & Rewards
 */
@Injectable()
export class LoggerInterceptor implements HttpInterceptor {
    constructor(
        private readonly environmentService: EnvironmentService,
        private readonly _loggerService: LoggerService
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const whitelist = [
            {
                url: this.environmentService.COIN_API,
                name: "COIN"
            },
            {
                url: this.environmentService.VF_BINGO,
                name: "VFBingo"
            },
            {
                url: this.environmentService.PLAYER_WALLET,
                name: "Bonus&Rewards"
            }
        ];

        const isAllowed = whitelist.find(
            (item) => item.url && request.url.indexOf(item.url) === 0
        );
        if (isAllowed === undefined) {
            return next.handle(request);
        }

        this._loggerService.info(`${isAllowed.name} Request`, request);

        return next.handle(request).pipe(
            catchError((error) => {
                const errorMsg =
                    error.error instanceof ErrorEvent
                        ? "Client Side Error Response"
                        : "HTTP Error Response";
                this._loggerService.error(errorMsg, {
                    message: error?.message,
                    name: error?.name,
                    error: error.error,
                    url: error?.url
                });
                return throwError(errorMsg);
            }),
            tap((event: HttpEvent<any>) => {
                if (!(event instanceof HttpResponse)) {
                    return event;
                }

                let logBody = event.body;
                if (typeof logBody !== "object") {
                    try {
                        logBody = JSON.parse(event.body);
                    } catch {
                        logBody = event.body;
                    }
                }

                /**
                 * Log the HTTP Response
                 * as well as the URL that was called
                 */
                logBody = { ...logBody, ...{ url: event.url } };
                this._loggerService.info(`${isAllowed.name} Response`, logBody);

                return event;
            })
        );
    }
}
