import { Component } from "@angular/core";
import { ResponseHandlerComponent } from "app/components/response-handler/response-handler.component";
import { paths } from "app/paths";

@Component({
    selector: "app-withdraw-response",
    templateUrl: "./withdraw-response.component.html"
})
export class WithdrawResponseComponent extends ResponseHandlerComponent {
    public returnURL = `/${paths.BASE}/${paths.WITHDRAW}`;
}
