<sbmedia-response-type
    [size]="'medium'"
    [type]="'failure'"
></sbmedia-response-type>

<div class="my-3 d-flex align-items-center flex-column">
    <div class="text-center">
        <h5 class="text-danger">Deposit Limit Update Unsuccessful</h5>
        <p
            class="text-danger"
            *ngIf="systemMessage"
            [innerHTML]="systemMessage"
        ></p>
    </div>
</div>

<app-cashier-button appCloseCashier>Close</app-cashier-button>

<a
    class="mt-3 d-block text-center text-secondary"
    href="javascript:void(0)"
    appChatWithUs
    >Chat with us</a
>
