export const paths = {
    ABOUT_STORING_CARDS: "about-storing-cards",
    ADD_ACCOUNT: "add-account",
    ADD_ACCOUNT_DEBIT_CARD: "card",
    ADD_ACCOUNT_EWALLET: "ewallet",
    BASE: "v3",
    NON_REFUNDABLE_CONFIRMED: "confirmed",
    DECLINE_RECOVERY: "error",
    DEPOSIT: "deposit",
    DEPOSIT_LIMITS: "limits",
    DEPOSIT_BLOCKED: "deposits-blocked",
    ERROR: "error",
    MY_ACCOUNTS: "my-accounts",
    RESPONSE: "response",
    WITHDRAW: "withdraw",
    WITHDRAW_NO_DEPOSITS: "no-deposits"
};
