import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { promoCodeValidator } from "app/validators/promo-code.validator";
import { PromoService } from "app/services/promo/promo.service";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { FormFieldComponent } from "app/components/form-field/form-field.component";
import { Promo } from "app/interfaces/accounts.interface";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { AccountTypeCode } from "app/enums/account-type-code.enum";
import { PlayerSessionService } from "app/services/player-session/player-session.service";

@Component({
    selector: "app-promo-form",
    templateUrl: "./promo-form.component.html",
    styleUrls: ["./promo-form.component.scss"]
})
export class PromoFormComponent implements OnInit, OnDestroy {
    public formGroup: FormGroup;
    public showBonusField: boolean;
    public showAllBonuses: boolean;
    public bonusPromoVisibility: boolean;
    public chevronState = "chevron_down";
    public selectedBonusID: string;
    private subs = new Subscription();

    public readonly promosEnabled: boolean =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.DEPOSIT_PROMOS
        );
    public readonly bonusesEnabled: boolean =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.DEPOSIT_BONUSES
        );
    public readonly bonusPromoDropdownDefaultState: boolean =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.BONUS_DROPDOWN_DEFAULT_STATE
        );
    private readonly unSupportedBonusPaymentMethods: AccountTypeCode[] =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.unSupportedFTDBonusPaymentMethods
        );

    public showError = false;
    public checkingPromoCode: Subscription;
    private defaultPromoID: null;
    @ViewChild("inputField") inputField: FormFieldComponent;

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private featureToggleService: FeatureToggleService,
        private playerSessionService: PlayerSessionService,
        private _formBuilder: FormBuilder,
        private _promoService: PromoService
    ) {
        this.listenForPromoChanges();
        this.listenForSelectedAccountChanges();
    }

    public get bonuses(): Promo[] {
        if (!this.paymentMethodsService.currentSelected) {
            return [];
        }
        const currentSelectedAccount =
            this.paymentMethodsService.currentSelected;

        if (this.supportBonusesAndPromosFTD()) {
            const filteredPromos = currentSelectedAccount.promos.map(
                (promo) => {
                    return {
                        promoId: promo.promoId,
                        depositMin: promo.depositMin,
                        description: promo.description,
                        isOffer: promo.isOffer || false
                    };
                }
            );
            return filteredPromos;
        } else {
            return [];
        }
    }

    public get firstAvailableBonus(): Promo[] {
        if (this.bonuses > []) {
            if (
                this.paymentMethodsService.currentSelected?.promos.length == 2
            ) {
                return this.paymentMethodsService.currentSelected.promos.slice(
                    0,
                    2
                );
            }
            return new Array(
                this.paymentMethodsService.currentSelected.promos[0]
            );
        }
        return [];
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.setupForm().then((formGroup) => {
            this.addFormListeners(formGroup);
            this.checkCookiesForCode();
        });
        if (this.promosEnabled || this.bonusesEnabled) {
            this.setPromoBonusVisibility(this.bonusPromoDropdownDefaultState);
        }
    }

    public supportBonusesAndPromosFTD(): boolean {
        if (!this.paymentMethodsService.currentSelected) return false;
        const depositCount = this.playerSessionService.depositCount;

        if (
            this.unSupportedBonusPaymentMethods.includes(
                this.paymentMethodsService.currentSelected?.accountTypeCode
            ) &&
            depositCount < 1
        ) {
            return false;
        }
        return true;
    }

    private listenForPromoChanges(): Subscription {
        return this._promoService.promoPayload$.subscribe((resp) => {
            this.selectedBonusID = resp?.id?.toString();
        });
    }

    private listenForSelectedAccountChanges(): void {
        this.subs.add(
            this.paymentMethodsService.selected$.subscribe((resp) => {
                if (
                    resp?.accountTypeCode == AccountTypeCode.PayPal &&
                    !this.playerSessionService.claimCode
                ) {
                    this._promoService.clear();
                }
            })
        );
    }

    public toggleSelection(bonus: Promo): void {
        if (this.selectedBonusID == bonus.promoId) {
            this.selectedBonusID = null;
            this._promoService.selectBonus(null);
        } else {
            this.selectedBonusID = bonus.promoId;
            this._promoService.selectBonus(bonus);
        }
    }

    public setPromoBonusVisibility(visibilityIndicator: boolean): void {
        if (this.bonusesEnabled) {
            this.showBonusField = visibilityIndicator;
            this.showAllBonuses = visibilityIndicator;
        }
    }

    public togglePromoBonusVisibility(): void {
        this.bonusPromoVisibility = !this.bonusPromoVisibility;
        this.chevronState = this.bonusPromoVisibility
            ? "chevron_up"
            : "chevron_down";
        this.showAllBonuses = !this.bonusPromoVisibility;
        this.setPromoBonusVisibility(this.bonusPromoVisibility);
    }

    /**
     * Disables the validated button when either...
     * 1. Form is invalid, i.e. when the promo code does not match validation requirements
     * 2. Form is busy validating the promo code
     * 3. When an already validated promo code is entered
     */
    public get preventSubmission(): boolean {
        return (
            this.formGroup.invalid ||
            this.busyCheckingPromoCode ||
            this.hasValidPromoCode
        );
    }

    /**
     * If this code exists...
     * pre-populate the form and automatically validate the code
     *
     * Additionally, force show the promo form even if
     * it has been turned off via the feature flag
     *
     * when there is a promo code in the cookies
     *
     * @private
     */
    private checkCookiesForCode() {
        const promoCodeInCookie = this._promoService.hasCodeInCookies();
        if (!promoCodeInCookie) {
            return;
        }

        /**
         * Assume the promo code used is valid
         * In the event it is invalid, this value will be cleared
         */
        this._promoService.updateSubject(promoCodeInCookie);

        this.formGroup.patchValue({
            code: promoCodeInCookie
        });
    }

    /**
     * Validates the promo code
     * First resets the value for the ID field
     * Upon a successful validation, the ID field is updated with this value
     */
    public async submitForm(): Promise<void> {
        if (this.formGroup.invalid) {
            return;
        }

        this.checkingPromoCode = this._promoService
            .validateCode(this.formGroup.value.code)
            .subscribe((resp) => {
                this.formGroup.patchValue(
                    {
                        validatedPromoID: resp.PromoId
                    },
                    {
                        emitEvent: false
                    }
                );
            });
    }

    public get hasValidPromoCode(): boolean {
        return this.formGroup && this.formGroup.value.validatedPromoID !== null;
    }

    public get busyCheckingPromoCode(): boolean {
        return this.checkingPromoCode !== undefined;
    }

    private async setupForm(): Promise<FormGroup> {
        return new Promise((resolve) => {
            this.formGroup = this._formBuilder.group({
                code: [null, [Validators.required, promoCodeValidator]],
                validatedPromoID: [this.defaultPromoID]
            });
            resolve(this.formGroup);
        });
    }

    /**
     * React to certain changes in the form
     * When text is entered:
     * 1. reset the ID Field value
     * 2. reset the variable that listens to the validation process
     * @param   formGroup
     * @private
     */
    private async addFormListeners(formGroup: FormGroup): Promise<void> {
        formGroup.controls.code.valueChanges.subscribe(() => {
            formGroup.controls.validatedPromoID.reset(this.defaultPromoID, {
                emitEvent: false
            });

            this.checkingPromoCode = undefined;
        });

        /**
         * Wait for 0.5sec before submitting automatically
         */
        formGroup.controls.code.valueChanges
            .pipe(debounceTime(800))
            .subscribe(() => {
                this.submitForm();
            });

        /**
         * We need to listen to this event so that if a player chooses a bonus
         * Then we need to clear this form
         */
        this._promoService.promoPayload$.subscribe((resp) => {
            if (resp.code === this.formGroup.value.code) {
                return;
            }

            /**
             * Set the placeholder value but clear the value so that
             * the player can see the failed promo code
             */
            if (this.inputField) {
                this.inputField.placeholder =
                    this.formGroup.controls.code.value;
            }
            this.formGroup.controls.code.reset(null, {
                emitEvent: false
            });
        });
    }
}
