import { Component, Input, OnInit } from "@angular/core";
import { PlayerCurrencyPipe } from "app/pipes/player-currency/player-currency.pipe";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { NotificationService } from "app/services/notification/notification.service";
import { CookieService } from "ngx-cookie-service";
import { APP_COOKIE_KEYS } from "app/app.settings";
import { DepositResponse } from "app/interfaces/deposit.interface";
import { CashierApiService } from "app/services/cashier-api.service";
import { CoinStatus } from "app/enums/coin-status.enum";

@Component({
    selector: "app-deposit-response-success",
    templateUrl: "./deposit-response-success.component.html",
    styleUrls: ["./deposit-response-success.component.scss"]
})
export class DepositResponseSuccessComponent implements OnInit {
    @Input() transactionAmount: number;
    @Input() cashierServiceResponse: DepositResponse;
    @Input() depositAmount: number;

    public successData: { text: string; value: string | number }[];

    constructor(
        private readonly _playerSessionService: PlayerSessionService,
        private _playerCurrencyPipe: PlayerCurrencyPipe,
        private _notificationService: NotificationService,
        private cashierAPIService: CashierApiService,
        private _cookieService: CookieService
    ) {}

    public ngOnInit(): void {
        this._playerSessionService.ukgcAck = false;

        this.successData = this.parseSuccessData(
            this.createSuccessDataObject(this.cashierServiceResponse)
        );
        const depositCount =
            this.cashierServiceResponse.depositCount ??
            this._playerSessionService.depositCount;

        const coinRef = this._playerSessionService.getTxDepositID(
            this.cashierServiceResponse
        );

        // we read this from a cookie since APMs payments are usually taken in a redirect flow
        // for card payments this isn't strictly necessary but the code doesnt make passing simple values around too easy
        // the cookie is set in PaymentMethodService.select() which is called whenever a payment method is selected
        const accountTypeCode = this._cookieService.get(
            APP_COOKIE_KEYS.LAST_SELECTED_ACCOUNTTYPE_CODE
        );

        this._notificationService.sendDepositNotification(
            this._playerSessionService.playerID.toString(),
            this.transactionAmount,
            this._playerSessionService.currencyCode,
            accountTypeCode,
            coinRef,
            depositCount,
            this.cashierAPIService.playerData.transunionStatus ?? null,
            this._playerSessionService.isWebView
        );
    }

    /**
     * This is to standardize the Deposit Response as we get it from different sources
     * @param cashierServiceResponse
     * @returns Partial<DepositResponse>
     */
    private createSuccessDataObject(data): Partial<DepositResponse> {
        if (data?.Status == CoinStatus.Success) {
            return {
                bonusAmount: data?.Bonus,
                vfBingoBonusPrize1Amount: data?.VFBingoBonusPrize1Amount,
                freeSpins: data?.FreeSpins,
                freeCards: data?.FreeCards,
                freeGamesPrize1Amount: data?.FreeGamesPrize1Amount,
                freeGamesPrize2Amount: data?.FreeGamesPrize2Amount
            };
        }
        return data;
    }

    private parseSuccessData(
        data: Partial<DepositResponse>
    ): { text: string; value: string | number }[] {
        return [
            {
                text: "Deposit amount",
                value: this._playerCurrencyPipe.transform(
                    this.transactionAmount
                )
            },
            {
                text: "Bonus received",
                value:
                    +data?.bonusAmount > 0
                        ? this._playerCurrencyPipe.transform(data?.bonusAmount)
                        : null
            },
            {
                text: "Bingo bonus",
                value:
                    +data?.vfBingoBonusPrize1Amount > 0
                        ? this._playerCurrencyPipe.transform(
                              data?.vfMiniGameBonusPrize1Amount
                          )
                        : null
            },
            {
                text: "Mini game bonus",
                value:
                    +data?.vfBingoBonusPrize1Amount > 0
                        ? this._playerCurrencyPipe.transform(
                              data?.vfMiniGameBonusPrize1Amount
                          )
                        : null
            },
            {
                text: "Free spins",
                value: +data?.freeSpins > 0 ? data?.freeSpins : null
            },
            {
                text: "Free cards",
                value: +data?.freeCards > 0 ? data?.freeCards : null
            },
            {
                text: "Prize wheel spins",
                value: this.setMultiPrizeValue(
                    +data?.freeGamesPrize1Amount,
                    +data?.freeGamesPrize2Amount
                )
            }
        ];
    }

    private setMultiPrizeValue(amount1: number, amount2: number): string {
        return amount1 > 0 || amount2 > 0
            ? (amount1 + amount2).toString()
            : null;
    }
}
