<div class="row no-gutters mt-2">
    <div class="col-auto p-2 mr-2">
        <span cashierIcon="warning" class="icon error"></span>
    </div>
    <div class="col p-0">
        <div class="row mb-3">
            <p class="text-danger">
                If you withdraw any amount you may lose any active bonuses in
                your account
            </p>
        </div>
        <div class="row mb-2">
            <p class="text-danger" style="font-weight: 500; font-size: 12px">
                Are you sure you want to continue?
            </p>
        </div>
    </div>
</div>
