import { Component, HostBinding, Input } from "@angular/core";

const enum Themes {
    PRIMARY = "primary",
    PRIMARY_OUTLINE = "primary-outline",
    SECONDARY = "secondary",
    SECONDARY_OUTLINE = "secondary-outline",
    TERTIARY = "tertiary",
    NONE = "none",
    PROMPT_APPLE_PAY = "prompt-apple-pay"
}

const enum Sizes {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large"
}

@Component({
    selector: "app-cashier-button",
    templateUrl: "./cashier-button.component.html",
    styleUrls: ["./cashier-button.component.scss"]
})
export class CashierButtonComponent {
    @Input() theme: Themes = Themes.PRIMARY;
    @Input() size: Sizes = Sizes.MEDIUM;
    @Input() fontWeight: "bold" | "normal" = "bold";
    @Input() disabled = false;
    @Input() text: string;
    @HostBinding("class") cssClass = "d-block";
}
