import { Injectable, Injector } from "@angular/core";
import { AuthService } from "app/services/auth/auth.service";
import { GtmService } from "app/services/gtm/gtm.service";
import { SkinService } from "app/services/skin/skin.service";
import { ScriptLoaderService } from "app/services/script-loader/script-loader.service";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { LoggerService } from "app/services/logger/logger.service";

@Injectable()
export class AppLoadService {
    constructor(
        private readonly _loggerService: LoggerService,
        private readonly _playerSessionService: PlayerSessionService,
        private readonly scriptLoaderService: ScriptLoaderService,
        private injector: Injector
    ) {}
    /**
     * Init App
     */
    initializeApp(): void {
        this._playerSessionService.initialize();

        try {
            const launchURL = new URL(document.location.href);
            launchURL.searchParams.set("cashierToken", "redacted");
            this._loggerService.info("Cashier Launch", {
                url: launchURL.toString()
            });
        } catch (ex) {
            this._loggerService.warn("Failed to Log launch url");
        }

        const authService = this.injector.get(AuthService);
        this._playerSessionService.initStatus = authService.isAuthenticated();
        if (!this._playerSessionService.skinID) {
            throw new Error("NO SKIN ID PROVIDED");
        }

        const skinService = this.injector.get(SkinService);

        if (skinService.isStride) {
            const gtmService = this.injector.get(GtmService);
            gtmService.loadScripts();
        }

        this.scriptLoaderService.injectSkinStyles(
            this._playerSessionService.skinID
        );
    }
}
