import { Component } from "@angular/core";
import { PlayerService } from "app/services/player/player.service";

@Component({
    selector: "app-affordability-deposit-error",
    templateUrl: "./affordability-deposit-error.component.html",
    styleUrls: ["./affordability-deposit-error.component.scss"]
})
export class AffordabilityDepositErrorComponent {
    public readonly playerTUIsAmber = this.playerService.isAmber;
    constructor(private readonly playerService: PlayerService) {}
}
