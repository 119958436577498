import { Component, Input } from "@angular/core";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { ExpireStatus } from "app/enums/expire-status.enum";
import { AccountTypeCode } from "app/enums/account-type-code.enum";

@Component({
    selector: "app-account-number",
    templateUrl: "./account-number.component.html"
})
export class AccountNumberComponent {
    @Input() account: CoinAccount;
    @Input() showExpiry = true;
    @Input() showApplePayAccountNumber = true;
    public ExpireStatusEnum = ExpireStatus;
    public AccountTypeCode = AccountTypeCode;
}
