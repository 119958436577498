import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class TokenService {
    /**
     * Returns value of Params
     * @param name
     * @param url
     * @returns {any}
     */
    getUrlParameter(name, url): string | null {
        const URLObj = new URL(url);
        return URLObj.searchParams.get(name);
    }
}
