import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { WithdrawComponent } from "./withdraw.component";
import { SharedModule } from "app/modules/shared.module";
import { WithdrawHomeComponent } from "./withdraw-home/withdraw-home.component";
import { NoDepositsYetComponent } from "./no-deposits-yet/no-deposits-yet.component";
import { WithdrawFormComponent } from "./withdraw-form/withdraw-form.component";
import { WithdrawConfirmComponent } from "./withdraw-confirm/withdraw-confirm.component";
import { WithdrawBonusComponent } from "./withdraw-bonus/withdraw-bonus.component";

@NgModule({
    declarations: [
        WithdrawComponent,
        WithdrawBonusComponent,
        WithdrawConfirmComponent,
        WithdrawFormComponent,
        WithdrawHomeComponent,
        NoDepositsYetComponent
    ],
    imports: [ReactiveFormsModule, SharedModule]
})
export class WithdrawModule {}
