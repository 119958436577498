import { Injectable } from "@angular/core";
import { CashierEvents } from "app/enums/cashier-events";
import { TUStatus } from "app/interfaces/start.interface";
import { LoggerService } from "../logger/logger.service";
import { CashierApiService } from "../cashier-api.service";

@Injectable({
    providedIn: "root"
})
export class NotificationService {
    constructor(
        private loggerService: LoggerService,
        private cashierAPIService: CashierApiService
    ) {}

    sendWithdrawalNotification(): void {
        window.parent.postMessage(
            {
                cashierEvent: CashierEvents.APP_WITHDRAWAL_REQUEST_COMPLETED
            },
            "*"
        );
    }

    async sendDepositNotification(
        playerId: string,
        amount: number,
        currency: string,
        paymentMethodType: string,
        coinRef: string,
        depositCount: number,
        affordabilityStatus: TUStatus,
        isWebview: boolean
    ): Promise<void> {
        const cashierData = await this.cashierAPIService
            .getPlayer()
            .toPromise();

        const eventCode = CashierEvents.APP_DEPOSIT_SUCCESS;
        const date = new Date();
        const payload = {
            cashierEvent: eventCode,
            data: {
                customerPlayerId: playerId,
                purchaseId: coinRef,
                depositAmount: amount,
                depositCurrency: currency,
                depositMethod: paymentMethodType,
                depositCount: depositCount,
                depositSource: this.isBrowser(isWebview) ? "Web" : "App",
                affordabilityGamblerStatus: TUStatus[affordabilityStatus],
                hitTimeStamp: date.toISOString(),
                availableToDeposit: cashierData?.deposit?.max ?? null
            }
        };
        window.parent.postMessage(payload, "*");
        console.log(
            `postMessage for cashierEvent:${eventCode} with payload:`,
            payload
        );
        this.loggerService.info(
            `postMessage for cashierEvent:${eventCode} fired`,
            payload
        );
    }

    /**
     * This is a crude implementation to try determine if we have been loaded in a native webview
     * (whether directly or indirectly) or if we are running in the context of a normal web browser.
     * It ideally does not belong here and in future should move to a different service so it can
     * also be utilised elsewhere.
     * See https://daubltd.atlassian.net/wiki/spaces/CCSI/pages/214862725127/Cashier+Parent+Sites+Wrapper+Apps+Native+Apps
     *
     * @returns true if we think we are running in a normal browser, false if not
     */
    private isBrowser(isWebview: boolean): boolean {
        return (
            !isWebview || // EPI Android native (mkodo) and EPI iOS native (mkodo)
            navigator.userAgent.includes("app-id") || // RAD2 Android + iOS wrapper apps
            navigator.userAgent.includes("bede_app_id") // EPI Android + iOS wrapper apps (mkodo)
        );
    }
}
