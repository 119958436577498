import { Injectable } from "@angular/core";
import {
    CanActivate,
    Router,
    UrlTree,
    NavigationExtras
} from "@angular/router";
import { paths } from "app/paths";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { DepositResponseStatus } from "app/enums/DepositResponseStatus.enum";
import { CoinStatus } from "app/enums/coin-status.enum";

@Injectable({
    providedIn: "root"
})
export class APNResponseCheckerGuard implements CanActivate {
    constructor(
        private _playerSessionService: PlayerSessionService,
        private _router: Router
    ) {}

    /**
     * Checks if there is a apn response redirect cookie that has been set
     * If so, take the user to the Deposit Response Page,
     * otherwise, let them proceed
     *
     * @see   AuthService.checkForRedirectDepositFeedback
     */
    canActivate(): boolean | UrlTree {
        if (!this._playerSessionService.redirectDepositFeedback) {
            return true;
        }
        let transactionResult = "error";
        const state: NavigationExtras = {
            state: {
                isCashierServiceTransaction: true
            }
        };

        if (
            this._playerSessionService.redirectDepositFeedback?.status ==
                DepositResponseStatus.S_SUCCESS_SETTLED ||
            this._playerSessionService.redirectDepositFeedback?.Status ==
                CoinStatus.Success
        ) {
            transactionResult = "success";
        }
        return this._router.createUrlTree(
            [
                `/${paths.BASE}/${paths.DEPOSIT}/${paths.RESPONSE}/${transactionResult}`
            ],
            state
        );
    }
}
