<label class="small text-dark" *ngIf="requestUKGCAck">
    <input
        type="checkbox"
        (change)="writeValue(checkbox.checked)"
        #checkbox
        [checked]="value === 1"
    />
    <span>
        I acknowledge that my funds are held in a designated bank account so
        that, in the event of insolvency, sufficient funds are always available
        to withdraw at any time. This is the medium level of protection outlined
        by the UK Gambling Commission.
        <a
            target="_blank"
            class="ml-1"
            href="//www.gamblingcommission.gov.uk/public-and-players/guide/how-gambling-companies-protect-your-money"
            >More</a
        >
    </span>
</label>
