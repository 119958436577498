<form [formGroup]="formGroup" class="mb-4">
    <section>
        <div class="section-title font-family-bold">Amount</div>
        <app-form-field
            formControlName="amount"
            [invalid]="
                formGroup.controls.amount.dirty &&
                formGroup.controls.amount.invalid
            "
            [disabled]="
                formGroup.disabled || formGroup.controls.amount.disabled
            "
            [value]="formGroup.value.amount"
        >
            <span slot="icon">{{ currency }}</span>
            <span slot="validation-error-message">
                <ng-container *ngIf="formGroup.controls.amount?.errors">
                    <ng-container
                        *ngIf="
                            formGroup.controls.amount?.errors.min;
                            else maxCheck
                        "
                    >
                        The minimum withdrawal amount is
                        {{ minAllowed | playerCurrency }}
                    </ng-container>
                    <ng-template #maxCheck>
                        <ng-container
                            *ngIf="
                                formGroup.controls.amount?.errors.max;
                                else invalidAmount
                            "
                        >
                            <ng-container
                                *ngIf="
                                    +formGroup.controls.amount?.value >
                                        +withdrawDetails.AvailableToWithdraw;
                                    else maxError
                                "
                            >
                                You cannot withdraw more than your balance
                            </ng-container>
                            <ng-template #maxError>
                                The maximum withdrawal amount is
                                {{ maxAllowed | playerCurrency }}
                            </ng-template>
                        </ng-container>
                    </ng-template>
                    <ng-template #invalidAmount>
                        Enter a valid amount.
                    </ng-template>
                </ng-container>
            </span>
        </app-form-field>
    </section>
    <ng-container
        *ngIf="
            !formGroup.controls.amount?.errors &&
            +withdrawDetails?.BonusToBeForfeited > 0
        "
    >
        <app-withdraw-bonus
            class="w-100"
            [bonusForfeited]="withdrawDetails?.BonusToBeForfeited"
        >
        </app-withdraw-bonus>
    </ng-container>

    <ng-container *ngIf="accountSelectionAllowed">
        <section class="mb-4">
            <div class="section-title font-family-bold">Pay into</div>
            <ng-container
                *ngIf="
                    accountsRespAsync | async as accountsResp;
                    else tplLoader
                "
            >
                <app-form-dropdown
                    formControlName="account"
                    [value]="formGroup.value.account"
                    [options]="accountsResp.accounts"
                    [optionTemplate]="tplAccountOption"
                    [footerTemplate]="tplDropdownFooter"
                >
                </app-form-dropdown>
                <ng-template #tplAccountOption let-option>
                    <app-account-type-logo
                        [code]="option.accountTypeCode"
                        size="compact"
                        class="mr-2"
                    ></app-account-type-logo>
                    <app-account-number
                        [account]="option"
                        [showExpiry]="false"
                    ></app-account-number>
                    <span
                        cashierIcon="credit_card_fast"
                        height="12"
                        *ngIf="option.SupportsFastPayouts"
                    ></span>
                </ng-template>
                <ng-template #tplDropdownFooter>
                    <a
                        class="w-100 text-decoration-none"
                        style="color: inherit; font-size: inherit"
                        [routerLink]="addPaymentMethodLink"
                        [queryParams]="{ intent: 'withdraw' }"
                    >
                        <span cashierIcon="tag_new_circle" class="mr-4"></span>
                        <span>Add new method</span>
                    </a>
                </ng-template>
            </ng-container>
        </section>
    </ng-container>
</form>

<ng-template #tplLoader>
    <img src="assets/images/spinner-busy.svg" class="animate spin" />
</ng-template>
