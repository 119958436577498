import { Component, Input } from "@angular/core";
import { AccountTypeCode } from "app/enums/account-type-code.enum";

@Component({
    selector: "app-account-type-logo",
    templateUrl: "./account-type-logo.component.html",
    styleUrls: ["./account-type-logo.component.scss"]
})
export class AccountTypeLogoComponent {
    @Input() code: AccountTypeCode;
    @Input() isExpired: boolean;
    @Input() size: "large" | "normal" | "alternate" | "compact" = "normal";
    public AccountTypeCodeEnum = AccountTypeCode;
}
