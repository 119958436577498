import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    ViewChild
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DepositLimit } from "app/interfaces/deposit-limits.interface";
import { SkinService } from "app/services/skin/skin.service";
import { DepositService } from "app/services/deposit/deposit.service";
import { Skins } from "app/enums/skin-id.enum";

@Component({
    selector: "app-deposit-limits-radial-bar-chart",
    templateUrl: "./deposit-limits-radial-bar-chart.component.html",
    styleUrls: ["./deposit-limits-radial-bar-chart.component.scss"]
})
export class DepositLimitsRadialBarChartComponent implements OnInit {
    @Input() limitPeriodData: DepositLimit;

    @HostBinding("hidden") isHidden = false;
    @HostBinding("attr.level") level: "green" | "amber" | "red";
    @ViewChild("svgElement") svgElement: ElementRef<SVGElement>;

    public usage$ = new BehaviorSubject<number>(null);
    public percentUsage$ = new BehaviorSubject<number>(null);
    public usageDescriptor$ = new BehaviorSubject<string>(null);
    public periodDescriptor$ = new BehaviorSubject<string>(null);
    public strokeDashoffset = "490.0884539600077";

    public get isGlobal(): boolean {
        return (
            !this.limitPeriodData.value &&
            !isNaN(this.limitPeriodData.DepositLimitGlobal) &&
            this.limitPeriodData.DepositLimitGlobal > 0
        );
    }

    constructor(
        private readonly depositService: DepositService,
        private readonly skinService: SkinService
    ) {}

    ngOnInit(): void {
        if (
            !this.limitPeriodData ||
            (!this.limitPeriodData.value && !this.isGlobal)
        ) {
            this.isHidden = true;
            return;
        }
        this.usage$.subscribe((usage) => this.calcPercentUsage(usage));
        this.percentUsage$.subscribe((percentUsage) => {
            this.calcColorLevel(percentUsage);
            this.calcProgressBar(percentUsage);
        });
        this.calcUsage();
        this.calcUsageDescriptor();
    }

    /**
     * Calculate how much the player has used
     * TODO: this maybe affected by the START DATA since a player can deposit
     * @private
     */
    private async calcUsage(): Promise<void> {
        let usage;
        if (this.isGlobal) {
            usage = this.limitPeriodData.DepositsGlobal;
        } else {
            usage = this.limitPeriodData.usage;
        }

        /**
         * Ensures that when a player has a negative usage, that the frontend sees a 0 instead
         */
        usage = Math.max(usage, 0);

        this.usage$.next(usage);
    }

    /**
     * Calculates how much of the limit has been used as a percentage of the limit
     * @param usage
     * @private
     */
    private async calcPercentUsage(usage: number): Promise<void> {
        let limit;
        if (this.isGlobal) {
            limit = this.limitPeriodData.DepositLimitGlobal;
            this.periodDescriptor$.next("over 30 days");
        } else {
            limit = this.limitPeriodData.value;
            this.periodDescriptor$.next(
                `over ${this.limitPeriodData.periodDescriptor}`
            );
        }
        this.percentUsage$.next(Math.round((usage / limit) * 100));
    }

    /**
     * Calculates the level of the usage, whether it is green / amber / red
     * @param percentUsage
     * @private
     */
    private async calcColorLevel(percentUsage: number): Promise<void> {
        if (
            percentUsage > 99 ||
            this.limitPeriodData.available < this.depositService.minAllowed
        ) {
            this.level = "red";
        } else if (percentUsage >= 75) {
            this.level = "amber";
        } else {
            this.level = "green";
        }
    }

    /**
     * Calculates the text to show
     * Varies between period, i.e. daily, weekly, monthly
     * ... Also varies between whether the limit is global or not
     * @private
     */
    private async calcUsageDescriptor(): Promise<void> {
        let result;
        if (this.isGlobal) {
            result = "across our sites";
        } else {
            result = `on ${
                Skins.find((s) => s.id === this.skinService.currentID)?.name
            }`;
        }
        this.usageDescriptor$.next(result);
    }

    /**
     * Calculates the offset of the circle progress bar
     * @param percentUsage
     * @private
     */
    private calcProgressBar(percentUsage: number) {
        /**
         * These values come straight from the SVG element
         */
        const strokeWidth = 20;
        const diameter = 176;

        const radius = (diameter - strokeWidth) / 2;
        const circumference = 2 * Math.PI * radius;
        const percentAvailable = (100 - percentUsage) / 100;
        const strokeDashoffset = (percentAvailable * circumference).toString();
        /**
         * Set the value after a certain period so that the animation can kick in
         */
        setTimeout(() => {
            this.strokeDashoffset = strokeDashoffset;
        }, 300);
    }
}
