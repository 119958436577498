import { Directive, HostListener, Input } from "@angular/core";
import { CashierService } from "app/services/cashier/cashier.service";
import { CLOSE_CASHIER_EVENTS } from "app/app.settings";

@Directive({
    selector: "[appCloseCashier]"
})
export class CloseCashierDirective {
    @Input() appCloseCashier:
        | string
        | "survey"
        | "privacy"
        | "logout"
        | "chatWithUs"
        | "support";
    constructor(private _cashierService: CashierService) {}

    @HostListener("click", ["$event"]) closeCashier(): void {
        let eventToUse;
        switch (this.appCloseCashier) {
            case "survey":
                eventToUse =
                    CLOSE_CASHIER_EVENTS.APP_CLOSE_CASHIER_AND_OPEN_SURVEY;
                break;
            case "privacy":
                eventToUse =
                    CLOSE_CASHIER_EVENTS.APP_CLOSE_CASHIER_AND_OPEN_PRIVACY_POLICY;
                break;
            case "logout":
                eventToUse = CLOSE_CASHIER_EVENTS.APP_CLOSE_CASHIER_AND_LOGOUT;
                break;
            case "support":
            case "chatWithUs":
                eventToUse =
                    CLOSE_CASHIER_EVENTS.APP_CLOSE_CASHIER_AND_OPEN_SUPPORT;
                break;
            default:
                eventToUse = this.appCloseCashier ?? null;
        }
        this._cashierService.closeCashier(eventToUse);
    }
}
