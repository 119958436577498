<div class="p-2">
    <h6 class="font-family-bold">Storing your card details</h6>
    <p>
        You must consent for us to store your card details for future use. We
        can't process your payment until you agree to your details being stored.
        This is subject to, and in compliance with, our
        <a href="javascript:void(0)" appCloseCashier="privacy">Privacy Policy</a
        >.
    </p>

    <section>
        <p class="heading font-family-bold">
            How long will my card details be stored?
        </p>
        <p>They will be stored until you change or remove them.</p>
    </section>

    <section>
        <p class="heading font-family-bold">
            How will my stored card details be used?
        </p>

        <p class="m-0">They will be used to:</p>

        <ul class="list-unstyled">
            <li><p>- Process payments</p></li>
            <li><p>- To verify your account</p></li>
            <li><p>- Comply with our legal and regulatory obligations</p></li>
            <li><p>- Help us prevent and detect fraud and crime.</p></li>
        </ul>
    </section>

    <section>
        <p class="heading font-family-bold">Will these terms change?</p>
        <p>
            Some changes will follow the process in our General Terms &
            Conditions (which you accepted when creating your account). If we
            need to make other changes to how we use your stored card details,
            we are required to get your agreement.
        </p>
    </section>

    <app-cashier-button appButtonBack>Back</app-cashier-button>
</div>
