<div class="container-fluid border rounded p-2 info-container">
    <header
        class="row my-1 no-gutters"
        #infoBoxHeader
        [hidden]="infoBoxHeader.firstElementChild.childElementCount === 0"
        (click)="toggleCollapse()"
        [ngClass]="{ 'cta  user-select-none': collapsible }"
    >
        <div class="col">
            <ng-content select="[slot=header]"></ng-content>
        </div>
        <span
            [ngSwitch]="collapsed"
            *ngIf="collapsible"
            class="align-items-center d-flex"
        >
            <span
                *ngSwitchCase="true"
                cashierIcon="chevron_down"
                color="#222222"
                width="16"
            ></span>
            <span
                *ngSwitchDefault
                cashierIcon="chevron_up"
                color="#222222"
                width="16"
            ></span>
        </span>
    </header>
    <div class="row no-gutters">
        <div
            class="col-auto p-0 mr-2 align-items-center d-flex"
            *ngIf="icon !== 'none' && icon !== null"
        >
            <ng-container
                *ngTemplateOutlet="
                    icon === 'warning' ? tplWarningIcon : tplInfoIcon
                "
            ></ng-container>
        </div>
        <div
            class="col"
            [ngClass]="contentClass"
            [hidden]="collapsible && collapsed"
        >
            <ng-content></ng-content>
        </div>
    </div>
</div>

<ng-template #tplWarningIcon>
    <span cashierIcon="warning_outline"></span>
</ng-template>
<ng-template #tplInfoIcon>
    <span cashierIcon="info_outline"></span>
</ng-template>
