import { Injectable } from "@angular/core";
import { ScriptLoaderService } from "app/services/script-loader/script-loader.service";
import { BehaviorSubject } from "rxjs";
import { APP_SETTINGS } from "app/app.settings";

@Injectable({
    providedIn: "root"
})
export class PaypalService {
    public fastPayEnabled$ = new BehaviorSubject<boolean>(true);
    public fraudNetGUID: string;

    constructor(private readonly scriptLoader: ScriptLoaderService) {}

    /**
     * Checks the user's last FastPay Toggle State
     * If the player previously turned this off,
     * that value should be remembered for the next transaction
     */
    public get fastPayToggleLastState(): boolean {
        return true;
    }

    /**
     * Other formats include
     * @example 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
     * @param guidFormat
     * @private
     */
    private generateGUID(
        guidFormat = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx"
    ): string {
        return guidFormat.replace(/[xy]/g, (c) => {
            // tslint:disable-next-line:no-bitwise
            const randomNumber = (Math.random() * 16) | 0;
            // tslint:disable-next-line:no-bitwise
            const v = c === "x" ? randomNumber : (randomNumber & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    private loadFraudNetParams(): Promise<void> {
        return new Promise((resolve) => {
            const payPalFraudNetParamsID = "paypalFraudParamsID";
            if (this.scriptLoader.isAlreadyLoaded(payPalFraudNetParamsID)) {
                return resolve();
            }

            const payPalFraudNetParams = document.createElement("script");
            payPalFraudNetParams.type = "application/json";
            payPalFraudNetParams.id = payPalFraudNetParamsID;

            // This value is taken straight from the docs
            payPalFraudNetParams.setAttribute(
                "fncls",
                "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99"
            );

            this.fraudNetGUID = this.generateGUID();

            const scriptContent = {
                f: this.fraudNetGUID,
                s: APP_SETTINGS.PAYPAL.FRAUDNET.FLOW_ID
            };

            payPalFraudNetParams.innerHTML = JSON.stringify(
                scriptContent,
                undefined,
                2
            );
            document.querySelector("head").appendChild(payPalFraudNetParams);
            return resolve();
        });
    }

    /**
     * @see https://developer.paypal.com/docs/limited-release/fraudnet/integrate/
     * @public
     */
    public async loadFraudNet(): Promise<void> {
        await this.loadFraudNetParams().then(() => {
            this.scriptLoader.load(APP_SETTINGS.PAYPAL.FRAUDNET.BEACON_JS_URL);
        });
    }
}
