import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { RouterModule } from "@angular/router";
// custom
import { AccountTypeLogoComponent } from "app/components/account-type-logo/account-type-logo.component";
import { AccountNumberComponent } from "app/components/account-number/account-number.component";
import { CashierButtonComponent } from "app/components/cashier-button/cashier-button.component";
import { CashierHeaderComponent } from "app/components/cashier-header/cashier-header.component";
import { CashierButtonCloseComponent } from "app/components/cashier-button-close/cashier-button-close.component";
import { BrowserModule } from "@angular/platform-browser";
import { AmountButtonsComponent } from "app/components/amount-buttons/amount-buttons.component";
import { ButtonBackComponent } from "app/components/button-back/button-back.component";
import { CardComponent } from "app/components/card/card.component";
import { AccountTypeNameComponent } from "app/components/account-type-name/account-type-name.component";
import { CashierLayoutComponent } from "app/components/cashier-layout/cashier-layout.component";
import { PlayerCurrencyPipe } from "app/pipes/player-currency/player-currency.pipe";
import { ListItemComponent } from "app/components/list-item/list-item.component";
import { LoaderComponent } from "app/components/loader/loader.component";
import { FormFieldComponent } from "app/components/form-field/form-field.component";
import { DepositFormComponent } from "app/components/deposit-form/deposit-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import { GoBackDirective } from "app/directives/go-back/go-back.directive";
import { CloseCashierDirective } from "app/directives/close-cashier/close-cashier.directive";
import { ResponseHandlerComponent } from "app/components/response-handler/response-handler.component";
import { WithdrawalAllocationComponent } from "app/components/withdrawal-allocation/withdrawal-allocation.component";
import { InfoPanelComponent } from "app/components/info-panel/info-panel.component";
import { FormDropdownComponent } from "app/components/form-dropdown/form-dropdown.component";
import { CountdownPipe } from "app/pipes/countdown/countdown.pipe";
import { PromoFormComponent } from "app/components/promo-form/promo-form.component";
import { NuveiCDEFormComponent } from "app/components/nuvei-cde-form/nuvei-cde-form.component";
import { ChatWithUsDirective } from "app/directives/chat-with-us/chat-with-us.directive";
import { CVVFormComponent } from "app/components/cvv-form/cvv-form.component";
import { NuveiCVVFormComponent } from "app/components/cvv-form/nuvei-cvv-form/nuvei-cvv-form.component";
import { BonusesComponent } from "app/components/bonuses/bonuses.component";
import { ErrorComponent } from "app/components/error/error.component";
import { UiModule } from "@sbmedia/core-ui";
import { AffordabilityDepositErrorComponent } from "app/components/affordability-deposit-error/affordability-deposit-error.component";
import { InsolvencyAcknowledgementComponent } from "app/components/insolvency-acknowledgement/insolvency-acknowledgement.component";
import { DepositRangeLabelComponent } from "app/components/deposit-range-label/deposit-range-label.component";
import { PaypalToggleFastpayComponent } from "app/components/paypal-toggle-fastpay/paypal-toggle-fastpay.component";
import { PaymentMethodDropdownComponent } from "app/components/payment-method-dropdown/payment-method-dropdown.component";
import { InfoComponent } from "app/components/info/info.component";
import { DepositBlockedComponent } from "app/components/deposit-blocked/deposit-blocked.component";
import { CashierIconDirective } from "app/directives/cashier-icon/cashier-icon.directive";

const Components = [
    AccountNumberComponent,
    AccountTypeLogoComponent,
    AccountTypeNameComponent,
    AffordabilityDepositErrorComponent,
    AmountButtonsComponent,
    BonusesComponent,
    ButtonBackComponent,
    CardComponent,
    CashierButtonComponent,
    CashierButtonCloseComponent,
    CashierHeaderComponent,
    CashierLayoutComponent,
    CVVFormComponent,
    FormDropdownComponent,
    FormFieldComponent,
    DepositBlockedComponent,
    DepositFormComponent,
    DepositRangeLabelComponent,
    ErrorComponent,
    InfoComponent,
    InfoPanelComponent,
    InsolvencyAcknowledgementComponent,
    LoaderComponent,
    ListItemComponent,
    PaymentMethodDropdownComponent,
    PromoFormComponent,
    NuveiCDEFormComponent,
    NuveiCVVFormComponent,
    PaypalToggleFastpayComponent,
    ResponseHandlerComponent,
    WithdrawalAllocationComponent
];

const Pipes = [CountdownPipe, PlayerCurrencyPipe];

const Directives = [
    ChatWithUsDirective,
    CloseCashierDirective,
    GoBackDirective,
    CashierIconDirective
];

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        UiModule
    ],
    declarations: [...Components, ...Directives, ...Pipes],
    exports: [
        BrowserModule,
        CommonModule,
        RouterModule,
        UiModule,
        ...Components,
        ...Directives,
        ...Pipes
    ],
    providers: [CurrencyPipe]
})
export class SharedModule {}
