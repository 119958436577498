<sbmedia-response-type
    [size]="'medium'"
    [type]="'failure'"
></sbmedia-response-type>

<div class="mt-3 d-flex align-items-center flex-column error">
    <div class="text-center">
        <h5 class="text-danger text-center title">{{ title }}</h5>
        <p class="my-3 description">{{ description }}</p>
    </div>
</div>

<ng-container *ngFor="let option of recoveryOptions; let first = first">
    <app-list-item
        class="font-family-bold"
        size="large"
        [ngClass]="{ highlight: first }"
        [routerLink]="[option.routerLink]"
        (click)="option.closeEvent ? closeCashier(option.closeEvent) : null"
    >
        <span>{{ option.title }}</span>
    </app-list-item>
</ng-container>
