const environment = {
    production: true,
    BUILD: "production",
    BACKEND_API: "https://api.dagacube.net",
    COIN_API: "https://coin-api.dagacube.net",
    CASHIER_SERVICE_BASE_URL: "https://cashier-api.dagacube.net/cashier-service"
};

if (module) {
    module.exports = { environment: environment };
}
