<main
    class="rounded-lg bg-form overflow-hidden"
    [ngClass]="{
        'pt-3': contentHeader.children.length === 0,
        'pb-2': headerYMargin === 'small',
        'pb-3': headerYMargin !== 'small'
    }"
>
    <div
        #contentHeader
        id="content-header"
        [hidden]="
            contentHeader.children.length === 0 || headerHidden || resolving
        "
        class="border-bottom {{ horizontalPaddingClass }}"
        [ngClass]="{
            'mb-2': headerYMargin === 'small',
            'mb-3': headerYMargin === 'normal',
            'mx-2': headerXMargin === 'small'
        }"
    >
        <ng-content select="[slot=header]"></ng-content>
    </div>
    <div
        class="{{ horizontalPaddingClass }}"
        [ngClass]="{ 'mt-3': headerHidden }"
    >
        <ng-content *ngIf="!resolving; else loader"></ng-content>
    </div>
</main>

<ng-template #loader>
    <app-loader
        class="d-block"
        [ngClass]="{
            'mt-3': headerYMargin !== 'small',
            'mt-2': headerYMargin === 'small'
        }"
    ></app-loader>
</ng-template>
