<sbmedia-response-type
    [size]="'medium'"
    [type]="'failure'"
></sbmedia-response-type>

<div class="mt-3 d-flex align-items-center flex-column">
    <div class="text-center">
        <h5 class="text-danger">{{ title }}</h5>
    </div>

    <div *ngIf="description; else tplDefaultDescription">
        <p [innerHTML]="description"></p>
    </div>
</div>

<div>
    <p>Here's what you can do:</p>
    <ng-container
        *ngFor="let option of recoveryOptions | async; let first = first"
    >
        <ng-template #tplOptionTitle>{{ option.title }}</ng-template>

        <ng-container *ngIf="!option.templateRef">
            <app-list-item
                [routerLink]="[option.routerLink]"
                [queryParams]="option.queryParams || null"
                [ngClass]="{ highlight: first }"
                class="font-family-bold"
                size="large"
            >
                <ng-container *ngTemplateOutlet="tplOptionTitle"></ng-container>
            </app-list-item>
        </ng-container>
        <ng-container
            *ngTemplateOutlet="option.templateRef ? option.templateRef : null"
        ></ng-container>
    </ng-container>
</div>

<a
    class="mt-3 d-block text-center text-secondary"
    [routerLink]="[depositPageURL]"
    >Start Again</a
>

<ng-template #tplDefaultDescription>
    <p>
        Sorry, we weren't able to take your deposit. Please try again or select
        an alternative payment method.
    </p>
</ng-template>

<ng-template #tplDepositUsingAlternateMethod>
    <app-list-item
        class="font-family-bold"
        size="large"
        (click)="selectAccount(this.alternateAccount)"
    >
        <span class="mr-2">Deposit using</span>
        <ng-container *ngIf="this.alternateAccount as account">
            <app-account-type-logo
                [code]="account?.accountTypeCode"
                size="compact"
                class="mr-2"
            ></app-account-type-logo>
            <app-account-number
                [account]="account"
                [showExpiry]="false"
            ></app-account-number>
        </ng-container>
    </app-list-item>
</ng-template>
