import { Component } from "@angular/core";
import { paths } from "app/paths";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { BehaviorSubject } from "rxjs";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";

@Component({
    selector: "app-footer-links",
    templateUrl: "./footer-links.component.html",
    styleUrls: ["./footer-links.component.scss"]
})
export class FooterLinksComponent {
    public allowedLinks = [];

    /**
     * Can turn off footer links by using this route data,
     * allowFooterLinks = false
     */
    public routeEnabled$ = new BehaviorSubject(true);

    /**
     * Footer links can be turned on or off via the feature toggle
     * HOWEVER, for WebView, it is always turned on
     */
    public get enabled(): boolean {
        return (
            this._playerSessionService.isWebView ||
            this._featureToggleService.getConfigValue(
                SupportedFeatures.CASHIER_FOOTER
            )
        );
    }

    private withdrawURL = `/${paths.BASE}/${paths.WITHDRAW}`;

    public constructor(
        private _activatedRoute: ActivatedRoute,
        private readonly _playerSessionService: PlayerSessionService,
        private _featureToggleService: FeatureToggleService,
        private readonly paymentMethodsService: PaymentMethodsService,
        private _router: Router
    ) {
        this._router.events.subscribe((event) => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.recalculateLinks(event);
        });

        /**
         * Listens for change events to the footer links
         * Allows this component to be turned off via a route config
         * @file app-routing.module.ts
         */
        this._activatedRoute.url.subscribe(() => {
            this.routeEnabled$.next(
                this._activatedRoute.snapshot.firstChild?.data
                    ?.allowFooterLinks ?? true
            );
        });
    }

    private isWithdrawPage(): boolean {
        return document.location.pathname.indexOf(this.withdrawURL) === 0;
    }

    /**
     * Recalculate what links to show based off the current url
     * Also evaluates the extra conditions for which a link should show,
     * e.g. Withdraw link should
     * @param navigationEnd
     * @private
     */
    private async recalculateLinks(navigationEnd: NavigationEnd) {
        if (this._router.url.indexOf(`/${paths.ERROR}`) !== -1) {
            this.allowedLinks = [];
            return;
        }

        let allLinks = [
            {
                text: "Deposit",
                path: `/${paths.BASE}/${paths.DEPOSIT}`,
                enabled: false,
                otherConditions: [
                    await this.paymentMethodsService.hasActivePaymentMethod$,
                    this._router.url !==
                        `/${paths.BASE}/${paths.ADD_ACCOUNT}/${paths.RESPONSE}`
                ]
            },
            {
                text: "Withdraw",
                path: this.withdrawURL,
                enabled: true,
                otherConditions: []
            },
            {
                text: "Manage My Limits",
                enabled: false,
                path: `/${paths.BASE}/${paths.DEPOSIT_LIMITS}`,
                otherConditions: [!this.isWithdrawPage()]
            }
        ];

        /**
         * When the Cashier is rendered using the Webview,
         * the only link that should be available is the "Manage My Limits",
         * However, existing checks are still in place, example:
         * The 'Manage My Limits' footer link will not show on the Withdraw Page
         */
        if (this._playerSessionService.isWebView) {
            allLinks = allLinks.filter((i) => i.text === "Manage My Limits");
        }

        allLinks.map((link) => {
            const allowedByURL =
                navigationEnd.url.indexOf(link.path) !== 0 &&
                navigationEnd.urlAfterRedirects.indexOf(link.path) !== 0;

            let otherConditions = true;

            if (link.otherConditions && link.otherConditions.length !== 0) {
                otherConditions = link.otherConditions.reduce(
                    (result, condition) => {
                        return result && condition;
                    }
                );
            }

            link.enabled = allowedByURL && otherConditions;
        });

        this.allowedLinks = allLinks.filter((link) => link.enabled);
    }
}
