<app-cashier-layout headerYMargin="small">
    <sbmedia-response-type
        [size]="'medium'"
        [type]="'failure'"
    ></sbmedia-response-type>
    <div class="mt-3 d-flex align-items-center flex-column px-2">
        <div class="text-center">
            <h5 class="text-danger title">{{ title }}</h5>
        </div>

        <p class="description" [innerHTML]="description"></p>
    </div>

    <app-cashier-button class="my-3" appCloseCashier="logout"
        >Logout</app-cashier-button
    >

    <app-cashier-button class="my-3" theme="primary-outline" appChatWithUs
        >Contact Support</app-cashier-button
    >
</app-cashier-layout>
