import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output
} from "@angular/core";

interface CTAEvent {
    action: "delete" | "view";
}

@Component({
    selector: "app-list-item",
    templateUrl: "./list-item.component.html",
    styleUrls: ["./list-item.component.scss"]
})
export class ListItemComponent {
    @HostBinding("class") class =
        "card position-relative d-flex align-items-center flex-row";
    @Input() showDelete = false;
    @Input() @HostBinding("class.has-cta") ctaVisible = true;
    @Input() @HostBinding("class.disabled") disabled = false;
    @Output() onCTAClick = new EventEmitter<CTAEvent>();
    @HostBinding("size") size: "compressed" | "compact" | "large" = "compact";
    @HostListener("click") clickEvent(): void {
        /**
         * When the CTA is visible,
         * only allow events to be triggered from that portion
         */
        if (this.ctaVisible) {
            return;
        }
        this.triggerCTA();
    }

    public triggerCTA(): void {
        this.onCTAClick.emit({
            action: this.showDelete ? "delete" : "view"
        });
    }
}
