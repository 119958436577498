<p *ngIf="playerTUIsAmber">
    As a responsible operator, we've applied a maximum Net Deposit Limit of
    {{ limitPeriodData.lockedMaxValue | playerCurrency }}
    <br />
    <br />
    Want to increase this?
    <a href="javascript:void(0)" appCloseCashier="survey"
        >Please provide us more information</a
    >
    <br />
    <br />
    If you want to talk about it,
    <a href="javascript:void(0)" appCloseCashier="chatWithUs">chat with us.</a>
</p>
