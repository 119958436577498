<header class="font-family-bold p-3 position-relative">
    <h5 class="mb-2">Cashier</h5>
    <app-cashier-button-close></app-cashier-button-close>
</header>
<div
    class="bg-light title text-uppercase p-2 font-italic font-family-bold"
    *ngIf="title"
>
    {{ title }}
</div>
