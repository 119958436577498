import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { EnvironmentService } from "app/services/environment/environment.service";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { GetWithdrawDetailResponse } from "app/interfaces/withdraw.interface";
import { APP_COOKIE_KEYS, CLOSE_CASHIER_EVENTS } from "app/app.settings";
import { CoinStatus } from "app/enums/coin-status.enum";
import { PlayerSessionService } from "app/services/player-session/player-session.service";

@Injectable({
    providedIn: "root"
})
export class CashierService {
    public CASHIER_API_BACKEND = this.environmentService.COIN_API;
    public selectedAccount: CoinAccount;

    constructor(
        public http: HttpClient,
        private readonly environmentService: EnvironmentService,
        public cookieService: CookieService,
        private readonly _playerSessionService: PlayerSessionService
    ) {}

    /**
     * @param coinResponse
     * @param safeChargeResponse
     */
    public static isErrorResponse(coinResponse, safeChargeResponse): boolean {
        return (
            (!!coinResponse && +coinResponse?.Status !== +CoinStatus.Success) ||
            (!!safeChargeResponse && safeChargeResponse.result !== "APPROVED")
        );
    }

    public getDefaultCOINParams(
        func: string,
        extraParams: Record<string, string> = {}
    ): string {
        const params = new URLSearchParams();
        params.set("Function", func);
        params.set("SkinID", this._playerSessionService?.skinID?.toString());
        params.set("Source", "Cas");
        params.set("Device", this._playerSessionService.device);
        params.set("PlayerID", this._playerSessionService.playerID?.toString());
        params.set("ssid", this._playerSessionService.sessionID);
        params.set("callback", "none");
        if (this._playerSessionService.customerID) {
            params.set(
                "CustomerID",
                this._playerSessionService.customerID.toString()
            );
        }

        Object.keys(extraParams).forEach((key) => {
            params.set(key, extraParams[key]);
        });

        return `?${params.toString()}`;
    }

    public getWithdrawDetail(): Observable<GetWithdrawDetailResponse> {
        const params = this.getDefaultCOINParams("GetWithdrawDetail");
        return this.http.get<GetWithdrawDetailResponse>(
            this.CASHIER_API_BACKEND + params
        );
    }

    /**
     * Clear all cookies that we explicitly set.
     * We cannot blanket delete cookies since we share a domain with the parent site
     * and can't delete their cookies. We also don't know what cookies adobe analytics
     * and other tracking scripts set. We also can't use a cookie path of /csi
     * and just delete those, as bella will not be operating under the /csi path for the time being.
     *
     * NOTE: some cookies are EXCLUDED from the delete as we need to retain them regardless
     */
    private clearCookies(): void {
        const cookieNamesToExclude = [
            APP_COOKIE_KEYS.PAYPAL_FASTPAY_TOGGLE_LAST_STATE
        ];

        Object.values(APP_COOKIE_KEYS).forEach((cookieName) => {
            if (!cookieNamesToExclude.includes(cookieName)) {
                this.cookieService.delete(cookieName);
            }
        });
    }

    public closeCashier(event?: string): void {
        const _event = event ? event : CLOSE_CASHIER_EVENTS.APP_CLOSE_CASHIER;
        this.clearCookies();
        window.parent.postMessage({ source: "", cashierEvent: _event }, "*");
    }
}
