import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-cashier-header",
    templateUrl: "./cashier-header.component.html",
    styleUrls: ["./cashier-header.component.scss"]
})
export class CashierHeaderComponent implements OnInit {
    public title: string;

    public constructor(private _activatedRoute: ActivatedRoute) {}

    public ngOnInit(): void {
        this._activatedRoute.url.subscribe(() => {
            this.title = this._activatedRoute.snapshot.firstChild?.data?.title;
        });
    }
}
