<button
    [attr.theme]="theme"
    [attr.size]="size"
    class="w-100 btn"
    [ngClass]="{
        'font-family-bold': fontWeight === 'bold',
        '': fontWeight === 'normal'
    }"
    [disabled]="disabled"
>
    <ng-content *ngIf="!text; else tplText"></ng-content>
    <ng-template #tplText>{{ text }}</ng-template>
</button>
