import { CurrencyISOCode } from "app/enums/currency-iso-code";
import { CoinAccount } from "./accounts.interface";

export interface GetPlayerResponse {
    playerId: number;
    skinId: number;
    customerId: number;
    sessionId: string;
    firstName: string;
    lastName: string;
    maxAccounts: number;
    activeAccounts: number;
    accounts: CoinAccount;
    currencyCode: CurrencyISOCode;
    transunionStatus: TUStatus;
    affiliateId: number;
    netRefererAffiliateId: number;
    gclId: string;
    gateway: NuveiGateway;
    balance: {
        total: number;
        cash: number;
        bonus: number;
        bonusWins: number;
    };
    deposit: {
        disabled: boolean;
        depositCount: number;
        requiresPopfAck: boolean;
        max: number;
    };
    withdraw: {
        min: number;
        max: number;
        disabled: boolean;
        withdrawDisabledMessage: string;
    };
}

export enum TUStatus {
    UNKNOWN = "UNKNOWN",
    GREEN = "GREEN",
    AMBER = "AMBER",
    RED = "RED"
}

export interface NuveiGateway {
    nuvei: {
        env: string;
        merchantId: string;
        merchantSiteId: string;
    };
}

export const enum PctDepositLimitPeriodTypes {
    DAY = "Day",
    WEEK = "Week",
    MONTH = "Month"
}
