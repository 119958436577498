<ng-container *ngIf="isVisible">
    <p>
        We are currently unable to accept PayPal deposits if a Mastercard is
        your PayPal account funding method. Please use a Visa card or bank
        account (via PayPal) or select another method from our available options
    </p>
    <p></p>
    <p
        *ngIf="displayUnsupportedBonusesWarning"
        class="font-italic"
        style="font-weight: 200"
    >
        Deposits made by PayPal are
        <span style="font-weight: bold">not</span> eligible for our welcome
        offer.
    </p>
</ng-container>
