import { Injectable, isDevMode } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PlayerSessionService } from "app/services/player-session/player-session.service";

type Severity = "info" | "warn" | "error" | "log";

@Injectable({
    providedIn: "root"
})
export class LoggerService {
    private _correlationID: string;
    private get correlationID(): string {
        return (
            this._correlationID ?? (this._correlationID = this.generateGUID())
        );
    }

    /**
     * Other formats include
     * @example 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'
     * @param guidFormat
     * @private
     */
    private generateGUID(
        guidFormat = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    ): string {
        return guidFormat.replace(/[xy]/g, (c) => {
            // tslint:disable-next-line:no-bitwise
            const randomNumber = (Math.random() * 16) | 0;
            // tslint:disable-next-line:no-bitwise
            const v = c === "x" ? randomNumber : (randomNumber & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    constructor(
        private readonly http: HttpClient,
        private readonly _playerSessionService: PlayerSessionService
    ) {}

    public info(message: string, data: object = {}): void {
        this.log(message, "info", data);
    }

    public warn(message: string, data: object = {}): void {
        this.log(message, "warn", data);
    }

    public error(message: string, data: object = {}): void {
        this.log(message, "error", data);
    }

    public log(
        message: string,
        severity: Severity = "log",
        data: object = {}
    ): void {
        const description =
            data["description"] ??
            data["StatusMsg"] ??
            data["errorDescription"] ??
            null;
        const logBody = {
            message: message,
            description: description,
            severity: severity,
            data: data ?? null,
            correlationID: this.correlationID,
            ssid: this._playerSessionService.sessionID,
            hostname: document.location.hostname,
            customerID: this._playerSessionService.customerID,
            playerID: this._playerSessionService.playerID,
            username: this._playerSessionService.username,
            skinID: this._playerSessionService.skinID
        };

        /**
         * Logger Endpoint does not exist when running locally
         */
        if (isDevMode()) {
            console.info("Logger Service:", logBody);
            return;
        }

        this.http.post("/csi/log", logBody).subscribe(
            () => {},
            (error) => {
                console.warn("Logger Service: error", error);
            }
        );
    }
}
