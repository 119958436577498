import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountTypeCode } from "app/enums/account-type-code.enum";
import { CoinAccount } from "app/interfaces/accounts.interface";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-add-account-ewallet",
    templateUrl: "./add-account-ewallet.component.html"
})
export class AddAccountEWalletComponent implements OnDestroy {
    public account: CoinAccount;
    public AccountTypeCodeEnum = AccountTypeCode;
    private subs = new Subscription();

    public readonly promosEnabled: boolean =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.DEPOSIT_PROMOS
        );
    public readonly bonusesEnabled: boolean =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.DEPOSIT_BONUSES
        );

    constructor(
        private featureToggleService: FeatureToggleService,
        private readonly router: Router,
        private paymentMethodsService: PaymentMethodsService,
        private _activatedRoute: ActivatedRoute
    ) {
        this.listenForSelectedAccount();
        this.getRoutedata();
    }

    /**
     * We need to listen for selected account changes
     * Particularly by Clicking pay with apple pay button
     * When adding an account
     */
    private listenForSelectedAccount(): void {
        this.subs.add(
            this.paymentMethodsService.selected$.subscribe((account) => {
                this.account = account;
            })
        );
    }

    private getRoutedata(): void {
        const currentNavigation = this.router.getCurrentNavigation();

        this.subs.add(
            this.paymentMethodsService.accountTypes$.subscribe((accTypes) => {
                const accType = accTypes.find(
                    (a) =>
                        a.accountTypeCode ==
                        this._activatedRoute.snapshot.data?.type
                );

                // safety check just in case accType is null.
                // This shouldn't really happen though
                const smartTiles: number[] =
                    accType != null ? accType.smartTiles : [];
                const minDepositAmount =
                    accType != null ? accType.minDepositAmount : null;

                this.account = {
                    accountId: 0,
                    promos: currentNavigation?.extras.state?.accountData
                        ?.promos,
                    accountTypeCode: this._activatedRoute.snapshot.data?.type,
                    description: undefined,
                    lastSuccessfulDepositDate: undefined,
                    minDepositAmount: minDepositAmount,
                    smartTiles: smartTiles
                };
                this.paymentMethodsService.select(this.account);
            })
        );
    }

    public get hasAvalableBonuses(): boolean {
        return this.paymentMethodsService.currentSelected?.promos > []
            ? true
            : false;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
