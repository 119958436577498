<app-info-panel
    icon="none"
    class="d-flex mb-4"
    [collapsible]="true"
    contentClass="p-0"
    theme="info"
    #panel
    *ngIf="limitsResponse && playerTUIsAmber"
>
    <div slot="header" class="d-flex align-items-center px-2 py-1">
        <span cashierIcon="shield_pound" height="16"></span>
        <span class="mx-3 flex-grow-1">Deposit Limits applied.</span>
    </div>
    <div class="small mt-3">
        <p>
            You have a 30 day net deposit Deposit Limit of
            {{ limitsResponse.LockedDepositLimitPerMonth | playerCurrency }}.
        </p>
        <p>Want to raise it? Please provide us with more information.</p>
        <div class="d-flex flex-column mt-3 mb-1">
            <app-cashier-button
                theme="secondary"
                size="none"
                appCloseCashier="survey"
                >Provide more info</app-cashier-button
            >
            <app-cashier-button
                theme="secondary-outline"
                size="none"
                class="mt-3"
                appCloseCashier="chatWithUs"
                >Chat with us</app-cashier-button
            >
        </div>
    </div>
</app-info-panel>
