import { Component, OnInit } from "@angular/core";
import { ResponseHandlerComponent } from "app/components/response-handler/response-handler.component";
import { paths } from "app/paths";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { AddAccountResponse } from "app/interfaces/tokenize-card.interface";
import { CoinAccount } from "app/interfaces/accounts.interface";

@Component({
    selector: "app-add-account-response",
    templateUrl: "./add-account-response.component.html"
})
export class AddAccountResponseComponent
    extends ResponseHandlerComponent
    implements OnInit
{
    public returnURL = `/${paths.BASE}/${paths.ADD_ACCOUNT}`;
    public account$: Observable<AddAccountResponse | CoinAccount>;

    public ngOnInit(): void {
        super.ngOnInit();
        this.fetchAccountAddedData();
    }

    /**
     * Only for Successful Tokenization calls
     * fetches the Added Account Data to display to the user
     * @private
     */
    private fetchAccountAddedData(): void {
        if (
            this.isError ||
            !this.cashierServiceResponse ||
            !this.cashierServiceResponse["accountId"]
        ) {
            return;
        }

        // TODO: Remove this backward support once COIN and Cashier Frontend are deployed
        const accountsService = this._injector.get(PaymentMethodsService);
        const accountIDAdded = this.cashierServiceResponse["accountId"];
        this.account$ = accountsService.get().pipe(
            map((resp) => {
                const accountFound = resp.accounts.find(
                    (acc) => acc.accountId === accountIDAdded
                );
                if (accountFound === undefined) {
                    this.loggerService.error(
                        "Could not find recently added payment method",
                        {
                            ...resp,
                            ...{
                                recentlyAddedID: accountIDAdded
                            }
                        }
                    );
                }
                return accountFound;
            })
        );
    }
}
