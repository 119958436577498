<app-cashier-layout>
    <div class="px-1">
        <ng-container *ngIf="showPendingMessage">
            <ng-container *ngTemplateOutlet="tplPendingResponse"></ng-container>
        </ng-container>
        <ng-container *ngIf="!showPendingMessage && isError">
            <ng-container *ngTemplateOutlet="tplErrorResponse"></ng-container>
        </ng-container>
        <ng-container *ngIf="!showPendingMessage && !isError">
            <ng-container *ngTemplateOutlet="tplSuccessResponse"></ng-container>
        </ng-container>
    </div>
</app-cashier-layout>

<ng-template #tplSuccessResponse>
    <app-deposit-response-success
        [transactionAmount]="transactionAmount"
        [cashierServiceResponse]="cashierServiceResponse"
    ></app-deposit-response-success>
</ng-template>

<ng-template #tplErrorResponse>
    <app-deposit-response-error
        [cashierServiceResponse]="cashierServiceResponse"
        [safeChargeResponse]="safeChargeResponse"
        [transactionAmount]="transactionAmount"
    ></app-deposit-response-error>
</ng-template>

<ng-template #tplPendingResponse>
    <ng-container>
        <app-info
            title="We're processing your deposit"
            description="Please check your account in a few minutes."
            [optionsTitleEnabled]="false"
        >
            <div slot="recoveryOptions">
                <app-cashier-button class="my-3" appCloseCashier
                    >Close</app-cashier-button
                >
                <app-cashier-button
                    class="my-3"
                    theme="primary-outline"
                    appChatWithUs
                    >Chat with us</app-cashier-button
                >
            </div>
        </app-info>
    </ng-container>
</ng-template>
