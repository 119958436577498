import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Injectable, OnDestroy } from "@angular/core";
import { DepositService } from "app/services/deposit/deposit.service";
import { PlayerCurrencyPipe } from "app/pipes/player-currency/player-currency.pipe";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { map } from "rxjs/operators";
import { Subscription } from "rxjs";
import { CashierApiService } from "app/services/cashier-api.service";

@Injectable({
    providedIn: "root"
})
export class DepositValidatorService implements OnDestroy {
    private minDepositAmount: number;
    private subs = new Subscription();

    constructor(
        private cashierAPIService: CashierApiService,
        private _depositService: DepositService,
        private _playerCurrencyPipe: PlayerCurrencyPipe,
        private _paymentMethodService: PaymentMethodsService
    ) {
        this.subs.add(
            this._paymentMethodService.selected$
                .pipe(map((acc: CoinAccount) => acc?.minDepositAmount))
                .subscribe((minDepositAmount) => {
                    this.minDepositAmount = minDepositAmount;
                })
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public validate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            /**
             * No Value,
             * nothing to validate
             */
            if (!control.value) {
                return null;
            }

            const depositAmount = +control.value;

            const breachedAffordabilityLimit =
                this.checkAgainstAffordabilityLimits(depositAmount);
            if (breachedAffordabilityLimit !== null) {
                return breachedAffordabilityLimit;
            }

            const minMaxErrors = this.checkMinMaxRange(
                depositAmount,
                this.minDepositAmount
            );
            if (minMaxErrors !== null) {
                return minMaxErrors;
            }

            const breachedLimitErrors =
                this.checkRemainingLimits(depositAmount);
            if (breachedLimitErrors !== null) {
                return breachedLimitErrors;
            }

            return null;
        };
    }

    /**
     * Checks if the deposit amount lies within the min and max range allowable
     * @param depositAmount
     * @private
     */
    private checkMinMaxRange(
        depositAmount: number,
        minAllowed: number
    ): ValidationErrors | null {
        const maxAllowed = this._depositService.maxAllowed;

        /**
         * No data exists for min / max
         * Cannot validate against it
         */
        if (minAllowed === null || maxAllowed === null || maxAllowed === 0) {
            return null;
        }

        /**
         * If the deposit amount is within the allowable range,
         * bail, no issues here
         */
        if (depositAmount <= maxAllowed && depositAmount >= minAllowed) {
            return null;
        }

        const message =
            maxAllowed !== minAllowed
                ? `Enter a value between ${this._playerCurrencyPipe.transform(
                      minAllowed
                  )} and ${this._playerCurrencyPipe.transform(maxAllowed)}`
                : `You can only deposit ${this._playerCurrencyPipe.transform(
                      minAllowed
                  )}`;
        return {
            minMax: {
                message: message,
                min: minAllowed,
                max: maxAllowed,
                depositAmount: depositAmount
            }
        };
    }

    /**
     * Checks if the deposit amount breaches any of the players limits
     * @param depositAmount
     * @private
     */
    private checkRemainingLimits(
        depositAmount: number
    ): ValidationErrors | null {
        const defaultValidationResponse = {
            limits: {
                message: "You have reached your deposit limit."
            }
        };

        /**
         * When the property `AvailableToDeposit` is in the CoinStart response,
         * We don't check against daily, weekly and month values
         */
        if (this._depositService.hasAvailableToDeposit) {
            return this.cashierAPIService.playerData.deposit.max <
                this._depositService.minAllowed
                ? defaultValidationResponse
                : null;
        }

        let breachedPeriod;
        let availableForPeriod;

        const availableMonth = this.cashierAPIService.availableToDepositMonth;
        const availableWeek = this.cashierAPIService.availableToDepositWeek;
        const availableDay = this.cashierAPIService.availableToDepositDay;

        /**
         * If the player has no amount available to deposit...
         * they have reached their deposit limits
         */
        if (Math.max(...[availableDay, availableWeek, availableMonth]) === 0) {
            return defaultValidationResponse;
        }

        if (
            depositAmount > availableMonth &&
            !!this.cashierAPIService.availableToDepositMonth
        ) {
            breachedPeriod = "Monthly";
            availableForPeriod = availableMonth;
        } else if (
            depositAmount > availableWeek &&
            !!this.cashierAPIService.availableToDepositWeek
        ) {
            breachedPeriod = "Weekly";
            availableForPeriod = availableWeek;
        } else if (
            depositAmount > availableDay &&
            !!this.cashierAPIService.availableToDepositDay
        ) {
            breachedPeriod = "Daily";
            availableForPeriod = availableDay;
        }

        if (!breachedPeriod) {
            return null;
        }

        return {
            limits: {
                message: `The deposit value is more than your remaining ${breachedPeriod} limit`,
                period: breachedPeriod,
                available: availableForPeriod
            }
        };
    }

    private checkAgainstAffordabilityLimits(
        depositAmount
    ): ValidationErrors | null {
        const breachesAffordabilityLimit =
            this._depositService.breachesAffordabilityLimit(depositAmount);
        if (!breachesAffordabilityLimit) {
            return null;
        }

        return {
            affordabilityLimit: {
                message:
                    "This takes you over your current deposit limit. Try a lower amount."
            }
        };
    }
}
