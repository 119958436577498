<div class="row no-gutters">
    <div
        class="col-auto d-flex justify-content-center bg-success updated-notice mr-3"
        *ngIf="recentlyUpdated"
    >
        <span
            cashierIcon="check_circle"
            color="#fff"
            class="d-flex align-items-center"
        ></span>
    </div>
    <div class="col">
        <span class="small text-muted">{{ limit.label }} limit</span>
        <h6
            class="font-family-bold"
            [ngClass]="{ 'recently-updated-text': recentlyUpdated }"
        >
            <ng-container *ngIf="limit.value; else tplNotSetValueLabel">
                {{ limit.value | playerCurrency }}
            </ng-container>
        </h6>
        <ng-container
            *ngTemplateOutlet="limit.isPending ? pendingRibbon : null"
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="limit.requiresConfirm ? confirmRibbon : null"
        ></ng-container>
    </div>
    <div class="col-auto d-flex align-items-end">
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </div>
</div>

<ng-template #cta>
    <ng-container
        *ngTemplateOutlet="limit.isPending ? ctaCancel : null"
    ></ng-container>
    <ng-container
        *ngTemplateOutlet="limit.requiresConfirm ? ctaConfirm : null"
    ></ng-container>
    <ng-container
        *ngTemplateOutlet="
            !limit.requiresConfirm && !limit.isPending ? ctaGeneric : null
        "
    ></ng-container>
</ng-template>

<ng-template #ctaCancel>
    <app-cashier-button
        theme="primary-outline"
        (click)="cancelLimit()"
        onkeypress=""
        >Cancel</app-cashier-button
    >
</ng-template>

<ng-template #ctaConfirm>
    <app-cashier-button
        theme="primary"
        class="mr-2"
        (click)="confirmLimit()"
        onkeypress=""
        >Yes</app-cashier-button
    >
    <app-cashier-button
        theme="primary-outline"
        (click)="cancelLimit()"
        onkeypress=""
        >No</app-cashier-button
    >
</ng-template>

<ng-template #ctaGeneric>
    <app-cashier-button
        [theme]="!limit.value ? 'secondary' : 'secondary-outline'"
        [routerLink]="[limit.type]"
        [text]="getCTAText()"
    >
    </app-cashier-button>
</ng-template>

<ng-template #pendingRibbon>
    <div class="ribbon bg-warning d-inline-flex rounded-right py-2 pr-2">
        <span cashierIcon="clock" class="d-flex align-items-center mr-2"></span>
        <div>
            <span
                >{{ limit.pendingAmount | playerCurrency: false }} pending</span
            >
            <br />
            <span class="font-family-bold">{{
                limit.pendingTime | countdown | async
            }}</span>
        </div>
    </div>
</ng-template>

<ng-template #confirmRibbon>
    <div class="ribbon bg-success d-inline-flex rounded-right py-2 pr-2">
        <span
            cashierIcon="check_circle"
            class="d-flex align-items-center mr-2"
        ></span>
        <div>
            <span
                >{{
                    limit.pendingAmount | playerCurrency: false
                }}
                approved</span
            >
            <br />
            <span class="font-family-bold">Accept?</span>
        </div>
    </div>
</ng-template>

<ng-template #tplNotSetValueLabel>
    <ng-container
        *ngIf="playerTUIsAmber && type === 'monthly'; else defaultNotSetText"
    >
        <span class="mr-2">{{ limit.lockedMaxValue | playerCurrency }}</span>
        <span cashierIcon="shield_pound" height="16"></span>
    </ng-container>
    <ng-template #defaultNotSetText> Not Set </ng-template>
</ng-template>
