import { Directive, HostListener } from "@angular/core";
import { CashierService } from "app/services/cashier/cashier.service";
import { CLOSE_CASHIER_EVENTS } from "app/app.settings";

@Directive({
    selector: "[appChatWithUs]"
})
export class ChatWithUsDirective {
    constructor(private _cashierService: CashierService) {}

    @HostListener("click") closeCashier(): void {
        this._cashierService.closeCashier(
            CLOSE_CASHIER_EVENTS.APP_CLOSE_CASHIER_AND_OPEN_SUPPORT
        );
    }
}
