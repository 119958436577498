import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { DepositLimitsService } from "app/services/deposit-limits/deposit-limits.service";
import { DepositLimitsResponse } from "app/interfaces/deposit-limits.interface";

@Injectable({
    providedIn: "root"
})
export class DepositLimitsResolver implements Resolve<DepositLimitsResponse> {
    constructor(private readonly depositLimitsService: DepositLimitsService) {}

    resolve(): Observable<DepositLimitsResponse> {
        return this.depositLimitsService.get();
    }
}
