import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { paths } from "app/paths";
import { WithdrawService } from "app/services/withdraw/withdraw.service";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { CashierButtonComponent } from "app/components/cashier-button/cashier-button.component";
import { SkinService } from "app/services/skin/skin.service";

@Component({
    selector: "app-withdraw-confirm",
    templateUrl: "./withdraw-confirm.component.html",
    styleUrls: ["./withdraw-confirm.component.scss"]
})
export class WithdrawConfirmComponent implements OnInit {
    @ViewChild("btnSubmit") btnSubmit: CashierButtonComponent;
    public transactionAmount: number;
    public accountUsed: CoinAccount;
    public showWithdrawPreAllocation = this.featureToggleService.getConfigValue(
        SupportedFeatures.WITHDRAW_PRE_ALLOCATION
    );
    public allowWithdrawAccountSelection =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.WITHDRAW_ACCOUNT_SELECTION
        );
    public withdrawURL = `/${paths.BASE}/${paths.WITHDRAW}`;
    public isEPISkin: boolean;
    public hasBonus: boolean;
    public disableCashierButton: boolean;

    constructor(
        private featureToggleService: FeatureToggleService,
        private _router: Router,
        private _withdrawService: WithdrawService,
        private readonly skinService: SkinService
    ) {
        if (!this.hasRouteData()) {
            this._router.navigate([this.withdrawURL]);
        }
        this.isEPISkin = this.skinService.isEPI;
    }

    ngOnInit(): void {
        this.hasBonus = WithdrawService.hasForfeitedBonus();
        if (!this.hasBonus) {
            // We'd like the confirm button to be disabled, only when we have a checkbox
            this.disableCashierButton = false;
        } else {
            this.disableCashierButton = true;
        }
    }

    /**
     * Checks if this page has been provided with the necessary data
     * @private
     */
    private hasRouteData(): boolean {
        const currentNavigation = this._router.getCurrentNavigation();
        this.transactionAmount =
            currentNavigation?.extras?.state?.transactionAmount;
        this.accountUsed = currentNavigation?.extras?.state?.accountUsed;
        if (this.allowWithdrawAccountSelection) {
            return !!this.accountUsed && !!this.transactionAmount;
        } else {
            return !!this.transactionAmount;
        }
    }

    /**
     * Do the actual withdraw
     * Redirection is handled in a pipe
     */
    public confirmWithdraw(): void {
        this.disableCashierButton = true;
        this._withdrawService.withdraw(
            this.transactionAmount,
            this.accountUsed
        );
    }
}
