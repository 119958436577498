import { NgModule } from "@angular/core";
import { SharedModule } from "app/modules/shared.module";
import { AddAccountResponseComponent } from "./add-account-response.component";
import { AddAccountResponseErrorComponent } from "./add-account-response-error/add-account-response-error.component";
import { AddAccountResponseSuccessComponent } from "./add-account-response-success/add-account-response-success.component";

@NgModule({
    declarations: [
        AddAccountResponseComponent,
        AddAccountResponseSuccessComponent,
        AddAccountResponseErrorComponent
    ],
    imports: [SharedModule]
})
export class AddAccountResponseModule {}
