import { AbstractControl, ValidationErrors } from "@angular/forms";
import { DepositLimitPeriod } from "app/enums/deposit-limit-period";
import { DepositLimitFormatted } from "app/interfaces/deposit-limit-formatted";
import { PlayerCurrencyPipe } from "app/pipes/player-currency/player-currency.pipe";
import { Injectable } from "@angular/core";
import { DepositService } from "app/services/deposit/deposit.service";

@Injectable({
    providedIn: "root"
})
export class DepositLimitValidator {
    constructor(
        private readonly depositService: DepositService,
        private playerCurrencyPipe: PlayerCurrencyPipe
    ) {}

    /**
     * This validator can be used as a validator for:
     * 1. Daily Amount
     * 2. Weekly Amount
     * 3. Monthly Amount
     *
     * provided that you tell the method which period this validator is for
     * AND
     * you've provided the DepositLimitFormatted
     * @see deposit-limits.service.ts
     *
     * @param period
     * @param limitData
     */
    public validate(
        period: DepositLimitPeriod,
        limitData: DepositLimitFormatted
    ) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const parsedValue = +control.value;

            const periodIndex = Object.keys(limitData).indexOf(period);
            if (periodIndex === -1) {
                return null;
            }

            let validationResult: ValidationErrors = {
                depositLimit: {
                    message: null,
                    actual: parsedValue,
                    breachPeriod: null,
                    breachType: null,
                    breachPeriodValue: null
                }
            };

            if (parsedValue < this.depositService.minAllowed) {
                validationResult.depositLimit.message =
                    "Your limit is lower than the minimum deposit.";
                return validationResult;
            }

            validationResult = DepositLimitValidator.checkAgainstHigherPeriods(
                period,
                periodIndex,
                parsedValue,
                limitData,
                validationResult
            );
            validationResult = DepositLimitValidator.checkAgainstLowerPeriods(
                period,
                periodIndex,
                parsedValue,
                limitData,
                validationResult
            );
            validationResult = this.checkAgainstLockedValue(
                period,
                parsedValue,
                limitData,
                validationResult
            );

            // Silly check, but might as well
            // prevents the player from setting the same value for their limit
            if (parsedValue === limitData[period].value) {
                validationResult.depositLimit.message = `The current ${period} limit is already ${this.playerCurrencyPipe.transform(
                    limitData[period].value
                )}`;
                validationResult.depositLimit.breachPeriod = period;
                validationResult.depositLimit.breachPeriodValue =
                    limitData[period].value;
            }

            return validationResult.depositLimit.message
                ? validationResult
                : null;
        };
    }

    /**
     * Checks that the deposit limit is not greater than the system determine locked value
     * @internal
     * @param period
     * @param controlValue
     * @param limitData
     * @param validationResult
     * @private
     */
    private checkAgainstLockedValue(
        period,
        controlValue,
        limitData,
        validationResult
    ): ValidationErrors {
        /**
         * No locked data available,
         * bounce...
         */
        if (
            [null, undefined].indexOf(limitData[period].lockedMaxValue) !== -1
        ) {
            return validationResult;
        }

        if (controlValue <= limitData[period].lockedMaxValue) {
            return validationResult;
        }

        validationResult.depositLimit.message = `You've entered more than your pre-determined limit (${this.playerCurrencyPipe.transform(
            limitData[period].lockedMaxValue
        )}). Try a lower amount.`;
        validationResult.depositLimit.breachType = "lock";
        return validationResult;
    }

    /**
     * Checks the current periods deposit limit against the values of all lower periods
     * @internal
     * @param period
     * @param periodIndex
     * @param controlValue
     * @param limitData
     * @param validationResult
     * @private
     */
    private static checkAgainstLowerPeriods(
        period: DepositLimitPeriod,
        periodIndex: number,
        controlValue: number,
        limitData: DepositLimitFormatted,
        validationResult
    ): ValidationErrors {
        const lowerPeriods = Object.keys(limitData).slice(0, periodIndex);
        lowerPeriods.forEach((lowerPeriod) => {
            if (controlValue >= limitData[lowerPeriod].value) {
                return;
            }
            validationResult.depositLimit.message = `${period} limit cannot be lower than ${lowerPeriod} limit`;
            validationResult.depositLimit.breachPeriod = lowerPeriod;
            validationResult.depositLimit.breachPeriodValue =
                limitData[lowerPeriod].value;
        });
        return validationResult;
    }

    /**
     * Checks the current periods deposit limit against the values of all higher periods
     * @internal
     * @param period
     * @param periodIndex
     * @param controlValue
     * @param limitData
     * @param validationResult
     * @private
     */
    private static checkAgainstHigherPeriods(
        period: DepositLimitPeriod,
        periodIndex: number,
        controlValue: number,
        limitData: DepositLimitFormatted,
        validationResult
    ): ValidationErrors {
        const higherPeriods = Object.keys(limitData).slice(periodIndex + 1);
        higherPeriods.forEach((higherPeriod) => {
            /**
             * Bail if....
             * 1. there is no data for the other periods
             * 2. field value is lower than the other periods
             */
            if (
                controlValue <= limitData[higherPeriod].value ||
                [null, undefined].indexOf(limitData[higherPeriod].value) !== -1
            ) {
                return;
            }
            validationResult.depositLimit.message = `${period} limit cannot be higher than ${higherPeriod} limit`;
            validationResult.depositLimit.breachPeriod = higherPeriod;
            validationResult.depositLimit.breachPeriodValue =
                limitData[higherPeriod].value;
        });

        return validationResult;
    }
}
