<ng-container *ngIf="minAllowed && maxAllowed && maxAllowed >= minAllowed">
    <span id="minMaxLabel">
        (<ng-container *ngTemplateOutlet="tplMinAmount"></ng-container
        ><ng-container *ngTemplateOutlet="tplMaxAmount"></ng-container>)
    </span>
</ng-container>

<ng-template #tplMinAmount>
    <span>{{ minAllowed | playerCurrency: false }}</span>
</ng-template>
<ng-template #tplMaxAmount>
    <ng-container *ngIf="maxAllowed > minAllowed">
        <span class="mx-2">-</span>
        <span>{{ maxAllowed | playerCurrency: false }}</span>
    </ng-container>
</ng-template>
