import { Component, forwardRef } from "@angular/core";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { AccountTypeCode } from "app/enums/account-type-code.enum";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";

/**
 * @see https://developer.paypal.com/docs/archive/express-checkout/ec-set-up-reference-transactions/
 * @see https://developer.paypal.com/docs/archive/express-checkout/integration-guide/ECReferenceTxns/
 */
@Component({
    selector: "app-paypal-toggle-fastpay",
    templateUrl: "./paypal-toggle-fastpay.component.html",
    styleUrls: ["./paypal-toggle-fastpay.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PaypalToggleFastpayComponent),
            multi: true
        }
    ]
})
export class PaypalToggleFastpayComponent {
    public isVisible;

    private readonly unSupportedBonusPaymentMethods: AccountTypeCode[] =
        this.featureToggleService.getConfigValue(
            SupportedFeatures.unSupportedFTDBonusPaymentMethods
        );

    constructor(
        private readonly featureToggleService: FeatureToggleService,
        private readonly paymentMethodsService: PaymentMethodsService,
        private readonly playerSessionService: PlayerSessionService
    ) {
        /**
         * When it's a PayPal Account
         * Show the component
         */
        this.paymentMethodsService.selected$.subscribe((account) => {
            this.isVisible =
                account && account.accountTypeCode === AccountTypeCode.PayPal;
        });
    }

    public get displayUnsupportedBonusesWarning(): boolean {
        if (this.playerSessionService.skinID == 16) {
            return false;
        }
        const depositCount = this.playerSessionService.depositCount;
        if (
            this.unSupportedBonusPaymentMethods.includes(
                this.paymentMethodsService.currentSelected?.accountTypeCode
            ) &&
            depositCount < 1
        ) {
            return true;
        }
        return false;
    }
}
