import { NgModule } from "@angular/core";
import { WithdrawResponseComponent } from "./withdraw-response.component";
import { WithdrawResponseSuccessComponent } from "./withdraw-response-success/withdraw-response-success.component";
import { WithdrawResponseErrorComponent } from "./withdraw-response-error/withdraw-response-error.component";
import { SharedModule } from "app/modules/shared.module";

@NgModule({
    declarations: [
        WithdrawResponseComponent,
        WithdrawResponseSuccessComponent,
        WithdrawResponseErrorComponent
    ],
    imports: [SharedModule]
})
export class WithdrawResponseModule {}
