import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { SkinService } from "app/services/skin/skin.service";
import { NavigationEnd, Router } from "@angular/router";
import { TrackingEventService } from "app/services/tracking-event/tracking-event.service";
import { CoreUiTrackingService } from "@sbmedia/tracking";
import { Subscription } from "rxjs";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    public isHeaderEnabled = this._featureToggleService.getConfigValue(
        SupportedFeatures.CASHIER_HEADER
    );
    private subs: Subscription = new Subscription();

    constructor(
        private readonly coreUiTrackingService: CoreUiTrackingService,
        private readonly router: Router,
        private readonly skinService: SkinService,
        private readonly trackingEventService: TrackingEventService,
        private _featureToggleService: FeatureToggleService
    ) {
        this.injectPreConnectLinks();
    }

    /**
     * Creates pre-connect links to speed up connection to the relevant domains
     * @link    https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types/preconnect
     * @private
     */
    private injectPreConnectLinks(): void {
        const headTag = document.querySelector("head");
        if (!headTag) {
            return null;
        }
        const preConnectLinks: { id: string; url: string }[] = [
            {
                id: "safe-charge",
                url: "https://cdn.safecharge.com"
            }
        ];

        preConnectLinks.forEach((link) => {
            if (document.head.querySelector(`link#${link.id}`)) {
                return;
            }
            const preConnectLink = document.createElement("link");
            preConnectLink.id = link.id;
            preConnectLink.rel = "preconnect";
            preConnectLink.href = link.url;
            return headTag.appendChild(preConnectLink);
        });
    }

    public ngOnInit(): void {
        if (this.skinService.isStride) {
            this.subscribeToTrackingEvents();
        } else {
            this.subscribeToRouterEvents();
        }
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private subscribeToTrackingEvents(): void {
        this.subs.add(
            this.coreUiTrackingService.trackEventClick$.subscribe(
                (dataLayer) => {
                    if (!dataLayer.event || !(<any>window).dataLayer) {
                        return;
                    }
                    return (<any>window).dataLayer.push(dataLayer);
                }
            )
        );
    }

    private subscribeToRouterEvents(): void {
        this.subs.add(
            this.router.events.subscribe((val) => {
                if (val instanceof NavigationEnd) {
                    this.trackingEventService.trackPageView();
                }
            })
        );
    }
}
