<ng-container
    *ngIf="
        account.accountTypeCode == AccountTypeCodeEnum.ApplePay;
        else paysafeOrPayPal
    "
>
    <app-account-type-logo
        [code]="account.accountTypeCode"
        size="alternate"
    ></app-account-type-logo>
</ng-container>

<ng-template #paysafeOrPayPal>
    <app-account-type-logo
        [code]="account.accountTypeCode"
        size="large"
    ></app-account-type-logo>
</ng-template>

<hr />
<app-paypal-toggle-fastpay></app-paypal-toggle-fastpay>
<app-promo-form *ngIf="promosEnabled || bonusesEnabled"></app-promo-form>
<app-deposit-form></app-deposit-form>
