<app-deposit-limits-applied-info
    [limitsResponse]="limitsResponse"
></app-deposit-limits-applied-info>
<ng-container
    *ngFor="let type of ['perDay', 'perWeek', 'perMonth']; let last = last"
>
    <app-deposit-limits-overview-item
        [attr.type]="type"
        [type]="type"
        [recentlyUpdated]="periodUpdated === type"
        (onAction)="refreshAllLimits($event)"
    ></app-deposit-limits-overview-item>
    <hr *ngIf="!last" />
</ng-container>
