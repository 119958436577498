import { ErrorHandler, Injectable } from "@angular/core";
import { LoggerService } from "../services/logger/logger.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private loggerService: LoggerService) {}

    /**
     * Catches uncaught errors globally, logs them, and then rethrows so behaviour is unchanged.
     */
    handleError(error): void {
        this.loggerService.error("Unhandled error " + error?.message, error);
        throw error;
    }
}
