import { NgModule } from "@angular/core";
import { DepositResponseComponent } from "./deposit-response.component";
import { SharedModule } from "app/modules/shared.module";
import { DepositResponseErrorComponent } from "./deposit-response-error/deposit-response-error.component";
import { DepositResponseSuccessComponent } from "./deposit-response-success/deposit-response-success.component";

@NgModule({
    declarations: [
        DepositResponseComponent,
        DepositResponseErrorComponent,
        DepositResponseSuccessComponent
    ],
    imports: [SharedModule]
})
export class DepositResponseModule {}
