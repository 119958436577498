import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { GetWithdrawDetailResponse } from "app/interfaces/withdraw.interface";
import {
    CoinAccount,
    GetCustomerAccountsResponse
} from "app/interfaces/accounts.interface";
import { paths } from "app/paths";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { FormDropdownComponent } from "app/components/form-dropdown/form-dropdown.component";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { matchMoneyPattern } from "app/validators/money-pattern.validator";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";
import { PlayerSessionService } from "app/services/player-session/player-session.service";

@Component({
    selector: "app-withdraw-form",
    templateUrl: "./withdraw-form.component.html"
})
export class WithdrawFormComponent implements OnInit {
    @Input() withdrawDetails: GetWithdrawDetailResponse;
    public currency = this._playerSessionService.currencySign;
    public minAllowed: number;

    /**
     * Determine what's the maximum a player can withdraw:
     * Lowest value of:
     * 1. Available to Withdraw
     * 2. MaxWithdraw
     */
    public get maxAllowed(): number {
        if (
            this.withdrawDetails.MaxWithdraw &&
            !isNaN(this.withdrawDetails.MaxWithdraw)
        ) {
            return Math.min(
                ...[
                    +this.withdrawDetails.AvailableToWithdraw,
                    this.withdrawDetails.MaxWithdraw
                ]
            );
        }

        return +this.withdrawDetails.AvailableToWithdraw;
    }

    public formGroup: FormGroup;
    public submitting = false;
    public accountSelectionAllowed = this.featureToggleService.getConfigValue(
        SupportedFeatures.WITHDRAW_ACCOUNT_SELECTION
    );
    public accountsRespAsync: Observable<GetCustomerAccountsResponse>;
    public addPaymentMethodLink = `/${paths.BASE}/${paths.ADD_ACCOUNT}`;
    @ViewChild("accountsDropdown")
    accountsDropdown: FormDropdownComponent<CoinAccount>;

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private _activatedRoute: ActivatedRoute,
        private featureToggleService: FeatureToggleService,
        private _formBuilder: FormBuilder,
        private readonly _playerSessionService: PlayerSessionService,
        private _router: Router
    ) {}

    private preSelectFirstAccount(): void {
        this.accountsRespAsync = this.paymentMethodsService.get().pipe(
            map((resp) => {
                resp.accounts = resp.accounts.filter((acc) => {
                    return (
                        acc.refundable === undefined || acc.refundable === true
                    );
                });
                this.formGroup.patchValue({
                    account: resp.accounts[0] ?? null
                });
                return resp;
            })
        );
    }

    public ngOnInit(): void {
        this.preSelectFirstAccount();
        this.minAllowed = +this.withdrawDetails?.MinWithdraw;
        this.setupForm();
    }

    public submit(): void {
        if (this.formGroup.invalid) {
            return;
        }

        this.submitting = true;
        this.goToConfirm();
    }

    private async checkQueryParamsForAmount(): Promise<void> {
        this._activatedRoute.queryParams.subscribe((params) => {
            const queryParamAmount = params["withdrawalAmount"];
            if (!queryParamAmount || isNaN(queryParamAmount)) {
                return;
            }

            const force2decimalPlaces = (+queryParamAmount).toFixed(2);
            this.formGroup.patchValue({
                amount: force2decimalPlaces
            });
            this.formGroup.controls.amount.markAsDirty();
            this.formGroup.disable();
        });
    }

    private goToConfirm(): void {
        this._router.navigate(["confirm"], {
            relativeTo: this._activatedRoute,
            state: {
                transactionAmount: this.formGroup.value.amount,
                accountUsed: this.formGroup.value.account
            }
        });
    }

    private setupForm(): void {
        const accValidators = [];
        if (this.accountSelectionAllowed) {
            accValidators.push(Validators.required);
        }
        this.formGroup = this._formBuilder.group({
            amount: [
                null,
                [
                    Validators.required,
                    Validators.min(this.minAllowed),
                    Validators.max(this.maxAllowed),
                    matchMoneyPattern
                ]
            ],
            account: [null, [...accValidators]]
        });
        this.checkQueryParamsForAmount();
    }
}
