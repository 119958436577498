import { Component } from "@angular/core";
import { DepositLimitsResponse } from "app/interfaces/deposit-limits.interface";
import { ActivatedRoute, Router } from "@angular/router";
import { DepositLimitPeriod } from "app/enums/deposit-limit-period";
import { Observable } from "rxjs";

@Component({
    selector: "app-deposit-limits-overview",
    templateUrl: "./deposit-limits-overview.component.html"
})
export class DepositLimitsOverviewComponent {
    public limitsResponse: DepositLimitsResponse;
    public periodUpdated: DepositLimitPeriod;

    constructor(
        private activateRouted: ActivatedRoute,
        private _router: Router
    ) {
        this.getLimitData();
        this.checkForPeriodUpdateData();
    }

    private getLimitData(): void {
        this.activateRouted.data.subscribe((data) => {
            this.limitsResponse = data.depositLimits;
        });
    }

    /**
     * Clear the limit data when the event is received
     * Subscribe \ Call the Endpoint
     * Upon successful response, fetch the limit data again
     *
     * @param action
     */
    public refreshAllLimits(action: Observable<DepositLimitsResponse>): void {
        this.limitsResponse = undefined;
        action.subscribe((resp) => {
            if (!resp) {
                return;
            }
            this.getLimitData();
        });
    }

    /**
     * Checks whether an immediate successful period update was made
     * i.e. when a limit is decreased
     *
     * @private
     */
    private checkForPeriodUpdateData(): void {
        const currentNavigation = this._router.getCurrentNavigation();
        this.periodUpdated =
            currentNavigation?.extras?.state?.transactionResponse?.affectedPeriod;
    }
}
