<app-deposit-limits-applied-info
    *ngIf="limitPeriodData.type === 'perMonth'"
    [limitsResponse]="limitsResponse"
></app-deposit-limits-applied-info>
<app-deposit-limits-radial-bar-chart
    [limitPeriodData]="limitPeriodData"
    class="my-2"
></app-deposit-limits-radial-bar-chart>
<form [formGroup]="formGroup">
    <div class="section-title font-family-bold mb-1">
        {{ limitPeriodData.label }} limit
    </div>
    <app-form-field
        formControlName="amount"
        type="number"
        [placeholder]="
            playerTUIsAmber && !limitPeriodData.value
                ? limitPeriodData.lockedMaxValue
                : limitPeriodData.value
        "
        [invalid]="
            formGroup.controls.amount.dirty && formGroup.controls.amount.invalid
        "
        [value]="formGroup.value.amount"
    >
        <span slot="icon">{{ currency }}</span>
        <span slot="validation-error-message">
            <ng-container
                *ngIf="
                    formGroup.controls.amount?.errors?.pattern &&
                        formGroup.controls.amount?.errors?.pattern.actualValue;
                    else limitErrors
                "
            >
                You've entered a value with a decimal point.<br />
                Please round up the value for example,
                {{
                    formGroup.controls.amount.value
                        | number: "1.0-0"
                        | playerCurrency
                }}
            </ng-container>
            <ng-template #limitErrors>
                <ng-container
                    *ngIf="
                        formGroup.controls.amount?.errors?.depositLimit;
                        else msgPatternError
                    "
                >
                    {{
                        formGroup.controls.amount?.errors?.depositLimit.message
                    }}
                    <ng-container
                        *ngIf="
                            playerTUIsAmber &&
                            formGroup.controls.amount?.errors?.depositLimit
                                ?.breachType === 'lock'
                        "
                    >
                        <app-amber-affordability-limit-validation
                            [limitPeriodData]="limitPeriodData"
                        ></app-amber-affordability-limit-validation>
                    </ng-container>
                </ng-container>
            </ng-template>
            <ng-template #msgPatternError> Enter a valid amount </ng-template>
        </span>
    </app-form-field>

    <app-info-panel class="d-flex my-3" theme="info">
        <p>
            Increasing your limit will take 24 hours. Lowering it will take
            place immediately.
        </p>
    </app-info-panel>

    <app-cashier-button
        class="w-100 mt-4"
        theme="tertiary"
        [disabled]="formGroup.invalid"
        (click)="submit()"
    >
        Submit
    </app-cashier-button>
</form>

<app-explain-net-deposit-loss-limit
    class="mt-5 d-block"
></app-explain-net-deposit-loss-limit>
