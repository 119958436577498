import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { paths } from "app/paths";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";

@Injectable({
    providedIn: "root"
})
export class HasActivePaymentMethodGuard implements CanActivate {
    constructor(
        private readonly paymentMethodsService: PaymentMethodsService,
        private _router: Router
    ) {}

    /**
     * Just checks if the player has any accounts,
     * if not, redirect them to the add payment-method screen
     */
    async canActivate(): Promise<boolean | UrlTree> {
        return this.paymentMethodsService.hasActivePaymentMethod$.then(
            (res) => {
                if (res === false) {
                    this._router.navigate(
                        [`/${paths.BASE}/${paths.ADD_ACCOUNT}`],
                        {
                            state: {
                                layoutHeader: false
                            }
                        }
                    );
                }
                return res;
            }
        );
    }
}
