import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Injectable } from "@angular/core";
import { PromoService } from "app/services/promo/promo.service";

@Injectable({
    providedIn: "root"
})
export class DepositWithBonusValidatorService {
    constructor(private _promoService: PromoService) {}

    validate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const bonus = this._promoService.selected;
            /**
             * No Value,
             * nothing to validate
             */
            if (!control.value) {
                return null;
            }

            /**
             * No Bonus selected, bounce
             */
            if (!bonus) {
                return null;
            }

            const parsedDepositMin = +bonus.depositMin;
            /**
             * There is no Deposit Min
             * No need to check
             */
            if (!parsedDepositMin) {
                return null;
            }

            const parsedValue = +control.value;
            if (parsedValue >= parsedDepositMin) {
                return null;
            }

            return {
                depositWithBonus: {
                    message: `The deposit value is less that the minimum required for the selected Bonus (${bonus.depositMin})`,
                    min: bonus.depositMin
                }
            };
        };
    }
}
