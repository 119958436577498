import { Injectable } from "@angular/core";
import { LoggerService } from "app/services/logger/logger.service";

@Injectable({
    providedIn: "root"
})
export class ScriptLoaderService {
    constructor(private readonly loggerService: LoggerService) {}

    private static get headTag(): HTMLHeadElement {
        return document.querySelector("head");
    }

    /**
     * Creates an ID from the script URL
     * @param url
     * @private
     */
    private static createID(url): string {
        return btoa(url).replace(/\W/g, "_");
    }

    /**
     * Creating a link element to be appended to the headtag
     * This will contan the stylesheet specific to each skin
     * @see SkinID enum
     * @private
     */
    public async injectSkinStyles(
        skinID: number
    ): Promise<HTMLLinkElement | void> {
        const headTag = document.getElementsByTagName("head")[0];
        if (!headTag) {
            return;
        }

        if (!skinID) {
            return;
        }
        const skinLocation = `assets/theme-variables/${skinID}1.css`;
        const stylesheetLink = document.createElement("link");
        stylesheetLink.rel = "stylesheet";
        stylesheetLink.href = skinLocation;

        headTag.parentElement.appendChild(stylesheetLink);
    }
    /**
     * Creates the HTML Script Element
     * @param url
     * @param id
     * @param isAsync
     * @private
     */
    private static createScriptElement(
        url: string,
        id: string = null,
        isAsync = false
    ): HTMLScriptElement {
        const tagElement = document.createElement("script");
        tagElement.src = url;
        tagElement.type = "text/javascript";
        tagElement.id = id;
        if (isAsync) {
            tagElement.async = isAsync;
        }
        return tagElement;
    }

    public isAlreadyLoaded(scriptID: string): false | HTMLScriptElement {
        const result =
            ScriptLoaderService.headTag.querySelector<HTMLScriptElement>(
                `#${scriptID}`
            );
        return result === null ? null : result;
    }

    public load(
        url: string,
        id: string = null,
        isAsync = false
    ): HTMLScriptElement {
        id = id ?? ScriptLoaderService.createID(url);

        //** if there is no script, we shan't bother continuing */
        if (this.isAlreadyLoaded(id) || !url) {
            return;
        }

        const tagElement = ScriptLoaderService.createScriptElement(
            url,
            id,
            isAsync
        );

        tagElement.addEventListener("error", () => {
            this.loggerService.error("Failed to load script", { url: url });
        });

        return ScriptLoaderService.headTag.appendChild(tagElement);
    }
}
