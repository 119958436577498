import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { PlayerSessionService } from "app/services/player-session/player-session.service";

@Pipe({
    name: "playerCurrency"
})
export class PlayerCurrencyPipe implements PipeTransform {
    constructor(
        private _currencyPipe: CurrencyPipe,
        private readonly _playerSessionService: PlayerSessionService
    ) {}

    /**
     * Currently only the following currencies are truly supported
     * € | £
     * @param value
     * @param showCents
     */
    transform(value: number | string, showCents = null): string {
        if (value === undefined || value === null) {
            return "";
        }

        /**
         * If showCents has no value, but the value has cents, show it
         */
        showCents = showCents === null && value.toString().indexOf(".") !== -1;

        const digitsInfo = `1.${showCents ? 2 : 0}`;

        if (this._playerSessionService.currencySign === "€") {
            return (
                this._currencyPipe.transform(
                    value,
                    this._playerSessionService.currencySign,
                    "",
                    digitsInfo
                ) + ` ${this._playerSessionService.currencySign}`
            );
        }
        return this._currencyPipe.transform(
            value,
            this._playerSessionService.currencySign,
            "symbol",
            digitsInfo
        );
    }
}
