<app-cashier-layout>
    <div class="px-1">
        <ng-container
            *ngTemplateOutlet="
                isError !== false ? tplErrorResponse : tplSuccessResponse
            "
        >
        </ng-container>
    </div>
</app-cashier-layout>

<ng-template #tplSuccessResponse>
    <app-withdraw-response-success
        [transactionResponse]="cashierServiceResponse"
    ></app-withdraw-response-success>
</ng-template>

<ng-template #tplErrorResponse>
    <app-withdraw-response-error
        [transactionResponse]="coinResponse || cashierServiceResponse"
        [transactionAmount]="transactionAmount"
    ></app-withdraw-response-error>
</ng-template>
