<div class="p-2">
    <ng-container *ngIf="accountTypes$ | async; else loader">
        <ng-container *ngFor="let item of options">
            <app-list-item
                id="add-account-option-{{ item.name }}"
                *ngIf="item.enabled"
                [state]="{ accountData: item.accountData }"
                [routerLink]="[item.route]"
                class="cta"
            >
                <div
                    class="d-flex flex-grow-1 flex-row align-items-center justify-content-around"
                >
                    <app-account-type-logo
                        *ngFor="let logo of item.logos"
                        [code]="logo"
                        size="large"
                    ></app-account-type-logo>
                </div>
            </app-list-item>
        </ng-container>
    </ng-container>
</div>
<ng-template #loader>
    <app-loader></app-loader>
</ng-template>
