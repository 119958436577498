import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

export interface EnvironmentValues {
    production: boolean;
    BUILD: string;
    BACKEND_API: string;
    COIN_API: string;
    CASHIER_SERVICE_BASE_URL: string;
}

@Injectable({
    providedIn: "root"
})
export class EnvironmentService {
    public value: EnvironmentValues;

    public get COIN_API(): string {
        return this.value?.COIN_API ?? "";
    }

    public get CASHIER_SERVICE_BASE_URL(): string {
        return this.value?.CASHIER_SERVICE_BASE_URL ?? "";
    }

    public get BUILD(): string {
        return this.value?.BUILD ?? "";
    }

    public get VF_BINGO(): string {
        return this.value?.BACKEND_API + "/vf-bingo/api/v1/bonus";
    }

    public get PLAYER_WALLET(): string {
        return this.value?.BACKEND_API + "/player-wallet/api/v1/wallet";
    }

    constructor(private readonly http: HttpClient) {}

    public async loadEnvConfig(): Promise<void> {
        /**
         * When running locally... return the current environment file
         */
        if (environment.production !== true) {
            this.value = environment;
        } else {
            this.value = await this.http
                .get<EnvironmentValues>("/csi/env")
                .toPromise();
        }
    }
}
