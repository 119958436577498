import { Component, Input } from "@angular/core";
import { DepositLimitsResponse } from "app/interfaces/deposit-limits.interface";

@Component({
    selector: "deposit-global-limit-reached",
    templateUrl: "./deposit-global-limit-reached.component.html",
    styleUrls: ["./deposit-global-limit-reached.component.scss"]
})
export class DepositGlobalLimitReachedComponent {
    @Input() limitsResponse: DepositLimitsResponse;

    depositLimitTitle = "Deposit limit applied.";
    responsibleOperatorInfo =
        "As a responsible operator, we have applied a rolling 30 day net deposit  Deposit Limit of";
    limitReached =
        "If you reach this limit, you cannot make any more deposits until this decreases over time.";
    increaseLimitInfo =
        "If you want to increase this limit, please provide us with more information.";
    chatWithUs = "Chat with us";
}
