<div class="container-fluid position-relative">
    <div class="row no-gutters">
        <div class="col mr-3" id="cvv-column">
            <div
                for="cardCVV"
                class="section-title font-family-bold m-0 cvv-label"
                [ngClass]="{ 'text-danger': hasErrors }"
            >
                <span>CVV</span>
                <span cashierIcon="cvv"></span>
            </div>
            <div
                id="cardCVV"
                class="form-field"
                [ngClass]="{ invalid: hasErrors }"
            ></div>
        </div>
        <div class="col align-self-end">
            <app-cashier-button
                (click)="submit()"
                [disabled]="isComplete !== true"
                >Deposit
                {{ depositAmount | playerCurrency }}
            </app-cashier-button>
        </div>
        <div class="small w-100 text-danger validation" *ngIf="hasErrors">
            <span cashierIcon="warning" class="icon error"></span>
            <span class="text">{{ errorMessage }}</span>
        </div>
    </div>
    <span *ngIf="showApplePayRedirect">
        <div class="sectional-title text-center">
            <span class="font-family-bold">or</span>
        </div>
        <app-cashier-button
            theme="prompt-apple-pay"
            (click)="navigateToApplePaySelectedHandler()"
        >
            <span>
                Deposit with
                <img src="assets/images/apple_pay_mark_button.svg" />
            </span>
        </app-cashier-button>
    </span>
</div>
