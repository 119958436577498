<div class="dropdown-field-mask"></div>
<div class="form-field dropdown collapse position-relative" #dropdown>
    <div class="icon toggle" (click)="toggle()">
        <span cashierIcon="chevron_down"></span>
    </div>

    <div
        class="option align-items-center"
        (click)="select(index); toggle()"
        [hidden]="value !== option && !isOpened"
        [ngClass]="{ 'order-first': value === option }"
        *ngFor="let option of options; let index = index"
    >
        <ng-container
            *ngTemplateOutlet="
                optionTemplate ? optionTemplate : tplDefaultOption;
                context: { $implicit: option }
            "
        ></ng-container>
    </div>

    <div
        *ngIf="footerTemplate"
        [hidden]="!isOpened"
        class="option align-items-center"
    >
        <ng-container
            *ngTemplateOutlet="footerTemplate ? footerTemplate : null"
        ></ng-container>
    </div>
</div>

<ng-template #tplDefaultOption let-option>{{
    option.name ? option.name : option
}}</ng-template>
