<div class="global-limit-container" *ngIf="limitsResponse">
    <div id="svgContainer">
        <svg xmlns="http://www.w3.org/2000/svg" width="176" height="176">
            <circle
                id="progressCircleBackground"
                fill="transparent"
                stroke-width="20"
                cx="88"
                cy="88"
                r="78"
                stroke-dasharray="490.0884539600077"
                stroke-dashoffset="490.0884539600077"
            ></circle>
            <circle
                id="progressCircle"
                fill="transparent"
                stroke-width="20"
                cx="88"
                cy="88"
                r="78"
                stroke-dasharray="490.0884539600077"
                [attr.stroke-dashoffset]="strokeDashoffset"
            ></circle>
            <defs>
                <linearGradient id="redRange" x1="0" x2="1" y1="0" y2="0">
                    <stop offset="0%" stop-color="#d64c7f"></stop>
                    <stop offset="100%" stop-color="#ee4758"></stop>
                </linearGradient>
            </defs>
        </svg>
        <div class="progress-text">
            <div class="usage-descriptor">
                <span class="amount-used">{{
                    limitsResponse.depositLimitGlobal | playerCurrency
                }}</span>
                <br /><span class="amount-used-text"
                    >used across our sites</span
                >
            </div>
            <hr />
            <div class="period-descriptor">
                <span class="amount-value">{{
                    limitsResponse.depositLimitGlobal | playerCurrency
                }}</span>
                <br />over 30 days<br />
                <span
                    class="cashier-icon"
                    cashierIcon="shield_pound_large"
                    height="40"
                ></span>
            </div>
        </div>
        <br /><br />
    </div>
    <header class="limit-header">
        You've reached your<br />
        pre-determined Deposit Limit.
    </header>

    <div class="container-info">
        <div
            slot="header"
            class="d-flex align-items-center px-2 py-1 small mt-3"
        >
            <span cashierIcon="shield_pound" height="16"></span>
            <span class="mx-3 flex-grow-1"
                ><strong>{{ depositLimitTitle }}</strong></span
            >
        </div>
        <p>
            {{ responsibleOperatorInfo }}
            <span>{{
                limitsResponse.depositLimitGlobal | playerCurrency
            }}</span>
            across all of our sites. <br /><br />You have used
            <span class="used-amount">{{
                limitsResponse.depositLimitGlobal | playerCurrency
            }}</span>
            of
            <strong>{{
                limitsResponse.depositLimitGlobal | playerCurrency
            }}</strong
            >. <br /><br />
            {{ limitReached }}
            <br /><br />
            {{ increaseLimitInfo }}
        </p>
        <div class="d-flex flex-column mt-3 mb-1">
            <app-cashier-button
                theme="primary-outline"
                size="none"
                class="mt-3"
                appCloseCashier="chatWithUs"
                >{{ chatWithUs }}</app-cashier-button
            >
        </div>
    </div>
</div>
