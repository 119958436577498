import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SafeChargeFieldDefinition } from "app/interfaces/safe-charge";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { SafeChargeService } from "app/services/safe-charge/safe-charge.service";
import { DepositStartRequest } from "app/interfaces/deposit.interface";
import { PromoService } from "app/services/promo/promo.service";
import { MaskService } from "app/services/mask/mask.service";
import { LoggerService } from "app/services/logger/logger.service";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { CashierApiService } from "app/services/cashier-api.service";
import { DepositService } from "app/services/deposit/deposit.service";

@Component({
    selector: "app-nuvei-cvv-form",
    templateUrl: "./nuvei-cvv-form.component.html",
    styleUrls: ["./nuvei-cvv-form.component.scss"]
})
export class NuveiCVVFormComponent implements OnInit, OnDestroy {
    @Input() depositAmount: number;
    @Input() selectedAccount: CoinAccount;
    private _requestData: DepositStartRequest;
    private sfcFields: SafeChargeFieldDefinition[] = [
        {
            field: null,
            sfcID: "ccCvc",
            htmlID: "cardCVV"
        }
    ];

    public isComplete;
    public hasErrors = false;
    public errorMessage;
    public showApplePayRedirect = false;

    constructor(
        private readonly _loggerService: LoggerService,
        private readonly maskService: MaskService,
        private _promoService: PromoService,
        private paymentMethodsService: PaymentMethodsService,
        private cashierAPIService: CashierApiService,
        private depositService: DepositService,
        private _safeChargeService: SafeChargeService
    ) {
        this.listenForPromos();
        this.addCVVListener();
    }

    public ngOnInit(): void {
        this.showApplePayRedirect =
            this.paymentMethodsService.setApplePayPrompt();
        this._safeChargeService.setupFields(this.sfcFields, {
            cvvPlaceholder: "CVV"
        });
    }

    ngOnDestroy(): void {
        this.removeCVVListener();
    }

    /**
     * Listen for message events from the SafeCharge iFrame
     * @private
     */
    private async addCVVListener(): Promise<void> {
        window.addEventListener("message", this.listenForCVVChanges);
    }

    private async removeCVVListener() {
        window.removeEventListener("message", this.listenForCVVChanges);
    }

    /**
     * TODO: use this implementation rather:
     * @link https://docs.safecharge.com/documentation/accept-payment/web-sdk/quick-start/nuvei-fields/#validation-error-handling
     *
     * Listen for changes to the CVV field
     * This will tell us if the CVV is complete or not
     *
     * When it is complete, emit an event so that other components can act upon
     * i.e. Submit Button should disable if it is incomplete
     * @param postMessage
     */
    private listenForCVVChanges = (postMessage): void => {
        /**
         * Not from safecharge, bounce
         */
        if (postMessage.origin !== "https://cdn.safecharge.com") {
            return;
        }

        /**
         * Data is not in the correct format, bounce
         */
        if (!postMessage?.data && typeof postMessage.data !== "string") {
            return;
        }

        let postMessageData;
        try {
            postMessageData = JSON.parse(postMessage.data);

            /**
             * Data is not related to cvv, bounce...
             */
            if (postMessageData?.message?.payload?.complete === undefined) {
                return;
            }

            /**
             * We only care about change events
             * ...ignores focus and blur events
             */
            if (postMessageData?.event !== "change") {
                return;
            }

            this.isComplete =
                postMessageData?.message?.payload.complete === true;
            this.toggleError(!this.isComplete);
        } catch (exception) {
            this.toggleError(false);
            this._loggerService.warn(
                "onSafeChargeMessage: Error trying to parse event data",
                postMessage
            );
        }
    };

    public submit(): void {
        this.maskService.show();
        this.cashierAPIService
            .initiateDeposit(this.requestData())
            .toPromise()
            .then((depositResponse) => {
                this._safeChargeService
                    .onDepositSessionCreated(
                        depositResponse,
                        this.sfcFields.find((field) => field.sfcID == "ccCvc")
                            .field,
                        this.depositAmount
                    )
                    .then((depositSummary) => {
                        if (!depositSummary.allowRetry) {
                            return;
                        }
                        this.maskService.hide();
                        this.toggleError(true, "Retry CVV");
                    });
            })
            .catch((error) => {
                this.depositService.redirectToResponsePage(error);
            });
    }

    private toggleError(showError: boolean, errorMessage?: string): void {
        this.hasErrors = showError;
        this.errorMessage = errorMessage ?? "Enter CVV";
    }

    /**
     * Listen for changes to promos
     * @private
     */
    private listenForPromos() {
        this._promoService.promoPayload$.subscribe((resp) => {
            this.requestData({
                promoId: resp.id,
                promoCode: resp.code,
                isOffer: resp.isOffer
            });
        });
    }

    /**
     * Click handler to create or select the Apple Pay account
     */
    public navigateToApplePaySelectedHandler(): void {
        this.paymentMethodsService.navigateToApplePaySelected();
    }
    /**
     * Preps the data that is sent to the DepositStart endpoint
     * @param additionalData
     * @private
     */
    private requestData(additionalData = {}): DepositStartRequest {
        return (this._requestData = {
            ...this._requestData,
            ...{
                amount: this.depositAmount,
                accountId: this.selectedAccount?.accountId ?? null,
                ukgcAck: true
            },
            ...additionalData
        });
    }
}
