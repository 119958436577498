import { Component, Input } from "@angular/core";
import { CoinResponse } from "app/interfaces/coin-response";
import { paths } from "app/paths";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-add-account-response-success",
    templateUrl: "./add-account-response-success.component.html"
})
export class AddAccountResponseSuccessComponent {
    @Input() transactionResponse: CoinResponse;
    @Input() account: CoinAccount;

    private depositPageURL = `/${paths.BASE}/${paths.DEPOSIT}`;
    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private _router: Router
    ) {}

    public selectAccount(account: CoinAccount): void {
        this.paymentMethodsService.select(account);
        this._router.navigate([this.depositPageURL]);
    }
}
