<app-cashier-layout>
    <div class="px-1">
        <ng-container
            *ngTemplateOutlet="
                isError !== false ? tplErrorResponse : tplSuccessResponse
            "
        >
        </ng-container>
    </div>
</app-cashier-layout>

<ng-template #tplSuccessResponse>
    <ng-container *ngIf="account$ | async as account; else loader">
        <app-add-account-response-success
            [account]="account"
            [transactionResponse]="coinResponse || cashierServiceResponse"
        ></app-add-account-response-success>
    </ng-container>
    <ng-template #loader>
        <app-loader></app-loader>
    </ng-template>
</ng-template>

<ng-template #tplErrorResponse>
    <app-add-account-response-error
        [transactionResponse]="coinResponse || cashierServiceResponse"
        [safeChargeResponse]="safeChargeResponse"
    ></app-add-account-response-error>
</ng-template>
