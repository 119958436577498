<div class="d-flex flex-row align-items-center flex-grow-1 p-2">
    <ng-content></ng-content>
</div>

<div
    role="cta"
    *ngIf="ctaVisible"
    class="p-3 position-absolute dropdown-menu-right"
    (click)="triggerCTA()"
>
    <app-cashier-button theme="none" *ngIf="showDelete; else defaultCTA">
        <span cashierIcon="delete" color="#292929"></span>
    </app-cashier-button>
</div>

<ng-template #defaultCTA>
    <span cashierIcon="chevron_right"></span>
</ng-template>
