<ng-container class="bonus-promo-field" *ngIf="bonusesEnabled || promosEnabled">
    <ng-container *ngIf="promosEnabled && supportBonusesAndPromosFTD()">
        <div class="promo-field mb-2">
            <div class="section-title font-family-bold m-0">Promo code?</div>
            <form [formGroup]="formGroup" (ngSubmit)="submitForm()">
                <div class="container-fluid">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <app-form-field
                                #inputField
                                formControlName="code"
                                [invalid]="
                                    formGroup.controls.code.dirty &&
                                    formGroup.controls.code.invalid
                                "
                            >
                                <span slot="validation-error-message"
                                    >Invalid promo code</span
                                >
                            </app-form-field>
                        </div>
                        <div class="ml-2">
                            <app-cashier-button [disabled]="preventSubmission">
                                Validate
                            </app-cashier-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="small" *ngIf="busyCheckingPromoCode">
                                <ng-container
                                    *ngIf="
                                        !checkingPromoCode.closed;
                                        else promoCodeResult
                                    "
                                >
                                    <span
                                        cashierIcon="loading"
                                        class="animate spin mr-1"
                                    ></span
                                    ><span>Validating...</span>
                                </ng-container>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
    <div class="d-flex flex-column">
        <div
            class="bonus-super-heading"
            *ngIf="firstAvailableBonus && firstAvailableBonus.length > []"
        >
            <div class="section-title font-family-bold mb-2">
                Available
                {{ bonuses.length == 1 ? "Bonus" : "Bonuses" }}
            </div>
            <p>
                To claim your bonus, make sure to click on the offer
                <strong>before</strong> you deposit.
            </p>
            <div class="mb-3 bonus-section">
                <ng-container *ngIf="!showAllBonuses">
                    <app-list-item
                        size="compressed"
                        [ctaVisible]="false"
                        (onCTAClick)="toggleSelection(bonus)"
                        [ngClass]="{
                            active: selectedBonusID == bonus.promoId
                        }"
                        *ngFor="let bonus of firstAvailableBonus"
                    >
                        <span
                            cashierIcon="gift_tag"
                            class="ml-2 mr-3"
                            [ngClass]="{
                                active: selectedBonusID == bonus.promoId
                            }"
                        ></span
                        ><span
                            class="small"
                            [innerHTML]="bonus.description"
                        ></span>
                    </app-list-item>
                </ng-container>

                <ng-container *ngIf="showBonusField && showAllBonuses">
                    <app-list-item
                        size="compressed"
                        [ctaVisible]="false"
                        (onCTAClick)="toggleSelection(bonus)"
                        [ngClass]="{
                            active: selectedBonusID == bonus.promoId
                        }"
                        *ngFor="let bonus of bonuses"
                    >
                        <span
                            cashierIcon="gift_tag"
                            class="ml-2 mr-3"
                            [ngClass]="{
                                active: selectedBonusID == bonus.promoId
                            }"
                        ></span
                        ><span
                            class="small"
                            [innerHTML]="bonus.description"
                        ></span>
                    </app-list-item>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="bonuses && bonuses.length > 2">
            <div class="bonus-promo-block mb-3">
                <div class="bonus-section">
                    <div
                        class="bonus-heading"
                        (click)="togglePromoBonusVisibility()"
                        onkeypress=""
                    >
                        <div
                            class="section-title font-family-bold"
                            *ngIf="!showAllBonuses"
                        >
                            Click to see {{ bonuses.length - 1 }} more available
                            {{ bonuses.length > 1 ? "bonuses" : "bonus" }}
                        </div>
                        <div
                            class="section-title font-family-bold"
                            *ngIf="showAllBonuses"
                        >
                            Show less
                        </div>
                        <span [cashierIcon]="chevronState"></span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-template #promoCodeResult>
    <ng-container *ngIf="hasValidPromoCode">
        <span cashierIcon="tick" class="success mr-1"></span
        ><span class="text-success">Valid promo code</span>
    </ng-container>
</ng-template>
