import { Component, OnInit } from "@angular/core";
import { WithdrawService } from "app/services/withdraw/withdraw.service";

@Component({
    selector: "app-withdraw-bonus",
    templateUrl: "./withdraw-bonus.component.html"
})
export class WithdrawBonusComponent implements OnInit {
    public hasForfeitedBonus: boolean;

    ngOnInit(): void {
        this.hasForfeitedBonus = WithdrawService.hasForfeitedBonus();
    }
}
