import { NgModule } from "@angular/core";
import { DepositLimitsComponent } from "./deposit-limits.component";
import { SharedModule } from "app/modules/shared.module";
import { DepositLimitsOverviewComponent } from "./deposit-limits-overview/deposit-limits-overview.component";
import { DepositLimitsEditComponent } from "./deposit-limits-edit/deposit-limits-edit.component";
import { DepositLimitsOverviewItemComponent } from "./deposit-limits-overview/deposit-limits-overview-item/deposit-limits-overview-item.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ExplainNetDepositLossLimitComponent } from "./deposit-limits-edit/explain-net-deposit-loss-limit/explain-net-deposit-loss-limit.component";
import { DepositLimitsAppliedInfoComponent } from "./deposit-limits-applied-info/deposit-limits-applied-info.component";
import { AmberAffordabilityLimitValidationComponent } from "./amber-affordability-limit-validation/amber-affordability-limit-validation.component";
import { DepositLimitsRadialBarChartComponent } from "./deposit-limits-radial-bar-chart/deposit-limits-radial-bar-chart.component";

@NgModule({
    declarations: [
        AmberAffordabilityLimitValidationComponent,
        ExplainNetDepositLossLimitComponent,
        DepositLimitsAppliedInfoComponent,
        DepositLimitsComponent,
        DepositLimitsOverviewComponent,
        DepositLimitsOverviewItemComponent,
        DepositLimitsRadialBarChartComponent,
        DepositLimitsEditComponent
    ],
    imports: [ReactiveFormsModule, SharedModule]
})
export class DepositLimitsModule {}
