/**
 * @link    https://bitbucket.org/spacebarmediadev/cashier_common/src/master/CoinInc.php#lines-243
 */
export enum AccountTypeCode {
    VisaCredit = "A_VISA_CREDIT",
    VisaDebit = "A_VISA_DEBIT",
    Electron = "Electron",
    MasterCardCredit = "A_MASTERCARD_CREDIT",
    MasterCardDebit = "A_MASTERCARD_DEBIT",
    Maestro = "A_MAESTRO",
    MaestroSwitch = "A_MAESTRO_SWITCH",
    Solo = "A_SOLO",
    Neteller = "A_NETELLER",
    PayPal = "A_PAYPAL",
    PaySafe = "A_PAYSAFECARD",
    Fonix = "A_FONIX",
    ManualDeposit = "A_MANUAL_DEPOSIT",
    ManualDepositAspers = "A_MANUAL_DEPOSIT_ASPERS",
    BankDraftCheque = "A_BANKDRAFT_CHEQUE",
    WireTransfer = "A_WIRETRANSFER",
    ManualRefund = "A_MANUAL_REFUND",
    ReverseRefund = "A_REVERSE_REFUND",
    ApplePay = "A_APPLEPAY"
}
