import { Component } from "@angular/core";
import { AccountTypeCode } from "app/enums/account-type-code.enum";
import { paths } from "app/paths";
import { Router } from "@angular/router";

@Component({
    selector: "app-confirm-non-refundable",
    templateUrl: "./confirm-non-refundable.component.html",
    styles: ["p{font-size: 0.9rem;}"]
})
export class ConfirmNonRefundableComponent {
    public accountCode = AccountTypeCode.PaySafe;
    public accountData = {
        promos: null
    };
    public confirmedPath = `${paths.NON_REFUNDABLE_CONFIRMED}`;

    constructor(private readonly router: Router) {
        const currentNavigation = this.router.getCurrentNavigation();
        this.accountData.promos =
            currentNavigation?.extras.state?.accountData?.promos;
    }
}
