<div slot="body" class="d-flex align-items-center">
    <app-account-type-logo
        [code]="account.accountTypeCode"
        [isExpired]="isExpired"
        class="mr-2"
    ></app-account-type-logo>
    <div class="flex-grow-1">
        <app-account-type-name
            [account]="account"
            class="name d-flex"
        ></app-account-type-name>
        <app-account-number [account]="account"></app-account-number>
    </div>
</div>

<div hidden #deleteConfirmationPanel>
    <hr />
    <ng-container [ngSwitch]="status">
        <ng-container *ngSwitchCase="'busy'">
            <ng-container *ngTemplateOutlet="tplCTABusy"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'error'">
            <ng-container *ngTemplateOutlet="tplError"></ng-container>
        </ng-container>
        <div
            *ngSwitchDefault
            slot="footer"
            class="d-flex justify-content-between flex-row align-items-center fadeInDown animated"
        >
            <span class="mr-2 small text-danger flex-grow-1 font-family-bold"
                >Are you sure?</span
            >
            <div
                class="align-items-center d-flex flex-row justify-content-between"
            >
                <app-cashier-button
                    theme="secondary-outline"
                    size="small"
                    class="mr-1"
                    (click)="triggerCTA()"
                    >Yes</app-cashier-button
                >
                <app-cashier-button
                    size="small"
                    (click)="deleteConfirmationPanel.toggleAttribute('hidden')"
                    >No</app-cashier-button
                >
            </div>
        </div>
    </ng-container>
</div>

<div
    role="cta"
    class="p-3 position-absolute dropdown-menu-right"
    (click)="
        showDelete
            ? deleteConfirmationPanel.toggleAttribute('hidden')
            : triggerCTA()
    "
>
    <app-cashier-button theme="none" *ngIf="showDelete; else defaultCTA">
        <span cashierIcon="delete"></span>
    </app-cashier-button>
</div>

<ng-template #tplCTABusy>
    <div class="small text-right">
        <img src="assets/images/spinner-busy.svg" />
        busy
    </div>
</ng-template>

<ng-template #defaultCTA>
    <span *ngIf="showCTA" cashierIcon="chevron_right"></span>
</ng-template>

<ng-template #tplError>
    <div class="d-flex justify-content-between flex-row align-items-center">
        <div class="mr-2 small flex-grow-1" appChatWithUs>
            Unable to remove at this time, please chat with us.
        </div>
        <app-cashier-button
            (click)="deleteConfirmationPanel.toggleAttribute('hidden')"
            >Ok</app-cashier-button
        >
    </div>
</ng-template>
