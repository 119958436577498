import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { GetWithdrawDetailResponse } from "app/interfaces/withdraw.interface";
import { WithdrawService } from "app/services/withdraw/withdraw.service";
import {
    FeatureToggleService,
    SupportedFeatures
} from "app/services/feature-toggle/feature-toggle.service";

@Component({
    selector: "app-withdraw-home",
    templateUrl: "./withdraw-home.component.html",
    styleUrls: ["./withdraw-home.component.scss"]
})
export class WithdrawHomeComponent {
    public withdrawDetail: Observable<GetWithdrawDetailResponse>;
    public accountSelectionAllowed = this.featureToggleService.getConfigValue(
        SupportedFeatures.WITHDRAW_ACCOUNT_SELECTION
    );

    constructor(
        private featureToggleService: FeatureToggleService,
        private _withdrawService: WithdrawService
    ) {
        this.withdrawDetail = this._withdrawService.initData;
    }
}
