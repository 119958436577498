import { AbstractControl, ValidationErrors } from "@angular/forms";

/**
 * Custom Validator to ensure an input matches the specified format
 * 1. Must start with at least 1 number
 * 2. Can have any amount of numbers before the decimal point
 * 3. If it has a decimal point, it needs to be proceeded by either 1 or 2 digits only
 *
 * Concerns about values such as:
 * 100000000.99
 * 0.01
 * Should be handled by min and max validation
 *
 * This validation only checks the format
 *
 * @param control
 */
export function matchMoneyPattern(
    control: AbstractControl
): ValidationErrors | null {
    return isMoneyFormat(control?.value)
        ? null
        : {
              pattern: true
          };
}

export function isMoneyFormat(value: string | number): boolean {
    if (!value) {
        return false;
    }
    value = value.toString().trim();
    return value.toString().match(/^(\d)+(\.\d{1,2})?$/) !== null;
}
