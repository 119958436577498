import { Component, Injector, Input, ViewEncapsulation } from "@angular/core";
import {
    ActivatedRoute,
    NavigationEnd,
    ResolveEnd,
    ResolveStart,
    Router,
    RouterEvent
} from "@angular/router";

@Component({
    selector: "app-cashier-layout",
    templateUrl: "./cashier-layout.component.html",
    styleUrls: ["./cashier-layout.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CashierLayoutComponent {
    /**
     * Define the gap between the header and the content below it
     * normal = mb-3
     * small = mb-2
     */
    @Input() headerYMargin: "normal" | "small" = "normal";

    /**
     * Define the gap between the header and it's parent container on the x-axis
     * none = ''
     * small = mx-2
     */

    @Input() headerXMargin: "small" | "none" = "none";

    public headerHidden = false;
    public horizontalPaddingClass = "px-3";

    /**
     * @see checkResolverEvents
     */
    public resolving = false;

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private injector: Injector
    ) {
        const router = this.injector.get(Router);
        router.events.subscribe((event: RouterEvent) => {
            this.checkResolverEvents(event);

            if (!(event instanceof NavigationEnd)) {
                return;
            }

            const currentNavigation = router.getCurrentNavigation();

            /**
             * The header can either be set to hidden via one of these methods:
             * * route data in app-routing.module.ts
             * * route state in the navigation extras
             */
            this.headerHidden =
                currentNavigation?.extras?.state?.layoutHeader === false ||
                this._activatedRoute.snapshot.firstChild?.data?.layoutHeader ===
                    false;
        });
    }

    /**
     * If the Router Event is related to a resolver event,
     * update the variable accordingly...
     * This is used to show a loader so that the user doesn't have any lag between screens
     * @param routerEvent
     * @private
     */
    private checkResolverEvents(routerEvent: RouterEvent): void {
        if (routerEvent instanceof ResolveStart) {
            this.resolving = true;
        } else if (routerEvent instanceof ResolveEnd) {
            this.resolving = false;
        }
    }
}
