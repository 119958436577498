import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { paths } from "app/paths";
import { CashierApiService } from "app/services/cashier-api.service";

@Injectable({
    providedIn: "root"
})
export class AllowedToDepositGuard implements CanActivate {
    constructor(
        private readonly cashierAPIService: CashierApiService,
        private _router: Router
    ) {}

    /**
     * This route guard is to restrict access to the Deposit Page
     * Specifially when the user has been disabled through CAT
     * @returns Promise
     */
    async canActivate(): Promise<boolean | UrlTree> {
        return new Promise((resolve) =>
            this.cashierAPIService.playerData$.subscribe((playerData) => {
                if (!playerData?.deposit?.disabled) return resolve(true);

                return resolve(
                    this._router.createUrlTree([
                        `/${paths.BASE}/${paths.DEPOSIT_BLOCKED}`
                    ])
                );
            })
        );
    }
}
