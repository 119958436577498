import { Component, Input } from "@angular/core";

@Component({
    selector: "app-deposit-range-label",
    templateUrl: "./deposit-range-label.component.html"
})
export class DepositRangeLabelComponent {
    @Input() minAllowed: number;
    @Input() maxAllowed: number;
}
