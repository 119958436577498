import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders
} from "@angular/common/http";
import { EMPTY, Observable, of, throwError } from "rxjs";
import { EnvironmentService } from "./environment/environment.service";
import { PlayerSessionService } from "./player-session/player-session.service";
import { GetAccountTypesResponse } from "app/interfaces/account-types";
import { catchError, shareReplay, tap } from "rxjs/operators";
import { GetCustomerAccountsResponse } from "app/interfaces/accounts.interface";
import { ExpireStatus } from "app/enums/expire-status.enum";
import { LoggerService } from "./logger/logger.service";
import {
    AddAccountResponse,
    TokenizedCardResponse
} from "app/interfaces/tokenize-card.interface";
import { Injectable } from "@angular/core";
import { WithdrawResponse } from "app/interfaces/withdraw.interface";
import { ErrorService } from "./error/error.service";
import { GetPlayerResponse, TUStatus } from "app/interfaces/start.interface";
import { CurrencyISOCode } from "app/enums/currency-iso-code";
import {
    DepositEndRequest,
    DepositResponse,
    DepositStartRequest
} from "app/interfaces/deposit.interface";
import { APP_COOKIE_KEYS } from "app/app.settings";
import { CookieService } from "ngx-cookie-service";
import { PaymentDataInterface } from "app/interfaces/payment-data.interface";
import {
    DepositLimitChangeRequest,
    DepositLimitsResponse
} from "app/interfaces/deposit-limits.interface";
import { CoinStatus } from "app/enums/coin-status.enum";
import { DepositResponseStatus } from "app/enums/DepositResponseStatus.enum";

export interface CashierServiceError {
    code: string;
    message: string;
}
@Injectable({
    providedIn: "root"
})
export class CashierApiService {
    private baseURL =
        this.environmentService.CASHIER_SERVICE_BASE_URL + "/api/v1";

    private defaultAPIError: CashierServiceError = {
        code: "Error",
        message:
            "Unfortunately an error has occurred, please try again and if the error persists contact support."
    };
    public playerData: GetPlayerResponse;
    public playerData$: Observable<GetPlayerResponse>;
    public availableToDepositDay: number;
    public availableToDepositWeek: number;
    public availableToDepositMonth: number;

    constructor(
        private playerSessionService: PlayerSessionService,
        private http: HttpClient,
        private cookieService: CookieService,
        private loggerService: LoggerService,
        private errorService: ErrorService,
        private environmentService: EnvironmentService
    ) {
        this.playerData$ = this.getPlayer();
    }

    private get authHeader(): HttpHeaders {
        return new HttpHeaders({
            Authorization: "Bearer " + this.playerSessionService.token
        });
    }

    /**
     * For now we're expecting two types of responses on successful deposits,
     * Better to map it into one we'd rather handle
     * @param coinDepositResponse
     * @returns coinDepositResponse/DepositResponse
     */
    public static standardiseDepositResponse(
        depositResponse
    ): Partial<DepositResponse> | any {
        if (depositResponse?.Status == CoinStatus.Success) {
            return {
                status: DepositResponseStatus.S_SUCCESS_SETTLED,
                amount: depositResponse?.Amount,
                bonusAmount: depositResponse?.Bonus,
                vfBingoBonusPrize1Amount:
                    depositResponse?.VFBingoBonusPrize1Amount,
                freeSpins: depositResponse?.FreeSpins,
                freeCards: depositResponse?.FreeCards,
                freeGamesPrize1Amount: depositResponse?.FreeGamesPrize1Amount,
                freeGamesPrize2Amount: depositResponse?.FreeGamesPrize2Amount
            };
        }
        return depositResponse;
    }

    /**
     * isErrorResponse
     * @param cashierServiceResponse
     * @param safeChargeResponse
     * @returns boolean
     */
    public static isErrorResponse(
        cashierServiceResponse,
        safeChargeResponse = null
    ): boolean {
        const evalResult =
            (!!cashierServiceResponse && !!cashierServiceResponse?.code) ||
            (cashierServiceResponse &&
                cashierServiceResponse?.Status == "-1") ||
            (!!cashierServiceResponse && !!cashierServiceResponse?.errors) ||
            (!!safeChargeResponse && safeChargeResponse.result !== "APPROVED");
        return evalResult;
    }

    /**
     * Updates the specified account.
     * Currently only supports disabling of the account.
     * @param accountId
     * @returns void
     */
    public disableAccount(accountId: number): Observable<void> {
        const body = {
            status: 0
        };

        return this.http
            .patch<void>(this.baseURL + `/account/${accountId}`, body, {
                headers: this.authHeader
            })
            .pipe(
                tap((response) =>
                    this.logResponse("Disable Account ", response)
                ),
                catchError((error: HttpErrorResponse) => {
                    this.logError("Disable Account ", error?.error);
                    return throwError(error);
                })
            );
    }

    /**
     * Returns the fields required to communicate with Nuvei's WebSDK JS library to tokenize card data.
     * @returns TokenizedCardResponse
     */
    public tokenizeCard(): Observable<TokenizedCardResponse> {
        return this.http
            .post<TokenizedCardResponse>(
                this.baseURL + "/account/tokenization-session",
                {
                    headers: this.authHeader
                }
            )
            .pipe(
                tap((resp) => {
                    this.logResponse("Tokenized Card ", resp);
                }),
                catchError((error: HttpErrorResponse) => {
                    const cashierServiceError: CashierServiceError =
                        error?.error || this.defaultAPIError;
                    this.logError("Tokenized Card ", cashierServiceError);

                    return throwError(cashierServiceError);
                })
            );
    }

    /**
     * Called after initializing a tokenization-session and invoking Nuvei's WebSDK JS library.
     * Only supports creation of card accounts. APM accounts are created during deposit.
     * @param gatewayEnc received from TokenizedCardResponse
     * @returns AddAccountResponse
     */
    public AddAccount(gatewayEnc: string): Observable<AddAccountResponse> {
        const body = { gatewayEnc: gatewayEnc };

        return this.http
            .post<AddAccountResponse>(this.baseURL + "/account/", body, {
                headers: this.authHeader
            })
            .pipe(
                tap((resp) => {
                    this.logResponse("Add Account ", resp);
                }),
                catchError((error: HttpErrorResponse) => {
                    const cashierServiceError: CashierServiceError =
                        error?.error || this.defaultAPIError;

                    this.logError("Add Account ", cashierServiceError);
                    return throwError(cashierServiceError);
                })
            );
    }

    /**
     * Get players deposit Limits
     * @returns DepositLimitsResponse
     */
    public getDepositLimits(): Observable<DepositLimitsResponse> {
        return this.http
            .get<DepositLimitsResponse>(this.baseURL + "/deposit-limits", {
                headers: this.authHeader
            })
            .pipe(
                tap((resp) => {
                    this.logResponse("Get DepositLimits Response  ", resp);
                    this.availableToDepositDay =
                        resp.lockedDepositLimitPerDay - resp.totalDepositsDay;
                    this.availableToDepositWeek =
                        resp.lockedDepositLimitPerWeek - resp.totalDepositsWeek;
                    this.availableToDepositMonth =
                        resp.lockedDepositLimitPerMonth -
                        resp.totalDepositsMonth;
                }),
                catchError((error: HttpErrorResponse) => {
                    this.logError("Get Deposit Limits ", error?.error);
                    const depositLimitsError = {
                        errorTitle: "Unable to Retrieve Deposit Limits",
                        StatusMsg:
                            error?.error?.message ||
                            this.defaultAPIError.message
                    };
                    this.errorService.catch(depositLimitsError);
                    return EMPTY;
                })
            );
    }

    /**
     * Gets the list of available account types for the authenticated player
     * @returns GetAccountTypesResponse
     */
    public getAccountTypes(): Observable<GetAccountTypesResponse> {
        return this.http
            .get<GetAccountTypesResponse>(this.baseURL + `/account/types`, {
                headers: this.authHeader
            })
            .pipe(
                tap((resp) => {
                    this.logResponse("Get Account Types ", resp);
                }),
                catchError((error: HttpErrorResponse) => {
                    const cashierServiceError: CashierServiceError =
                        error?.error || this.defaultAPIError;
                    this.logError("Get Account Types ", cashierServiceError);
                    return throwError(cashierServiceError);
                })
            );
    }

    /**
     * Gets the list of available accounts for the authenticated player
     * @returns GetAccountTypesResponse
     */
    public getCustomerAccounts(): Observable<GetCustomerAccountsResponse> {
        const unfilteredCustomerAccounts = this.http
            .get<GetCustomerAccountsResponse>(this.baseURL + `/account`, {
                headers: this.authHeader
            })
            .pipe(
                tap((resp) => {
                    this.logResponse("Get Customer Accounts: ", resp);
                    const activeAccounts = resp.accounts.filter(
                        (a) => a.expireStatus !== ExpireStatus.Expired
                    );
                    if (
                        activeAccounts.length === this.playerData.activeAccounts
                    ) {
                        return;
                    }

                    this.loggerService.warn(
                        "Get Customer Accounts: Active Accounts do not match Start Data ActiveAccounts",
                        {
                            CountFromStartResponse:
                                this.playerData.activeAccounts,
                            CountFromGetCustomerAccounts: activeAccounts.length
                        }
                    );
                }),
                catchError((error: HttpErrorResponse) => {
                    this.logError("Get Customer Accounts ", error?.error);
                    const getAccountsError = {
                        errorTitle: "No Active accounts",
                        StatusMsg:
                            error?.error?.message ||
                            this.defaultAPIError.message
                    };
                    this.errorService.catch(getAccountsError);
                    return EMPTY;
                })
            );

        return unfilteredCustomerAccounts;
    }

    /**
     * Receives user limits as a param and calls the API to set the limits
     * @returns void
     */
    public setDepositLimits(
        changeAction: DepositLimitChangeRequest
    ): Observable<void> {
        return this.http
            .post<void>(this.baseURL + "/deposit-limits", changeAction, {
                headers: this.authHeader
            })
            .pipe(
                tap(() =>
                    this.logResponse("Set Deposit Limits: ", changeAction)
                ),
                catchError((error: HttpErrorResponse) => {
                    this.logError("Set Deposit Limits Error ", error?.error);
                    const setDepositLimitsError = {
                        errorTitle: "Deposit Limits Error",
                        StatusMsg:
                            error?.error?.message ||
                            this.defaultAPIError.message
                    };
                    return throwError(setDepositLimitsError);
                })
            );
    }

    /**
     * Initiates a deposit request, can be different behaviours based on the payment method
     * @param depositRequestData
     * @returns DepositResponse
     */
    public initiateDeposit(
        depositRequestData: DepositStartRequest
    ): Observable<DepositResponse> {
        return this.http
            .post<DepositResponse>(
                this.baseURL + "/deposit",
                depositRequestData,
                {
                    headers: this.authHeader
                }
            )
            .pipe(
                tap((depositResponse) =>
                    this.logResponse("Initiate Deposit ", depositResponse)
                ),
                catchError((error: HttpErrorResponse) => {
                    const cashierServiceError: CashierServiceError =
                        error?.error || this.defaultAPIError;
                    this.logError(
                        "initiate Deposit Error ",
                        cashierServiceError
                    );
                    return throwError(cashierServiceError);
                })
            );
    }

    /**
     * Concludes the deposit for Apple Pay, we have additional fields
     * last4Digits and userPaymentOptionId
     * @param DepositEndRequest
     * @returns DepositResponse
     */
    public finaliseDeposit(
        depositEndRequest: DepositEndRequest
    ): Observable<DepositResponse> {
        return this.http
            .patch<DepositResponse>(
                this.baseURL + `/deposit/${depositEndRequest.txDepositId}`,
                depositEndRequest,
                {
                    headers: this.authHeader
                }
            )
            .pipe(
                tap((depositResponse) =>
                    this.logResponse("Finalise Deposit ", depositResponse)
                ),
                catchError((error: HttpErrorResponse) => {
                    const cashierServiceError: CashierServiceError =
                        error?.error || this.defaultAPIError;
                    this.logError(
                        "Finalise Deposit Error ",
                        cashierServiceError
                    );
                    return throwError(cashierServiceError);
                })
            );
    }

    /**
     * This is a utility just to get the formdata to DepositStartRequest
     * @param paymentData is essentially just formdata
     * @returns DepositStartRequest
     */
    public initiateAPMDeposit(
        paymentData: PaymentDataInterface
    ): Observable<DepositResponse> {
        const depositData: DepositStartRequest = {
            amount: paymentData.amt,
            accountTypeCode: paymentData.acctType,
            ukgcAck: Boolean(paymentData.ukgcAck),
            fastPay: Boolean(paymentData.fastpay),
            paypalRiskSessionCorrelationId: paymentData.RISKCORRELATIONID,
            device: this.playerSessionService.device,
            webView: this.playerSessionService.isWebView,
            isOffer: paymentData.isOffer
        };

        /**
         * Optional DepositStartRequest params below
         */
        if (paymentData.acctID)
            depositData.accountId = parseInt(paymentData.acctID);
        if (paymentData.promoCode) {
            depositData.promoCode = paymentData.promoCode;
        } else if (paymentData.promoID) {
            depositData.promoId = parseInt(paymentData.promoID.toString());
        }

        return this.http
            .post<DepositResponse>(this.baseURL + "/deposit", depositData, {
                headers: this.authHeader
            })
            .pipe(
                tap((depositResponse) => {
                    if (!depositResponse) return;
                    this.cookieService.set(
                        APP_COOKIE_KEYS.COIN_REF,
                        depositResponse.txDepositId.toString(),
                        null,
                        "/",
                        null,
                        true,
                        "None"
                    );
                    this.logResponse("APMDeposit ", depositResponse);
                }),
                catchError((error: HttpErrorResponse) => {
                    const cashierServiceError: CashierServiceError =
                        error?.error || this.defaultAPIError;
                    this.logError("APMDeposit ", cashierServiceError);
                    return throwError(cashierServiceError);
                })
            );
    }

    /**
     * Performs a Withdraw Request
     * @param amount number
     * @returns WithdrawResponse
     */
    public withdraw(amount: number): Observable<WithdrawResponse> {
        const body = { amount: amount };
        return this.http
            .post<WithdrawResponse>(this.baseURL + "/withdrawal/", body, {
                headers: this.authHeader
            })
            .pipe(
                tap((resp) => {
                    this.logResponse("Withdrawal Response  ", resp);
                }),
                catchError((error: HttpErrorResponse) => {
                    const cashierServiceError: CashierServiceError =
                        error?.error || this.defaultAPIError;
                    this.logError("Withdrawal Error ", cashierServiceError);
                    return throwError(cashierServiceError);
                })
            );
    }

    /**
     *
     * @param includeAccounts
     * @returns
     */
    public getPlayer(includeAccounts = false): Observable<GetPlayerResponse> {
        return this.http
            .get<GetPlayerResponse>(
                this.baseURL + `/player?inclAccounts=${includeAccounts}`,
                {
                    headers: this.authHeader
                }
            )
            .pipe(
                tap((playerResponse) => {
                    this.loggerService.warn(
                        `TU Status ${playerResponse.transunionStatus}`,
                        {
                            TUStatus: playerResponse?.transunionStatus ?? "n/a"
                        }
                    );
                    this.playerData$ = of(playerResponse);
                    this.playerData = playerResponse;
                    this.setGlobalDataCookies(playerResponse);
                    this.logResponse("Get Player ", playerResponse);
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error?.error?.code == "FAIL_CUSTOMER_STATUS") {
                        this.errorService.catch({
                            errorTitle: "Account in review",
                            message:
                                "Your account is under review. We will get in touch soon."
                        });
                    } else {
                        const cashierServiceError: CashierServiceError =
                            error?.error || this.defaultAPIError;
                        this.errorService.catch(cashierServiceError);
                    }
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    /**
     * Checks if the Player has an already determined TU Status
     * Will only return true if the player has a TU status
     *
     * so it will return false if the value is null, undefined, 0 (TU Error)
     * @private
     * @returns boolean
     */
    public get hasTUStatus(): boolean {
        return (
            [TUStatus.RED, TUStatus.AMBER, TUStatus.GREEN].indexOf(
                this.playerData.transunionStatus
            ) !== -1
        );
    }

    private setGlobalDataCookies(data: GetPlayerResponse): void {
        this.playerSessionService.currencySign =
            data?.currencyCode === CurrencyISOCode.EURO ? "€" : "£";
        this.playerSessionService.customerID = data?.customerId;
        this.playerSessionService.ukgcAck = data?.deposit?.requiresPopfAck;
        this.playerSessionService.depositCount = +data?.deposit.depositCount;
    }

    /**
     * Just a central call to log API errors
     * @param errorOrigin
     * @param error CashierServiceError
     */
    private logError(errorOrigin: string, error: CashierServiceError): void {
        this.loggerService.error(errorOrigin, error);
    }

    /**
     * Just a central call to log responses from the API
     * @param origin
     * @param data
     */
    private logResponse(origin, data): void {
        this.loggerService.info(origin, data);
    }
}
