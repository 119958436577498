import { Component, ElementRef, ViewChild } from "@angular/core";
import {
    CoinAccount,
    GetCustomerAccountsResponse
} from "app/interfaces/accounts.interface";
import { paths } from "app/paths";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { APP_ERROR_MESSAGES } from "app/app.settings";
import { AccountTypeCode } from "app/enums/account-type-code.enum";

@Component({
    selector: "app-payment-method-dropdown",
    templateUrl: "./payment-method-dropdown.component.html",
    styleUrls: ["./payment-method-dropdown.component.scss"]
})
export class PaymentMethodDropdownComponent {
    public myAccountsURL = `/${paths.BASE}/${paths.MY_ACCOUNTS}`;
    public hasError = false;
    public accountsResponse$: Observable<GetCustomerAccountsResponse>;
    public selectedAccount$: Observable<CoinAccount>;
    public selectedAccount: CoinAccount;
    public errorMessage = APP_ERROR_MESSAGES.NO_PAYMENT_METHODS_AVAILABLE;

    @ViewChild("dropdown") dropdown: ElementRef<HTMLDivElement>;

    constructor(private paymentMethodsService: PaymentMethodsService) {
        this.accountsResponse$ = this.paymentMethodsService
            .get({ excludeExpired: true })
            .pipe(
                tap((resp) => {
                    if (resp?.code) this.hasError = true;
                    if (this.hasError) {
                        this.toggle(true);
                    }
                    if (!this.paymentMethodsService.hasApplePay) {
                        resp.accounts = resp.accounts.filter((account) => {
                            return (
                                account.accountTypeCode !==
                                AccountTypeCode.ApplePay
                            );
                        });
                    } else {
                        /**
                         * Will need to create a new array
                         * because we are splicing
                         */
                        const filteredArray = resp.accounts.reduce(
                            (acc, account) => {
                                if (
                                    account.accountTypeCode ==
                                    AccountTypeCode.ApplePay
                                ) {
                                    const existingApplePayAccount =
                                        acc.findIndex(
                                            (item) =>
                                                item.accountTypeCode ===
                                                account.accountTypeCode
                                        );
                                    if (existingApplePayAccount !== -1) {
                                        if (
                                            account.lastSuccessfulDepositDate &&
                                            (!acc[existingApplePayAccount]
                                                .lastSuccessfulDepositDate ||
                                                account.lastSuccessfulDepositDate >
                                                    acc[existingApplePayAccount]
                                                        .lastSuccessfulDepositDate)
                                        ) {
                                            acc.splice(
                                                existingApplePayAccount,
                                                1
                                            );
                                            acc.push({ ...account });
                                        }
                                    } else {
                                        acc.push({ ...account });
                                    }
                                } else {
                                    acc.push({ ...account });
                                }
                                return acc;
                            },
                            []
                        );
                        resp.accounts = filteredArray;
                    }
                })
            );
        this.selectedAccount$ = this.paymentMethodsService.selected$.pipe(
            tap((account) => {
                this.selectedAccount = account;
            })
        );
    }

    /**
     * @param account   CoinAccount
     */
    public selectAccount(account: CoinAccount): void {
        this.selectedAccount = account;
        this.paymentMethodsService.select(account);
    }

    private toggle(forceOpen: boolean): void {
        this.dropdown.nativeElement.toggleAttribute("hidden", !forceOpen);
    }
}
