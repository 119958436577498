<form [formGroup]="formGroup" class="position-relative">
    <section class="d-flex flex-column mb-3">
        <div class="section-title font-family-bold m-0">Card Number</div>
        <div
            id="cardNumber"
            class="form-field"
            [ngClass]="{
                invalid:
                    cardNumberField.validation !== undefined &&
                    cardNumberField.validation.error !== undefined
            }"
        ></div>
        <ng-container
            *ngIf="
                cardNumberField.validation !== undefined &&
                cardNumberField.validation.error !== undefined
            "
        >
            <ng-container
                [ngTemplateOutlet]="tplValidation"
                [ngTemplateOutletContext]="{
                    error: cardNumberField.validation.error
                }"
            ></ng-container>
        </ng-container>
    </section>

    <section class="d-flex flex-column mb-3">
        <div class="section-title font-family-bold m-0">Name on Card</div>
        <app-form-field
            [invalid]="
                formGroup.controls.cardHolder.dirty &&
                formGroup.controls.cardHolder.invalid
            "
            formControlName="cardHolder"
        >
            <span
                slot="validation-error-message"
                *ngIf="formGroup.controls.cardHolder.errors"
            >
                <ng-container
                    *ngIf="
                        formGroup.controls.cardHolder.errors.consecutiveNumbers;
                        else defaultError
                    "
                >
                    {{
                        formGroup.controls.cardHolder.errors.consecutiveNumbers
                            .message
                    }}
                </ng-container>
                <ng-template #defaultError
                    >Cardholder Name is required</ng-template
                >
            </span>
        </app-form-field>
    </section>

    <section class="row">
        <div class="col">
            <label
                for="cardExpiry"
                class="section-title font-family-bold m-0 form-label"
                >Expiry Date</label
            >
            <div
                id="cardExpiry"
                class="form-field"
                [ngClass]="{
                    invalid:
                        expiryField.validation !== undefined &&
                        expiryField.validation.error !== undefined
                }"
            ></div>
            <ng-container
                *ngIf="
                    expiryField.validation !== undefined &&
                    expiryField.validation.error !== undefined
                "
            >
                <ng-container
                    [ngTemplateOutlet]="tplValidation"
                    [ngTemplateOutletContext]="{
                        error: expiryField.validation.error
                    }"
                ></ng-container>
            </ng-container>
        </div>

        <div class="col" id="cvv-column">
            <label
                for="cardCVV"
                class="section-title font-family-bold m-0 cvv-label form-label"
            >
                <span>CVV</span>
                <span cashierIcon="cvv"></span>
            </label>
            <div
                id="cardCVV"
                class="form-field"
                [ngClass]="{
                    invalid:
                        cvvField.validation !== undefined &&
                        cvvField.validation.error !== undefined
                }"
            ></div>
            <ng-container
                *ngIf="
                    cvvField.validation !== undefined &&
                    cvvField.validation.error !== undefined
                "
            >
                <ng-container
                    [ngTemplateOutlet]="tplValidation"
                    [ngTemplateOutletContext]="{
                        error: cvvField.validation.error
                    }"
                ></ng-container>
            </ng-container>
        </div>
    </section>
    <section class="mt-3">
        <p>
            Upon adding your Card, you may be asked to authenticate by your
            bank.
        </p>
    </section>

    <section class="mt-3">
        <app-cashier-button #btnSubmit (click)="submitForm()"
            >Continue</app-cashier-button
        >
    </section>
</form>
<ng-template #tplValidation let-error="error">
    <div class="small w-100 text-danger validation">
        <span cashierIcon="warning" class="icon error"></span>
        <span class="text">{{ error.message || "Invalid" }}</span>
    </div>
</ng-template>
