<form [formGroup]="formGroup">
    <section id="amount-tiles">
        <div class="section-title">
            <span class="font-family-bold">Amount</span>&nbsp;
            <app-deposit-range-label
                [minAllowed]="minAllowed"
                [maxAllowed]="maxAllowed"
            ></app-deposit-range-label>
        </div>
        <app-amount-buttons
            formControlName="amt"
            [value]="formGroup.controls.amt.value"
        ></app-amount-buttons>
    </section>

    <app-form-field
        formControlName="amt"
        [invalid]="
            formGroup.controls.amt.dirty && formGroup.controls.amt.invalid
        "
        placeholder="Other (Min {{ minAllowed | playerCurrency }})"
        [value]="formGroup.value.amt"
        #formfieldComponent
    >
        <span slot="icon">{{ currency }}</span>
        <span slot="validation-error-message">
            <ng-container *ngIf="formGroup.controls.amt?.errors">
                <ng-container
                    *ngIf="
                        formGroup.controls.amt.errors?.pattern;
                        else minMaxError
                    "
                >
                    <ng-container
                        *ngTemplateOutlet="defaultError"
                    ></ng-container>
                </ng-container>
                <ng-template #minMaxError>
                    <ng-container
                        *ngIf="
                            formGroup.controls.amt.errors?.minMax;
                            else breachedLimits
                        "
                    >
                        {{ formGroup.controls.amt.errors.minMax.message }}
                    </ng-container>
                </ng-template>
                <ng-template #breachedLimits>
                    <ng-container
                        *ngIf="
                            formGroup.controls.amt.errors?.limits;
                            else breachedAffordabilityLimits
                        "
                    >
                        {{ formGroup.controls.amt.errors.limits.message }}
                    </ng-container>
                </ng-template>
                <ng-template #breachedAffordabilityLimits>
                    <ng-container
                        *ngIf="
                            formGroup.controls.amt.errors?.affordabilityLimit;
                            else bonusChecks
                        "
                    >
                        <app-affordability-deposit-error></app-affordability-deposit-error>
                    </ng-container>
                </ng-template>
                <ng-template #bonusChecks>
                    <ng-container
                        *ngIf="
                            formGroup.controls.amt.errors?.depositWithBonus;
                            else defaultError
                        "
                    >
                        {{
                            formGroup.controls.amt.errors.depositWithBonus
                                .message
                        }}
                    </ng-container>
                </ng-template>
                <ng-template #defaultError> Enter a valid amount </ng-template>
            </ng-container>
        </span>
    </app-form-field>

    <section class="mt-4">
        <app-insolvency-acknowledgement
            formControlName="ukgcAck"
        ></app-insolvency-acknowledgement>
    </section>

    <section class="mt-3" *ngIf="enableSubmitButton">
        <div class="flex-grow-1">
            <app-cashier-button
                (click)="initiateDeposit()"
                [disabled]="
                    formGroup.invalid ||
                    (initSubscription && !initSubscription.closed) ||
                    initiatingSDK
                "
                #submitButton
            >
                Deposit
                <span *ngIf="formGroup.controls.amt.valid">
                    {{ formGroup.value.amt | playerCurrency }}
                    <img
                        *ngIf="formGroup.controls.amt.valid && initiatingSDK"
                        src="assets/images/spinner-busy.svg"
                        class="animate spin"
                    />
                </span>
            </app-cashier-button>

            <span
                *ngIf="
                    this.showApplePayPrompt && !this.currentSellectedApplePay
                "
            >
                <div class="sectional-title text-center">
                    <span class="font-family-bold">or</span>
                </div>
                <app-cashier-button
                    theme="prompt-apple-pay"
                    (click)="navigateToApplePaySelectedHandler()"
                >
                    <span>
                        Deposit with
                        <img src="assets/images/apple_pay_mark_button.svg" />
                    </span>
                </app-cashier-button>
            </span>
        </div>
    </section>
</form>
