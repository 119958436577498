<div class="d-flex flex-row align-items-center border-bottom mb-3 pb-2">
    <app-account-type-logo
        [code]="'A_VISA_DEBIT'"
        size="large"
        class="mr-3"
    ></app-account-type-logo>
    <app-account-type-logo
        [code]="'A_MASTERCARD_DEBIT'"
        size="large"
        class="mr-3"
    ></app-account-type-logo>
</div>
<p>
    You are agreeing to the secure storing of your card details for future use.
    <a href="javascript:void(0)" [routerLink]="[aboutStoringLink]">More</a>
</p>

<app-nuvei-cde-form></app-nuvei-cde-form>
