import { Component, Input } from "@angular/core";
import { DepositLimitsResponse } from "app/interfaces/deposit-limits.interface";
import { PlayerService } from "app/services/player/player.service";

@Component({
    selector: "app-deposit-limits-applied-info",
    styleUrls: ["./deposit-limits-applied-info.component.scss"],
    templateUrl: "./deposit-limits-applied-info.component.html"
})
export class DepositLimitsAppliedInfoComponent {
    @Input() limitsResponse: DepositLimitsResponse;
    public readonly playerTUIsAmber = this.playerService.isAmber;
    constructor(private playerService: PlayerService) {}
}
