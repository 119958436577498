import { Component } from "@angular/core";
import { SkinService } from "app/services/skin/skin.service";

@Component({
    selector: "app-explain-net-deposit-loss-limit",
    styleUrls: ["./explain-net-deposit-loss-limit.component.scss"],
    templateUrl: "./explain-net-deposit-loss-limit.component.html"
})
export class ExplainNetDepositLossLimitComponent {
    public readonly domainNames: string[] = SkinService.domainNames;
}
