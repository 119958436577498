import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { paths } from "app/paths";
import { CoinAccount } from "app/interfaces/accounts.interface";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { Params, Router } from "@angular/router";
import { NgIfContext } from "@angular/common";
import { DepositService } from "app/services/deposit/deposit.service";
import { SafeChargeService } from "app/services/safe-charge/safe-charge.service";

const enum DeclineRecoveryErrorDictionary {
    PaymentMethodUnderReview,
    CardExpired,
    CVV,
    DoNotHonour,
    InsufficientFunds
}

interface RecoveryOption {
    title?: string;
    templateRef?: TemplateRef<NgIfContext>;
    routerLink: string;
    queryParams?: Params;
    conditions?: boolean[];
    rank?: number;
    /**
     * Specific options are those specific to an error
     * Generic options are those that do not pertain to a specific error
     */
    category: "specific" | "generic";
}

@Component({
    selector: "app-deposit-response-error",
    templateUrl: "./deposit-response-error.component.html"
})
export class DepositResponseErrorComponent implements OnInit, AfterViewInit {
    public depositPageURL = `/${paths.BASE}/${paths.DEPOSIT}`;
    @Input() coinResponse: any;
    @Input() safeChargeResponse: any;
    @Input() transactionAmount: number;

    public title = "Deposit Unsuccessful";
    public description: string;
    public alternateAccount: CoinAccount;
    public recoveryOptions: Promise<RecoveryOption[]>;

    private _statusCode: DeclineRecoveryErrorDictionary;
    private get statusCode(): DeclineRecoveryErrorDictionary {
        if (this._statusCode !== undefined) {
            return this._statusCode;
        }
        return (this._statusCode = this.getStatusCode());
    }

    @ViewChild("tplDepositUsingAlternateMethod")
    tplDepositUsingAlternateMethod: TemplateRef<NgIfContext>;

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private readonly depositService: DepositService,
        private readonly _safeChargeService: SafeChargeService,
        private _router: Router
    ) {}

    public ngOnInit(): void {
        this.calcDisplayText();
        this.recoveryOptions = this.calcRecoveryOptions();
    }

    public ngAfterViewInit(): void {
        this.checkForAlternatePaymentMethodOption();
    }

    /**
     * Checks if the Player has an Alternate Payment Method
     * If so, add it to the List of Recovery options
     * @private
     */
    private checkForAlternatePaymentMethodOption() {
        this.paymentMethodsService
            .getAlternate(this.paymentMethodsService.currentSelected, {
                useCache: true
            })
            .subscribe((altAcc) => {
                /**
                 * If no alternate account is available,
                 * bail
                 */
                if (!altAcc) {
                    return;
                }

                this.alternateAccount = altAcc;
                this.recoveryOptions.then((options: RecoveryOption[]) => {
                    const alternateAccOption: RecoveryOption = {
                        templateRef: this.tplDepositUsingAlternateMethod,
                        routerLink: this.depositPageURL,
                        category: "generic",
                        rank: 2,
                        conditions: [
                            this.paymentMethodsService.activeCount > 1,
                            !!this.alternateAccount
                        ]
                    };

                    options.push(alternateAccOption);

                    this.recoveryOptions = new Promise<RecoveryOption[]>(
                        (resolve) => resolve(this.getTop3Options(options))
                    );
                });
            });
    }

    /**
     * Because coin reuses error codes,
     * the check below will help differentiate
     * based on the status code and status message
     *
     * @returns DeclineRecoveryErrorDictionary
     * @private
     */
    private getStatusCode(): DeclineRecoveryErrorDictionary {
        let errorStatus = DeclineRecoveryErrorDictionary.DoNotHonour;

        /**
         * When checking the SafeCharge response
         * We compare strings because their error codes are not unique
         */
        if (
            this.safeChargeResponse &&
            this.safeChargeResponse.errorDescription
        ) {
            if (
                this._safeChargeService.isRecoverableDepositError(
                    this.safeChargeResponse.errorDescription
                )
            ) {
                errorStatus = DeclineRecoveryErrorDictionary.CVV;
            } else if (
                this._safeChargeService.insufficientErrors.includes(
                    this.safeChargeResponse.errorDescription
                )
            ) {
                errorStatus = DeclineRecoveryErrorDictionary.InsufficientFunds;
            } else if (
                this._safeChargeService.expiredErrors.includes(
                    this.safeChargeResponse.errorDescription
                )
            ) {
                errorStatus = DeclineRecoveryErrorDictionary.CardExpired;
            } else if (
                this._safeChargeService.underReviewErrors.includes(
                    this.safeChargeResponse.errorDescription
                )
            ) {
                errorStatus =
                    DeclineRecoveryErrorDictionary.PaymentMethodUnderReview;
            }

            return errorStatus;
        }

        /**
         * This block is needed in the event there is a API Response but not a SafeCharge Response
         * e.g. when using APMs
         */
        if (
            (this.coinResponse && this.coinResponse?.message) ||
            this.coinResponse?.StatusMsg
        ) {
            const statusMessage =
                this.coinResponse?.message.toLowerCase() ||
                this.coinResponse?.StatusMsg;
            if (statusMessage.indexOf("cvn") !== -1) {
                errorStatus = DeclineRecoveryErrorDictionary.CVV;
            } else if (statusMessage.indexOf("insufficient") !== -1) {
                errorStatus = DeclineRecoveryErrorDictionary.InsufficientFunds;
            } else if (statusMessage.indexOf("expired") !== -1) {
                errorStatus = DeclineRecoveryErrorDictionary.CardExpired;
            } else if (statusMessage.indexOf("under review") !== -1) {
                errorStatus =
                    DeclineRecoveryErrorDictionary.PaymentMethodUnderReview;
            }
        }

        return errorStatus;
    }

    /**
     * Examines the Deposit Response from COIN
     * and sets the relevant text for display to the user
     * @private
     */
    private calcDisplayText(): void {
        switch (this.statusCode) {
            case DeclineRecoveryErrorDictionary.CVV:
                this.title = "Incorrect CVV";
                this.description =
                    "The CVV number entered was incorrect. These are the last three digits on the back of your card.";
                break;
            case DeclineRecoveryErrorDictionary.InsufficientFunds:
                this.title = "Insufficient Funds";
                this.description =
                    "Your deposit was declined due to insufficient funds.";
                break;
            case DeclineRecoveryErrorDictionary.CardExpired:
                this.title = "Card Expired";
                this.description = "The card you are using has expired";
                break;
            case DeclineRecoveryErrorDictionary.PaymentMethodUnderReview:
                this.title = "Deposit Unsuccessful";
                break;
        }
    }

    /**
     * Calculates all Decline Recovery Options
     * and will return at most 3 options based on a variety of factors
     * @private
     */
    private async calcRecoveryOptions(): Promise<RecoveryOption[]> {
        return new Promise((resolve) => {
            const allOptions: RecoveryOption[] = [
                {
                    title: "Check your CVV and try again",
                    routerLink: this.depositPageURL,
                    category: "specific",
                    rank: 1,
                    conditions: [
                        this.statusCode === DeclineRecoveryErrorDictionary.CVV
                    ]
                },
                {
                    title: "Try again",
                    routerLink: this.depositPageURL,
                    category: "specific",
                    rank: 1,
                    conditions: [
                        this.statusCode ===
                            DeclineRecoveryErrorDictionary.DoNotHonour
                    ]
                },
                {
                    title: "Deposit a Lower Amount",
                    routerLink: this.depositPageURL,
                    category: "specific",
                    rank: 1,
                    conditions: [
                        this.statusCode ===
                            DeclineRecoveryErrorDictionary.InsufficientFunds,
                        this.transactionAmount > this.depositService.minAllowed
                    ]
                },
                {
                    title: "Add a new payment method",
                    routerLink: `/${paths.BASE}/${paths.ADD_ACCOUNT}`,
                    category: "generic",
                    rank: 3,
                    conditions: [!this.paymentMethodsService.maxReached]
                }
            ];

            /**
             * Go through all the options and return the options where all the conditions are true
             */
            const validOptions = allOptions.filter((option) => {
                if (!option.conditions || !option.conditions.length) {
                    return true;
                }

                return option.conditions.reduce((result, condition) => {
                    return result && condition;
                });
            });

            /**
             * Keep track of the specific options separately
             */
            const specificOptions = validOptions.filter(
                (i) => i.category === "specific"
            );

            /**
             * Keep track of generic options separately
             */
            const genericOptions = validOptions.filter(
                (i) => i.category === "generic"
            );

            const finalOptions = this.getTop3Options([
                ...specificOptions,
                ...genericOptions
            ]);

            /**
             * In the event there are no options available to the player,
             * Give them an option to manage their payment methods
             */
            if (finalOptions.length === 0) {
                finalOptions.push({
                    title: "Manage payment methods",
                    routerLink: `/${paths.BASE}/${paths.MY_ACCOUNTS}`,
                    category: "generic"
                });
            }

            resolve(finalOptions);
        });
    }

    /**
     * Sort by rank then take the first 3 options
     */
    private getTop3Options(options: RecoveryOption[]): RecoveryOption[] {
        return options.sort((a, b) => (a.rank < b.rank ? -1 : 1)).slice(0, 3);
    }

    /**
     * Used when the player clicks on 'Deposit Using xxx' alternate account
     * @param account
     * @private
     */
    public selectAccount(account: CoinAccount): void {
        this.paymentMethodsService.select(account);
        this._router.navigate([this.depositPageURL]);
    }
}
