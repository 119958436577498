<div class="mt-2" *ngIf="enabled && (routeEnabled$ | async)">
    <ng-container *ngFor="let link of allowedLinks">
        <a
            [routerLink]="link.path"
            href="#"
            class="mb-4 d-block text-center font-family-bold"
        >
            {{ link.text }}
        </a>
    </ng-container>
</div>
