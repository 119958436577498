<div *ngIf="withdrawDetail | async as initResp">
    <section class="mb-3" id="balance">
        <div class="section-title font-family-bold mb-1">
            Available balance:
        </div>
        <div class="d-flex flex-wrap align-items-baseline">
            <span class="font-family-bold h5 m-0" id="available-amount">{{
                initResp.AvailableToWithdraw || 0.0 | playerCurrency
            }}</span>
            <p
                class="small ml-auto mb-0"
                id="bonus-funds"
                *ngIf="initResp?.BonusToBeForfeited"
            >
                (excluding {{ initResp.BonusToBeForfeited | playerCurrency }} of
                bonus funds)
            </p>
        </div>
    </section>

    <div class="d-flex align-items-center flex-column">
        <app-info-panel>
            <p>
                Depending on your payment method, most withdrawals reach you in
                a few minutes, however, this can take 1 to 3 working days.
            </p>
            <p class="text-danger">
                Withdrawals cannot be reversed once requested.
            </p>
        </app-info-panel>

        <app-withdraw-form
            class="w-100"
            [ngClass]="{
                'my-4': accountSelectionAllowed,
                'mt-4': !accountSelectionAllowed
            }"
            [withdrawDetails]="initResp"
            #withdrawForm
        ></app-withdraw-form>

        <app-info-panel *ngIf="accountSelectionAllowed" icon="none">
            <div slot="header" class="font-italic">
                <span class="mr-2">
                    <span cashierIcon="credit_card_fast"></span>
                </span>
                <span class="font-family-bold">Faster withdrawals!</span>
            </div>
            <p>
                Cards with this icon next to it means your card is enabled for
                faster withdrawals.<br />T&Cs apply.
            </p>
        </app-info-panel>

        <app-cashier-button
            class="w-100"
            [ngClass]="{ 'mt-4': accountSelectionAllowed }"
            [disabled]="
                withdrawForm.formGroup.invalid || withdrawForm.submitting
            "
            (click)="withdrawForm.submit()"
        >
            {{ withdrawForm.submitting ? "Wait..." : "Continue" }}
        </app-cashier-button>
    </div>
</div>
