import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { PlayerSessionService } from "app/services/player-session/player-session.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private _playerSessionService: PlayerSessionService) {}

    /**
     * Interceptor to add the token to the header
     * @param request
     * @param next
     */
    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            this._playerSessionService.token &&
            !request.url.includes("get.geojs.io/v1/") &&
            !request.url.includes("/csi/")
        ) {
            request = request.clone({
                setHeaders: {
                    authorization: "Bearer " + this._playerSessionService.token
                }
            });
        }
        return next.handle(request);
    }
}
