import {
    Component,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DepositService } from "app/services/deposit/deposit.service";
import { PaymentMethodsService } from "app/services/payment-methods/payment-methods.service";
import { isMoneyFormat } from "app/validators/money-pattern.validator";

@Component({
    selector: "app-amount-buttons",
    templateUrl: "./amount-buttons.component.html",
    styleUrls: ["./amount-buttons.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AmountButtonsComponent),
            multi: true
        }
    ]
})
export class AmountButtonsComponent implements ControlValueAccessor, OnChanges {
    public options$ = this._paymentMethodsService.smartTiles$;
    @Input() value = 0;

    public disabled = false;

    onChange: any = () => {};
    onTouched: unknown = () => {};

    constructor(
        private _paymentMethodsService: PaymentMethodsService,
        private _depositService: DepositService
    ) {}

    /**
     * Called when an amount tile is clicked
     * @param option
     */
    public select(option: number): void {
        this.value = option;
        this.onChange(this.value);
    }

    /**
     * Checks if this value breaches the affordability limit
     * Used to disable an amount tile
     * @param amountButtonValue
     */
    public breachesAffordabilityLimit(amountButtonValue: number): boolean {
        return this._depositService.breachesAffordabilityLimit(
            amountButtonValue
        );
    }

    /**
     * Listen to changes from the outside to this component
     * @param change
     */
    public ngOnChanges(change: SimpleChanges): void {
        if (!change.value || !change.value.currentValue) {
            return;
        }
        this.writeValue(change.value.currentValue);
    }

    public writeValue(value: number): void {
        let valueToWrite = null;

        /**
         * Helps prevent processing values like
         * > 5.
         * > 5.0
         * > 05
         * > 05.
         */
        if (isMoneyFormat(value)) {
            valueToWrite = +value;
        }

        this.value = valueToWrite;
    }

    public registerOnChange(fn: unknown): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: unknown): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
