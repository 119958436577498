<div class="small">
    <span
        class="card-number-ending"
        *ngIf="
            (account.last4Digits &&
                account.accountTypeCode !== AccountTypeCode.ApplePay) ||
                (account.accountTypeCode == AccountTypeCode.ApplePay &&
                    showApplePayAccountNumber);
            else description
        "
    >
        <span class="position-relative mr-1">....</span
        >{{ account.last4Digits }}
    </span>
    <span
        *ngIf="
            showExpiry &&
            account.expiryDate &&
            account.accountTypeCode != AccountTypeCode.ApplePay
        "
        class="expiry ml-2"
        [ngClass]="{
            'font-family-bold text-danger':
                account.expireStatus === ExpireStatusEnum.Expired
        }"
    >
        <span class="mr-1">Exp:</span>{{ account.expiryDate }}
    </span>

    <ng-template #description>
        <div class="description">
            {{ account.description }}
        </div>
    </ng-template>
</div>
