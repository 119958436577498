<app-info-panel icon="warning">
    <ng-container
        *ngIf="
            response.allocations && response.allocations.length;
            else noAllocationText
        "
    >
        <p>Your withdrawal will be allocated to:</p>
        <p>
            <ng-container
                *ngFor="let item of response.allocations; let first = first"
            >
                <span>
                    {{ item.accountTypeDescription }}
                    <span *ngIf="item.accountNumber"
                        >(x{{ item.accountNumber }})</span
                    >
                    -
                    <span class="font-family-bold withdrawal-alocation-param">{{
                        item.amount | playerCurrency
                    }}</span>
                </span>
                <br />
            </ng-container>
        </p>
        <p id="transactionReference" *ngIf="showTransactionReference">
            The transaction will appear as
            <span class="font-family-bold withdrawal-alocation-param"
                >"{{ transactionReference }}"</span
            >
            <span
                class="font-family-bold withdrawal-alocation-param"
                *ngIf="showAlternateReference"
            >
                or "RANK INTERACTIVE"</span
            >
            on your bank statement.
        </p>
    </ng-container>
</app-info-panel>

<ng-template #noAllocationText>
    <p>
        No refundable payment method registered. Please add a method so we can
        complete your withdrawal request
    </p>
</ng-template>
