import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: "app-info-panel",
    templateUrl: "./info-panel.component.html",
    styleUrls: ["./info-panel.component.scss"]
})
export class InfoPanelComponent {
    @Input() icon: "none" | "info" | "warning" = "info";
    @HostBinding("class") @Input() theme: "info" | "warning" = null;

    @Input() collapsible = false;
    public collapsed = true;

    private _contentClass: string;
    @Input()
    set contentClass(val: string) {
        this._contentClass = val;
    }
    get contentClass(): string {
        if (this._contentClass) {
            return this._contentClass;
        }
        return this.icon === "none" ? "ml-4" : "p-0";
    }

    /**
     * Used to toggle the visibility of the info-panel content
     */
    public toggleCollapse(): void {
        if (!this.collapsible) {
            return;
        }

        this.collapsed = !this.collapsed;
    }
}
