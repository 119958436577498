import { Component, Input, OnInit } from "@angular/core";
import { AddAccountResponse } from "app/interfaces/tokenize-card.interface";
import { paths } from "app/paths";
import { CashierService } from "app/services/cashier/cashier.service";

@Component({
    selector: "app-add-account-response-error",
    templateUrl: "./add-account-response-error.component.html"
})
export class AddAccountResponseErrorComponent implements OnInit {
    @Input() transactionResponse: AddAccountResponse;
    @Input() safeChargeResponse: any;

    public title = "Unable to add Payment Method";
    public description =
        "Unfortunately an error has occurred, please check that your card details are correct and if the error persists contact support.";
    public recoveryOptions = [
        {
            title: "Try again",
            routerLink: `/${paths.BASE}/${paths.ADD_ACCOUNT}`
        },
        {
            title: "Add a new payment method",
            routerLink: `/${paths.BASE}/${paths.ADD_ACCOUNT}`
        }
    ];

    constructor(private _cashierService: CashierService) {}

    ngOnInit(): void {
        /**
         * This handles the responses back from Cashier Service API and WebSDK
         * if WebSDK returns an error or no response we'll display the default error message
         * Else, we'll display the API error message if it exists or the default error message
         */
        if (!this.transactionResponse?.message || !this.safeChargeResponse) {
            return;
        }

        this.description = this.transactionResponse.message;
    }

    public closeCashier(event?: string): void {
        this._cashierService.closeCashier(event);
    }
}
