import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "app-form-field",
    templateUrl: "./form-field.component.html",
    styleUrls: ["./form-field.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormFieldComponent),
            multi: true
        }
    ]
})
export class FormFieldComponent implements ControlValueAccessor {
    @Input() type = "text";
    @Input() placeholder = "";
    @Input() maxLength: number;
    @Input() disabled = false;
    @Input() value: string | number = null;
    @Input() invalid = false;
    @Output() change = new EventEmitter<string | number>();

    onTouched: any = () => {};
    onChange: any = () => {};

    registerOnChange(fn: (val: string | number) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(incomingValue: string | number): void {
        this.value = incomingValue;
        this.onChange(this.value);
        this.change.emit(this.value);
    }
}
