<div class="px-2">
    <div class="my-4 d-flex justify-content-center">
        <app-account-type-logo
            [code]="accountCode"
            size="large"
        ></app-account-type-logo>
    </div>

    <app-info-panel icon="warning" class="d-flex my-4">
        <small
            >paysafe is a
            <span class="font-family-bold">non-refundable</span> payment method.
            So you can't use it to withdraw funds.</small
        >
    </app-info-panel>

    <p class="my-4">Want to continue?</p>

    <div class="container-fluid my-2">
        <div class="row">
            <div class="col">
                <app-cashier-button theme="primary-outline" appButtonBack
                    >No</app-cashier-button
                >
            </div>
            <div class="col">
                <app-cashier-button
                    [state]="{ accountData: accountData }"
                    [routerLink]="[confirmedPath]"
                    skipLocationChange
                    >Yes</app-cashier-button
                >
            </div>
        </div>
    </div>
</div>
