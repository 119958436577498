<span>This takes you over your current deposit limit. Try a lower amount.</span>

<ng-container *ngIf="playerTUIsAmber">
    <p>
        Want to raise your limit? We’ll need a little
        <a href="javascript:void(0)" appCloseCashier="survey"
            >more information.</a
        >
        <br />
        <br />
        Or you can <a href="javascript:void(0)" appChatWithUs>chat with us.</a>
    </p>
</ng-container>
