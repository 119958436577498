import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { InitData } from "app/interfaces/init-data.interface";
import { paths } from "app/paths";
import { PlayerSessionService } from "app/services/player-session/player-session.service";
import { AuthService } from "app/services/auth/auth.service";

@Injectable({
    providedIn: "root"
})
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private readonly authService: AuthService,
        private readonly _playerSessionService: PlayerSessionService,
        private readonly router: Router
    ) {}

    canActivate(): boolean | UrlTree {
        const initData: InitData = this._playerSessionService.initStatus;

        if (!initData.isLoggedIn && !initData.isTokenInUrl) {
            this.authService.destroyAuth();
            return this.router.createUrlTree([`/${paths.BASE}/${paths.ERROR}`]);
        }

        if (initData.isLoggedIn && initData.isTokenInUrl) {
            if (initData.isDepositLimits) {
                this.router.navigate(
                    [`/${paths.BASE}/${paths.DEPOSIT_LIMITS}`],
                    {
                        state: {
                            layoutHeader: false
                        }
                    }
                );
                return true;
            } else if (initData.isWithdrawals) {
                this.router.navigate([`/${paths.BASE}/${paths.WITHDRAW}`], {
                    state: {
                        layoutHeader: false
                    }
                });
                return true;
            }
        }

        return this.router.createUrlTree([`/${paths.BASE}`]);
    }
}
